import { Component } from '@angular/core';
import {
  AgreementFormComponent, BaseCommitmentFormData, OrchardList
} from 'app/agreements/agreement-page/agreement-form/agreement-form.component';
import { FormArray, FormControl, FormGroup, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import { HttpService } from 'app/shared/services/http.service';
import { StateService } from 'app/shared/services/state.service';
import { Router } from '@angular/router';
import { NotificationService } from 'app/notification/notification.service';
import { GrowerPortalScrollService } from 'app/grower-portal-layout/grower-portal-scroll.service';
import {
  ChangeableField,
  ChangeRequestDescriptor,
  ChangeRequestFormArray,
  ChangeRequestFormControl,
  ChangeRequestFormGroup,
  RequestedChange
} from 'app/agreements/agreement-page/agreement-form/changeable-field/changeable-field.component';
import { AgreementsService } from 'app/agreements/agreements.service';
import { GaService } from 'app/shared/services/ga.service';
import { MetadataFormGroup } from 'app/shared/forms/metadata-form-group';
import { DropdownOption } from 'app/shared/utils';
import { sum } from 'd3';
import { Utils } from 'app/shared/utils';

export const TREES_PER_HA = 25;
export const MAX_LENGTH_EXISTING_MONITORING_AREA_NAME = 50;
export const MAX_LENGTH_NEW_MONITORING_AREA_NAME = 5;

export interface AvoGreenData extends BaseCommitmentFormData {
  entity_bank_account_name: ChangeableField<string>;
  avogreen_monitor_options: DropdownOption[];
  default_monitoring_area_type: DropdownOption;
  frequency_options: DropdownOption[];
  avogreen_orchard_list: Orchard[];
  receive_results_default: DropdownOption;
}

export interface Block {
  text: string;
  value: number;
  producing_area: number;
}

export interface MonitoringArea {
  id: number;
  monitoring_area_name: ChangeableField<string>;
  monitoring_area_blocks: ChangeableField<Block[]>;
  monitoring_area_type: ChangeableField<DropdownOption>;
  monitoring_area_number_of_trees: ChangeableField<number>;
  monitoring_area_monitoring_frequency: ChangeableField<DropdownOption>;
  monitoring_area_china_registered: ChangeableField<boolean | string>;
  monitoring_area_active: ChangeableField<boolean>;
  active?: boolean;
  producing_area?: number;
}

export interface Contact {
  id: number;
  orchard_contact_orchard_id: DropdownOption;
  orchard_contact_first_name: ChangeableField<string>;
  orchard_contact_last_name: ChangeableField<string>;
  orchard_contact_email: ChangeableField<string>;
  orchard_contact_send_results: ChangeableField<DropdownOption | boolean>;
}

export interface Orchard extends OrchardList {
  season: string;
  blocks: Block[];
  monitoring_areas: MonitoringArea[];
  contacts: Contact[];
  orchard_pest_monitor_center: ChangeableField<DropdownOption>;
  activeMonitoringAreasCount: number;  // Calculated field based on selected/overridden monitor dropdown value
  isMonitoringWithTrevelyans?: boolean;  // Calculated field based on selected/overridden monitor dropdown value
  showSelfMonitorMessage?: boolean;  // Calculated field based on selected/overridden monitor dropdown value
  showNotMonitoringMonitorMessage?: boolean;  // Calculated field based on selected/overridden monitor dropdown value
  showSelectMonitorMessage?: boolean;  // Calculated field based on selected/overridden monitor dropdown value
  remainingBlocks?: Block[]; // Calculated field based on selected/overridden monitor blocks value
}

const TREVELYANS_PMC = 'Trevelyans';
const SELF_PMC = 'Self';

@Component({
  selector: 'agreement-form-avogreen',
  templateUrl: './agreement-form-avogreen.component.html',
  styleUrls: ['agreement-form-avogreen.component.scss', '../agreement-form.component.scss'],
})
export class AgreementFormAvogreenComponent extends AgreementFormComponent<AvoGreenData> {
  endpoint = 'avogreen';
  data = {} as AvoGreenData;
  changeRequestsForm = new ChangeRequestFormGroup({
    postal_address_line_1: new ChangeRequestFormControl('authorized_signer_user_id'),
    postal_address_line_2: new ChangeRequestFormControl('authorized_signer_user_id'),
    postal_address_city: new ChangeRequestFormControl('authorized_signer_user_id'),
    postal_address_country: new ChangeRequestFormControl('authorized_signer_user_id'),
    postal_address_postal_code: new ChangeRequestFormControl('authorized_signer_user_id', { validators: this.postalCodeValidators }),
    physical_address_line_1: new ChangeRequestFormControl('authorized_signer_user_id'),
    physical_address_line_2: new ChangeRequestFormControl('authorized_signer_user_id'),
    physical_address_city: new ChangeRequestFormControl('authorized_signer_user_id'),
    physical_address_country: new ChangeRequestFormControl('authorized_signer_user_id'),
    physical_address_postal_code: new ChangeRequestFormControl('authorized_signer_user_id', { validators: this.postalCodeValidators }),
    phone_number_mobile: new ChangeRequestFormControl('authorized_signer_user_id', { validators: this.mobileNumberValidators }),
  });
  form = new FormGroup({
    change_requests: this.changeRequestsForm,
    avogreen_orchard_list: new FormArray([]),
    signature_value: new FormControl('', [Validators.required]),
  });
  successMessage = 'Thank you for signing the Avogreen agreement.';
  avogreenMonitorOptions = [];
  defaultSamplingType: DropdownOption;
  defaultMonitoringFrequency: DropdownOption;
  frequencyOptions = [];
  receiveResultsDefault: DropdownOption;
  monitoringAreaFormGroupValidators: Record<string, Record<string, ValidatorFn[]>> = {};
  contactFormGroupValidators: Record<string, ValidatorFn[]> = {
    orchard_contact_first_name: [this.requiredValidator.bind(this), Validators.maxLength(30)],
    orchard_contact_last_name: [this.requiredValidator.bind(this), Validators.maxLength(30)],
    orchard_contact_email: [Validators.email],
  };
  private updateValidityOfMonitorAreaNameFieldsTimeoutHandle: any;
  private originalPestMonitorCenter: Record<number, DropdownOption> = {};

  constructor(
    private _http: HttpService,
    private _router: Router,
    private _stateService: StateService,
    private _notificationService: NotificationService,
    private _growerPortalScrollService: GrowerPortalScrollService,
    private _agreementsService: AgreementsService,
    private _gaService: GaService,
  ) {
    super(_http, _router, _stateService, _notificationService, _growerPortalScrollService, _agreementsService, _gaService);
  }

  get orchardFormGroups(): MetadataFormGroup<OrchardList>[] {
    return this.form.controls['avogreen_orchard_list'].controls as MetadataFormGroup<OrchardList>[];
  }

  protected handleFormData() {
    super.handleFormData();

    this.avogreenMonitorOptions = this.data.avogreen_monitor_options || [];
    this.defaultSamplingType = this.data.default_monitoring_area_type;
    this.frequencyOptions = this.data.frequency_options || [];
    this.receiveResultsDefault = this.data.receive_results_default;
    this.defaultMonitoringFrequency = this.frequencyOptions?.find(option => option.text === '4 Weekly');

    const orchardListFormArray = this.form.get('avogreen_orchard_list') as FormArray;
    for (const orchard of this.data.avogreen_orchard_list) {
      const monitoringAreasFormArray = new ChangeRequestFormArray('monitoring_area', true, []);
      for (const monitoringArea of orchard.monitoring_areas) {
        monitoringArea.producing_area = this.getProducingArea(monitoringArea.monitoring_area_blocks.value);
        monitoringAreasFormArray.push(this.createMonitoringAreaFormGroup(orchard, monitoringArea, monitoringAreasFormArray));
      }
      if (!orchard.monitoring_areas?.length) {
        // Always have at least one monitoring area
        this.addMonitoringArea(orchard, monitoringAreasFormArray);
      }

      const contactFormArray = new ChangeRequestFormArray('orchard_contact', false, []);
      for (const contact of orchard.contacts) {
        contactFormArray.push(this.createContactFormGroup(contact));
      }

      const changeRequestForm = new ChangeRequestFormGroup({
        orchard_pest_monitor_center: new ChangeRequestFormControl('orchard_id', { nonNullable: true, validators: [Validators.required] }),
        monitoring_areas: monitoringAreasFormArray,
        contacts: contactFormArray,
      });

      // Store the original value of the pest monitor center to determine if the user has changed it later.
      this.originalPestMonitorCenter[orchard.orchard_id] = orchard.orchard_pest_monitor_center.value;

      // Clear the requested change value to hide it in the UI and force the user to (re)select it.
      orchard.orchard_pest_monitor_center = { requested_change: null, value: null };
      changeRequestForm.patchValue(orchard);

      const orchardFormGroup = new MetadataFormGroup<OrchardList>({
        orchard_id: new FormControl(orchard.orchard_id),
        association_id: new FormControl(orchard.association_id),
        change_requests: changeRequestForm
      });

      this.setInitialShowOrchardMonitorForm(orchard, orchardFormGroup);
      orchardFormGroup.metadata = orchard;
      orchardListFormArray.push(orchardFormGroup);
      this.setRemainingBlocks(orchard);
      this.setActiveMonitoringAreas(orchard);
    }

    this.changeRequestsForm.patchValue(this.data);
  }

  private createMonitoringAreaFormGroup(orchard: Orchard, monitoringArea: MonitoringArea, monitoringAreas: FormArray<any>): ChangeRequestFormGroup {
    const numberOfTreesField = new ChangeRequestFormControl('id', { nonNullable: false }, monitoringArea.monitoring_area_number_of_trees.value);
    numberOfTreesField['producingArea'] = monitoringArea.producing_area;

    this.setMonitoringAreaFormGroupValidators(orchard, monitoringArea, monitoringAreas);

    const form = new ChangeRequestFormGroup({
      monitoring_area_name: new ChangeRequestFormControl('id'),
      monitoring_area_blocks: new ChangeRequestFormControl('id', { nonNullable: true }, []),
      monitoring_area_type: new ChangeRequestFormControl('id'),
      monitoring_area_number_of_trees: numberOfTreesField,
      monitoring_area_monitoring_frequency: new ChangeRequestFormControl('id', null, this.defaultMonitoringFrequency),
      monitoring_area_china_registered: new ChangeRequestFormControl(
        'id',
        { nonNullable: true },
        monitoringArea.monitoring_area_china_registered.value !== null,
        {
          'true': this.getChinaRegisteredDate(),
          'false': 'null',
          '': 'null'
        }
      ),
      monitoring_area_active: new ChangeRequestFormControl('id', null, monitoringArea.monitoring_area_active.value),
    });

    this.addValidatorsToFormGroup(form, this.monitoringAreaFormGroupValidators[orchard.orchard_id]);
    monitoringArea.active = monitoringArea.monitoring_area_active.value;
    form.patchValue(monitoringArea);
    return form;
  }

  private setMonitoringAreaFormGroupValidators(orchard: Orchard, monitoringArea: MonitoringArea, monitoringAreas: FormArray<any>) {
    this.monitoringAreaFormGroupValidators[orchard.orchard_id] = {
      monitoring_area_name: [
        this.requiredValidator.bind(this),
        this.getMonitoringAreaNameLengthValidator(monitoringArea),
        this.getUniqueMonitorAreaNameValidator(monitoringAreas)
      ],
      monitoring_area_blocks: [this.requiredValidator.bind(this), this.getBlocksUniqueValidator(orchard, monitoringArea)],
      monitoring_area_number_of_trees: [this.requiredValidator.bind(this), this.treeInRangeValidator.bind(this)],
    };
  }

  private getChinaRegisteredDate() {
    return `${ new Date().getFullYear() }-11-01`;
  }

  private createContactFormGroup(contact: Contact): ChangeRequestFormGroup {
    const sendResultsValue = contact.orchard_contact_send_results.value;

    const form = new ChangeRequestFormGroup({
      orchard_contact_orchard_id: new ChangeRequestFormControl('id', null, contact.orchard_contact_orchard_id),
      orchard_contact_first_name: new ChangeRequestFormControl('id'),
      orchard_contact_last_name: new ChangeRequestFormControl('id'),
      orchard_contact_email: new ChangeRequestFormControl('id'),
      orchard_contact_send_results: new ChangeRequestFormControl(
        'id',
        { nonNullable: true },
        typeof sendResultsValue === 'boolean' ? sendResultsValue : sendResultsValue?.value,
        {
          'true': this.receiveResultsDefault,
          'false': 'null',
          '': 'null'
        }
      ),
    });
    this.addValidatorsToFormGroup(form, this.contactFormGroupValidators);
    form.patchValue(contact);
    return form;
  }

  getUsedBlocks(orchardForm: MetadataFormGroup<Orchard>, excludeId?: number): Block[] {
    const usedBlocks = [];
    for (const _monitoringArea of ((orchardForm.get('change_requests.monitoring_areas') as FormArray<ChangeRequestFormGroup>).controls)) {
      const id = (_monitoringArea.controls.monitoring_area_name as ChangeRequestFormControl).objectId;
      if ((!excludeId || id !== excludeId) && _monitoringArea.controls.monitoring_area_active.value) {
        const blocksField = _monitoringArea.controls.monitoring_area_blocks as ChangeRequestFormControl;
        const blockValue = blocksField.getLatestValue() as Block[];
        usedBlocks.push(...blockValue);
      }
    }
    return Utils.uniqueObjectArrayByKey(usedBlocks, 'text');
  }

  private setRemainingBlocks(orchard: Orchard) {
    for (const orchardForm of this.getOrchardList().controls) {
      if (orchardForm.metadata === orchard) {
        const usedBlocks = this.getUsedBlocks(orchardForm);
        orchard.remainingBlocks = orchard.blocks.filter(block => !usedBlocks.find(usedBlock => usedBlock.text === block.text));
      }
    }
  }

  getBlocksUniqueValidator(orchard: Orchard, monitoringArea: MonitoringArea): ValidatorFn {
    const orchardList = this.form.get('avogreen_orchard_list');

    return (control: ChangeRequestFormControl): ValidationErrors | null => {
      const controlValue = control.value;
      if (controlValue?.length) {
        const controlBlockNames = controlValue.map(block => block.text);
        for (const orchardForm of (orchardList as FormArray<MetadataFormGroup<Orchard>>).controls) {
          if (orchardForm.metadata === orchard) {
            const duplicates = this.getUsedBlocks(orchardForm, monitoringArea.id).filter(block => controlBlockNames.includes(block.text));
            return duplicates.length ? { duplicate: this.formatBlockNames(duplicates) } : null;
          }
        }
      }
      return null;
    }
  }

  private getMonitoringAreaNameLengthValidator(monitoringArea: MonitoringArea): ValidatorFn {
    return (control: ChangeRequestFormControl): ValidationErrors | null => {
      const controlValue = control.value;
      const maxLength = monitoringArea.id > 0 ? MAX_LENGTH_EXISTING_MONITORING_AREA_NAME : MAX_LENGTH_NEW_MONITORING_AREA_NAME;
      if (controlValue?.length > maxLength) {
        return { maxlength: maxLength };
      }
      return null;
    }
  }

  private getUniqueMonitorAreaNameValidator(monitoringAreas: FormArray<any>): ValidatorFn {
    return (control: ChangeRequestFormControl): ValidationErrors | null => {
      const existingNames = this.getExistingMonitorAreaNames(monitoringAreas);
      const newName = (control.value || '').toString().toLowerCase();
      this.updateValidityOfMonitorAreaNameFields(monitoringAreas);

      if (newName && existingNames.filter(name => name === newName).length > 1) {
        return { notUnique: true };
      }
      return null;
    }
  }

  // This function returns all active monitor area names in the form.
  private getExistingMonitorAreaNames(monitoringAreas: FormArray<any>): string[] {
    const existingNames = [];
    for (const monitoringArea of monitoringAreas.controls) {
      const monitoringAreaNameControl = monitoringArea.get('monitoring_area_name') as ChangeRequestFormControl;
      const activeControl = monitoringArea.get('monitoring_area_active') as ChangeRequestFormControl;
      const isActive = activeControl.getLatestValue();
      if (isActive) {
        const value = monitoringAreaNameControl.getLatestValue();
        if (value) {
          existingNames.push(value.toString().toLowerCase());
        }
      }
    }
    return existingNames;
  }

  // Note: This method has to be debounced to prevent stack-overflows.
  private updateValidityOfMonitorAreaNameFields(monitoringAreas: FormArray<any>) {
    clearTimeout(this.updateValidityOfMonitorAreaNameFieldsTimeoutHandle);
    this.updateValidityOfMonitorAreaNameFieldsTimeoutHandle = setTimeout(() => {
      for (const monitoringArea of monitoringAreas.controls) {
        const monitoringAreaName = monitoringArea.get('monitoring_area_name') as ChangeRequestFormControl;
        monitoringAreaName.updateValueAndValidity({ onlySelf: true });
      }
    }, 300);
  }

  triggerBlocksValidation(monitoringArea: MonitoringArea) {
    const formArray = this.form.get('avogreen_orchard_list') as FormArray<MetadataFormGroup<Orchard>>;
    for (const orchardForm of formArray.controls) {
      const monitoringAreas = orchardForm.get('change_requests.monitoring_areas') as FormArray<ChangeRequestFormGroup>;
      for (const _monitoringArea of monitoringAreas.controls) {
        const blockControl = _monitoringArea.controls.monitoring_area_blocks as ChangeRequestFormControl;
        if (blockControl.objectId !== monitoringArea.id) {
          blockControl.updateValueAndValidity();
        }
      }
    }
  }

  saveForm() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    this.ga.event(
      'button',
      'click',
      `agreement form: ${ this.agreement.name } save`,
      this.agreement.entity_name
    );

    const formData = this.form.getRawValue();
    this.updateStringToNull(formData);
    this.cleanupPestMonitorCenterChangeRequests(formData);

    super.sendSaveRequest(formData);
  }

  // This function removes the change request for the initially hidden Pest Monitor Center, if the user has re-selected the same one.
  // Otherwise, it restores the old value to the change request.
  private cleanupPestMonitorCenterChangeRequests(formData: any) {
    for (const orchard of formData.avogreen_orchard_list) {
      const originalValue = this.originalPestMonitorCenter[orchard.orchard_id];
      if (originalValue) {
        const changeIndex = (orchard.change_requests || []).findIndex((change: ChangeRequestDescriptor) => change.name === 'orchard_pest_monitor_center');
        if (changeIndex > -1) {
          const changeRequest = orchard.change_requests[changeIndex];
          const newValue = changeRequest.new_value[0].orchard_pest_monitor_center;
          if (newValue?.value === originalValue.value) {
            orchard.change_requests.splice(changeIndex, 1);
          } else {
            changeRequest.old_value[0].orchard_pest_monitor_center = originalValue;
          }
        }
      }
    }
  }

  // Null values are replaced in changeable-field component. It seemed too big of a change to modify this behaviour.
  private updateStringToNull(formData: any) {
    for (const orchard of formData.avogreen_orchard_list) {
      for (const changes of orchard.change_requests) {
        for (const change of changes.new_value) {
          for (const key in change) {
            if (change[key] === 'null') {
              change[key] = null;
            }
          }
        }
      }
    }
  }

  formatSendResults(value: boolean): string {
    return value ? 'Yes' : 'No';
  }

  formatBlockNames(blocks: Block[]): string {
    return blocks.map(block => block.text).join(', ');
  }

  formatChinaRegistered(date: Date): string {
    return date ? 'Yes (' + date + ')' : 'No';
  }

  onPestMonitorCenterChange(orchard: Orchard, value: DropdownOption, orchardForm: MetadataFormGroup<OrchardList>) {
    this.setMonitorFormBooleans(orchard, value?.text, '', orchardForm);
  }

  private setMonitorFormBooleans(orchard: Orchard, newValue: string, oldValue = '', orchardForm: MetadataFormGroup<OrchardList> = null) {
    newValue ||= '';
    orchard.showSelectMonitorMessage = !(this.isViewOnly || oldValue || newValue);
    orchard.isMonitoringWithTrevelyans = newValue.includes(TREVELYANS_PMC) || (!newValue && oldValue.includes(TREVELYANS_PMC));
    orchard.showSelfMonitorMessage = newValue.includes(SELF_PMC) || (!newValue && oldValue.includes(SELF_PMC));
    orchard.showNotMonitoringMonitorMessage = !orchard.showSelectMonitorMessage && !orchard.isMonitoringWithTrevelyans && !orchard.showSelfMonitorMessage;

    if (orchardForm) {
      if (orchard.isMonitoringWithTrevelyans) {
        this.enableValidationOnOrchardContacts(orchardForm);
      } else {
        this.disableValidationOnOrchardContacts(orchardForm);
      }
    }
  }

  private setInitialShowOrchardMonitorForm(orchard: Orchard, orchardForm: MetadataFormGroup<OrchardList>) {
    const newValue = (orchard.orchard_pest_monitor_center.requested_change?.value || '') as string;
    const oldValue = (orchard.orchard_pest_monitor_center.value?.text || '') as string;
    this.setMonitorFormBooleans(orchard, newValue, oldValue, orchardForm);
  }

  private disableValidationOnOrchardContacts(orchardForm: MetadataFormGroup<OrchardList>) {
    const contacts = orchardForm.get('change_requests.contacts') as FormArray<ChangeRequestFormGroup>;
    this.disableValidationOnFormGroups(contacts.controls);
  }

  private disableValidationOnFormGroups(formGroups: ChangeRequestFormGroup[]) {
    for (const formGroup of formGroups) {
      this.removeValidatorsFromFormGroup(formGroup);
    }
  }

  private enableValidationOnOrchardContacts(orchardForm: MetadataFormGroup<OrchardList>) {
    const contacts = orchardForm.get('change_requests.contacts') as FormArray<ChangeRequestFormGroup>;
    this.enableValidationOnFormGroups(contacts.controls, this.contactFormGroupValidators);
  }

  private enableValidationOnFormGroups(formGroups: ChangeRequestFormGroup[], validators: Record<string, ValidatorFn[]>) {
    for (const formGroup of formGroups) {
      this.addValidatorsToFormGroup(formGroup, validators);
    }
  }

  private getOrchardList(): FormArray<MetadataFormGroup<Orchard>> {
    return this.form.get('avogreen_orchard_list') as FormArray<MetadataFormGroup<Orchard>>;
  }

  onBlocksChange(monitoringAreaControls: ChangeRequestFormGroup, orchard: Orchard, blocks: Block[], monitoringArea: MonitoringArea) {
    const numberOfTreesField = monitoringAreaControls.get('monitoring_area_number_of_trees') as ChangeRequestFormControl;
    this.setRemainingBlocks(orchard);
    this.triggerBlocksValidation(monitoringArea);
    monitoringArea.producing_area = this.getProducingArea(blocks);
    monitoringArea.monitoring_area_number_of_trees.value = this.getMinNumberOfTrees(monitoringArea.producing_area);
    numberOfTreesField.forceBaseValueUpdate(monitoringArea.monitoring_area_number_of_trees.value);
    numberOfTreesField['producingArea'] = monitoringArea.producing_area;
    numberOfTreesField.updateValueAndValidity();
  }

  private getProducingArea(blocks: Block[]): number {
    return sum(blocks.map(block => block.producing_area));
  }

  addMonitoringArea(orchard: Orchard, controls: FormArray) {
    const monitoringArea = {
      id: -1,
      monitoring_area_name: { value: '', requested_change: {} as RequestedChange },
      monitoring_area_blocks: { value: [], requested_change: {} as RequestedChange },
      monitoring_area_type: { value: null, requested_change: {} as RequestedChange },
      monitoring_area_number_of_trees: { value: null, requested_change: {} as RequestedChange },
      monitoring_area_monitoring_frequency: { value: this.defaultMonitoringFrequency, requested_change: {} as RequestedChange },
      monitoring_area_china_registered: { value: null, requested_change: {} as RequestedChange },
      monitoring_area_active: { value: true, requested_change: {} as RequestedChange },
      active: true
    };

    const newFormGroup = this.createMonitoringAreaFormGroup(orchard, monitoringArea, controls);
    controls.push(newFormGroup);
    (newFormGroup.get('monitoring_area_type') as ChangeRequestFormControl).updateValue(this.defaultSamplingType);

    orchard.monitoring_areas.push(monitoringArea);
    this.setActiveMonitoringAreas(orchard);
  }

  deleteMonitoringArea(orchard: Orchard, monitoringArea: MonitoringArea, controls: FormArray, index: number) {
    if (monitoringArea.id > 0) {
      monitoringArea.active = false;
      const form = controls.at(index) as ChangeRequestFormGroup;
      const monitoringAreaActive = form.get('monitoring_area_active');
      monitoringAreaActive.setValue(false);
      monitoringAreaActive.markAsTouched();
      monitoringAreaActive.markAsDirty();
      this.removeValidatorsFromFormGroup(form);
    } else {
      orchard.monitoring_areas.splice(index, 1);
      controls.removeAt(index);
    }
    this.setActiveMonitoringAreas(orchard);
    this.setRemainingBlocks(orchard);
    this.triggerBlocksValidation(monitoringArea);
  }

  setActiveMonitoringAreas(orchard: Orchard) {
    orchard.activeMonitoringAreasCount = (orchard.monitoring_areas || []).filter(monitoringArea => monitoringArea.active).length;
  }

  restoreMonitoringArea(orchard: Orchard, monitoringArea: MonitoringArea, controls: FormArray, index: number) {
    const form = controls.at(index) as ChangeRequestFormGroup;
    monitoringArea.active = true;
    form.get('monitoring_area_active').setValue(true);
    this.addValidatorsToFormGroup(form, this.monitoringAreaFormGroupValidators[orchard.orchard_id]);
    this.setActiveMonitoringAreas(orchard);
    this.setRemainingBlocks(orchard);
    this.triggerBlocksValidation(monitoringArea);
  }

  addContact(orchard: Orchard, controls: FormArray) {
    const orchardName = [orchard.season, orchard.grower_number, orchard.orchard_name].join(' - ');
    const contact = {
      id: -1,
      orchard_contact_orchard_id: { text: orchardName, value: orchard.orchard_id },
      orchard_contact_first_name: { value: '', requested_change: {} as RequestedChange },
      orchard_contact_last_name: { value: '', requested_change: {} as RequestedChange },
      orchard_contact_email: { value: '', requested_change: {} as RequestedChange },
      orchard_contact_send_results: { value: true, requested_change: {} as RequestedChange }
    };
    controls.push(this.createContactFormGroup(contact));
    orchard.contacts.push(contact);
  }

  deleteContact(orchard: Orchard, controls: FormArray, index: number) {
    orchard.contacts.splice(index, 1);
    controls.removeAt(index);
  }

  private getOldValueFromControl(control: FormControl): any {
    const rawValue = control.getRawValue();
    return rawValue.old_value[0]?.[rawValue.name];
  }

  private getMinNumberOfTrees(producingArea: number): number {
    let trees = 5;
    if (producingArea >= 1 && producingArea < 4) {
        trees = 10;
    } else if (producingArea >= 4 && producingArea < 6) {
        trees = 15;
    } else if (producingArea >= 6 && producingArea < 8) {
        trees = 20;
    } else if (producingArea >= 8 && producingArea <= 10) {
        trees = 25;
    } else if (producingArea > 10) {
        // Orchards 10ha+ get TREES_PER_HA number of trees to monitor per 10ha
        trees = Math.round((producingArea / 10) * TREES_PER_HA);
    }
    return trees;
  }

  private addValidatorsToFormGroup(form: ChangeRequestFormGroup, validators: Record<string, ValidatorFn[]>) {
    for (const field in form.controls) {
      const control = form.controls[field];
      if (validators[field]) {
        control.setValidators(validators[field]);
        control.updateValueAndValidity();
      }
    }
  }

  private removeValidatorsFromFormGroup(form: ChangeRequestFormGroup) {
    for (const field in form.controls) {
      const control = form.controls[field];
      control.clearValidators();
      control.updateValueAndValidity();
    }
  }

  private treeInRangeValidator(control: FormControl): ValidationErrors | null {
    const maxTrees = 999;
    const minTrees = this.getMinNumberOfTrees(control['producingArea']);
    let value = control.value;
    if (!control.touched) {
      value = this.getOldValueFromControl(control);
    }
    return value >= minTrees && value <= maxTrees ? null : { min: minTrees, max: maxTrees };
  }

  private requiredValidator(control: FormControl): ValidationErrors | null {
    let value = control.value;

    if (!control.touched) {
      value = this.getOldValueFromControl(control);
    }

    if (Array.isArray(value)) {
      return value.length ? null : { required: true };
    } else {
      return value ? null : { required: true };
    }
  }
}
