<ng-container *ngIf="displayError; else weather">
  <div class="no-data-message visible">
    <i class="fas fa-exclamation-triangle"></i>
    <div class="message">
      Your weather forecast is currently unavailable.
    </div>
  </div>
</ng-container>
<ng-template #weather>
  <kendo-tabstrip class="tabstrip" (tabSelect)="onTabSelect($event)">
    <kendo-tabstrip-tab title="Daily Forecast" id="daily-tab" [selected]="selectedTabTitle === 'Daily Forecast'">
      <ng-template kendoTabContent>
        <div #dailyForecast class="daily-forecast">
          <div *ngFor="let forecast of dailyWeatherForecasts" class="day" (click)="scrollHourlyForecastIntoView(forecast.dow)">
            <div class="header">
              <div class="weather-icon" [style.backgroundImage]="'url(/static/images/weather-icons/' + (forecast.icon_code | number: '2.') + '.png)'"></div>
              <div class="day-of-week">{{ forecast.dow }}</div>
              <div class="date">{{ forecast.date | date:'MMM dd' }}</div>
            </div>
            <div class="narrative">{{ forecast.narrative }}</div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Hourly Forecast" id="hourly-tab" [selected]="selectedTabTitle === 'Hourly Forecast'">
      <ng-template kendoTabContent>
        <div class="hourly-forecast">
          <div class="previous" (click)="previous()" (mousedown)="startPrevious()" (mouseup)="endMouseDown()">
            <i class="fas fa-chevron-left"></i>
          </div>
          <div #hourlyForecast
               class="hours"
               (mousedown)="dragStart($event.pageX)"
               (touchstart)="touchStart($event.changedTouches[0].pageX)"
               (mousemove)="drag($event.pageX)"
               (mouseup)="dragEnd()"
               (touchend)="touchEnd()"
               (mouseleave)="dragEnd()">
            <div *ngFor="let forecast of hourlyWeatherForecasts; let i = index" class="hour">
              <div *ngIf="hourlyWeatherForecasts[i-1]?.dow !== forecast.dow" class="day-of-week" [id]="dowIdPrefix + forecast.dow">{{ forecast.dow }}</div>
              <div class="time">{{ forecast.date | date:'h a' }}</div>
              <div class="weather-icon" [style.backgroundImage]="'url(/static/images/weather-icons/' + (forecast.icon_code | number: '2.') + '.png)'"></div>
              <div class="temperature">{{ (forecast.temp | appendIfExists:'°C') || '-' }}</div>
              <div class="metadata">{{ forecast.pop || '0' }}%<i class="fas fa-droplet"></i></div>
              <div *ngIf="forecast.wspd" class="metadata">{{ forecast.wspd | appendIfExists: ' km/h ' + forecast.wdir_cardinal }}</div>
            </div>
          </div>
          <div class="next" (click)="next()" (mousedown)="startNext()" (mouseup)="endMouseDown()">
            <i class="fas fa-chevron-right"></i>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
  <div class="attribution">
    Your orchard weather forecast is provided by
    <a target="_blank" rel="noopener noreferrer" href="https://business.weather.com/">The Weather Company.</a>
  </div>
</ng-template>
