import { Injectable } from '@angular/core';
import {
  ProductName,
  Resource,
  ResourceCategory,
  ResourceGroup, ResourceMetaItem, ResourceThumbnail
} from 'app/resources/resource.interfaces';
import { OrchardCalendarComponent } from 'app/orchard-calendar/orchard-calendar.component';
import { ThinningCalculatorComponent } from 'app/calculators/thinning-calculator/thinning-calculator.component';
import {
  CosmeticThinningCalculatorComponent
} from 'app/calculators/cosmetic-thinning-calculator/cosmetic-thinning-calculator.component';
import { Utils } from 'app/shared/utils';
import { DocumentLibraryComponent } from 'app/shared/document-library/document-library.component';
import { BehaviorSubject, first, Observable } from 'rxjs';
import { StateService } from 'app/shared/services/state.service';
import { HttpService } from 'app/shared/services/http.service';
import { DialogRef, DialogResult, DialogService, DialogSettings } from '@progress/kendo-angular-dialog';
import { UserProductsService } from 'app/shared/services/user-products.service';
import { NewsViewerComponent } from 'app/news-viewer/news-viewer.component';
import { PickingContractorRankingComponent } from 'app/picking-contractor-ranking/picking-contractor-ranking.component';
import { TitleService } from 'app/shared/services/title.service';

const MAX_IMAGE_INDEX = 6;
const RESOURCE_PLACEHOLDER_IMAGE = '/assets-shared/img/bg_resource_place_holder.jpg';
const BASE_CATEGORIES: ResourceCategory[] = [
  {
    label: 'Communications',
    resourceGroups: [
      {
        group_name: 'Week That Was',
        id: 'week-that-was',
        icon: 'fa-file-lines',
        component: {
          componentClass: NewsViewerComponent
        }
      },
      { group_name: 'Newsletters', id: 'newsletters',  icon: 'fa-newspaper', items: [] },
    ]
  },
  {
    label: 'Resources',
    resourceGroups: [
      {
        group_name: 'Calculators',
        id: 'calculators',
        icon: 'fa-calculator',
        items: [
          {
            name: 'Thinning',
            id: 'thinning_calculator',
            thumbnail: { src: '/assets-shared/img/bg_kiwi_1.jpg' },
            description: 'This calculator gives you a quick indication of fruit size to thin to on any given day.',
            component: {
              componentClass: ThinningCalculatorComponent,
              params: [{ key: 'embedded', value: true }]
            },
            product_name: 'kiwifruit',
            meta: [{ icon: 'fa-person-digging', text: 'Interactive' }]
          }, {
            name: 'Cosmetic Thinning',
            id: 'cosmetic_thinning_calculator',
            thumbnail: { src: '/assets-shared/img/bg_kiwi_2.jpg' },
            description: 'This calculator will help you estimate the benefit of fruit thinning before harvest.',
            component: {
              componentClass: CosmeticThinningCalculatorComponent,
              params: [{ key: 'embedded', value: true }]
            },
            product_name: 'kiwifruit',
            meta: [{ icon: 'fa-person-digging', text: 'Interactive' }]
          }
        ]
      },
      { group_name: 'Growth Charts', id: 'growth_charts',  icon: 'fa-seedling', items: [] },
      { group_name: 'Conversion Matrices', id: 'conversion_matrices',  icon: 'fa-table-cells', items: [] },
      { group_name: 'Field Day Handouts', id: 'field_days_handouts', icon: 'fa-file-image', items: [] },
      { group_name: 'Foliar Nutrition Options', id: 'foliar_nutrition_options', icon: 'fa-leaf', items: [] },
      { group_name: 'Spray Guides', id: 'spray_guides',  icon: 'fa-spray-can', items: [] },
      {
        group_name: 'Documents',
        id: 'documents',
        icon: 'fa-folder-tree',
        component: {
          componentClass: DocumentLibraryComponent,
          params: [{ key: 'embedded', value: true }]
        }
      },
      { group_name: 'Maps', id: 'maps', icon: 'fa-map', items: [] },
      {
        group_name: 'Kiwifruit Calendar',
        id: 'kiwifruit-calendar',
        icon: 'fa-calendar-days',
        component: {
          componentClass: OrchardCalendarComponent,
          params: [
            { key: 'embedded', value: true },
            { key: 'showHideCalendarButton', value: false },
            { key: 'isExpanded', value: true },
            { key: 'showExpandAllButton', value: true }
          ]
        }
      },
      {
        group_name: 'Picking Contractor Ranking',
        id: 'picking-contractor-ranking',
        icon: 'fa-ranking-star',
        contentScrollDisabled: true,
        component: {
          componentClass: PickingContractorRankingComponent
        }
      },
    ]
  }
];
export const EMPTY_RESOURCE_GROUP = { items: [] } as ResourceGroup;
const EMPTY_RESOURCE = {} as Resource;

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  categories: ResourceCategory[] = Utils.clone(BASE_CATEGORIES);
  currentResourceGroup: ResourceGroup = EMPTY_RESOURCE_GROUP;
  currentResource: Resource = EMPTY_RESOURCE;
  isRemoteDataLoaded = new BehaviorSubject(false);
  isUiRefreshed = true;
  placeholderImage = RESOURCE_PLACEHOLDER_IMAGE;
  private openDialog: DialogRef;

  constructor(
    private stateService: StateService,
    private http: HttpService,
    private dialogService: DialogService,
    private userProductsService: UserProductsService,
    private titleService: TitleService
  ) {
    if (!this.isRemoteDataLoaded.value) {
      this.loadResources();
    }
    this.initCategories();
  }

  setCurrent(resourceGroupId: string, resourceId: string = null) {
    for (const category of this.categories) {
      for (const resourceGroup of category.resourceGroups) {
        if (resourceGroup.id === resourceGroupId) {
          this.currentResourceGroup = resourceGroup;
          if (resourceId) {
            for (const resource of resourceGroup.items) {
              if (resource.id === resourceId) {
                this.currentResource = resource;
                this.titleService.setDocumentSubTitle(this.currentResource.name);
              }
            }
          } else {
            this.titleService.setDocumentSubTitle(this.currentResourceGroup.group_name);
          }
        }
      }
    }
  }

  loadResources() {
    this.stateService.setLoading(true, 'Loading your resources');
    this.http.get('growers/resources/items/', {}, false, true).pipe(
      first()
    ).subscribe({
      next: (result:  ResourceGroup[]) => {
        this.loadResourcesComplete();
        this.addDownloadableResources(result);
      },
      error: (error) => {
        this.loadResourcesComplete();
        this.showErrorDialog('Failed to load your resources.');
        throw(error);
      }
    });
  }

  downloadResource(resource: Resource): Observable<object> {
    const url = `growers/resources/drive/${ resource.item.drive_id }/item/${ resource.item.item_id }/content/`;
    const getRequest = this.http.get(url, { responseType: 'blob' }, false, true).pipe(
      first()
    );
    getRequest.subscribe({
      next: (result: Blob) => {
        this.downloadResourceSuccess(resource, result);
      },
      error: (error) => {
        this.showErrorDialog('Failed to download ' + resource.name);
        throw(error);
      }
    });
    return getRequest;
  }

  downloadResourceImage(resource: Resource) {
    const url = `growers/resources/drive/${ resource.thumbnail.drive_id }/item/${ resource.thumbnail.item_id }/content/`;
    this.http.get(url, { responseType: 'blob' }, false, true).pipe(
      first()
    ).subscribe({
      next: (result: Blob) => {
        const blob = new Blob([result], { type: resource.thumbnail.mime_type });
        resource.thumbnail.src = URL.createObjectURL(blob);
      },
      error: (error) => {
        this.showErrorDialog('Failed to download image for ' + resource.name);
        throw(error);
      }
    });
  }

  private initCategories() {
    const hasKiwifruit = this.userProductsService.hasKiwifruit();
    if (!hasKiwifruit) {
      const resources = this.getCategory('Resources');
      this.removeResourceGroupById(resources, 'kiwifruit-calendar');
      this.removeResourceGroupById(resources, 'picking-contractor-ranking');
      this.removeResourceGroupByProduct('kiwifruit');
    }
  }

  private getCategory(categoryLabel: string): ResourceCategory {
    return this.categories.find((category) => category.label === categoryLabel);
  }

  private removeResourceGroupByProduct(productName: string) {
    this.categories.forEach((category) => {
      category.resourceGroups.forEach((resourceGroup) => {
        resourceGroup.items = (resourceGroup.items || []).filter((resource) => {
          return resource.product_name !== productName;
        });
      });
    });
  }

  private removeResourceGroupById(category: ResourceCategory, resourceGroupId: string) {
    category.resourceGroups = category.resourceGroups.filter((resourceGroup) => {
      return resourceGroup.id !== resourceGroupId;
    });
  }

  private downloadResourceSuccess(resource: Resource, fileData: Blob) {
    const blob = new Blob([fileData], { type: resource.item.mime_type });
    const fileUrl = URL.createObjectURL(blob);

    // Click on link permits setting the file name.
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = this.getFilenameWithExtension(resource);
    link.click();
  }

  private getFilenameWithExtension(resource: Resource) {
    return `${ resource.name }.${ resource.item.extension }`;
  }

  private loadResourcesComplete() {
    this.stateService.setLoading(false);
    this.isRemoteDataLoaded.next(true);
  }

  private addDownloadableResources(data: ResourceGroup[]) {
    data.forEach((resourceGroup) => {
      resourceGroup.items.forEach((item) => {
        this.addDownloadableResource(item, resourceGroup.group_name);
      });
    });
    this.refreshUi();
  }

  private addDownloadableResource(item: Resource, resourceGroupName: string) {
    for (const category of this.categories) {
      for (const resourceGroup of category.resourceGroups) {
        if (resourceGroup.group_name === resourceGroupName) {
          const resource = this.getCleanResource(item);
          resourceGroup.items.push(resource);
        }
      }
    }
  }

  private getCleanResource(item: Resource): Resource {
    return {
      name: item.name,
      id: this.nameToId(item.name),
      description: item.description,
      thumbnail: this.getThumbnail(item),
      item: item.item || null,
      file_type: item.file_type,
      product_name: item.product_name.toLowerCase() as ProductName,
      meta: this.getDownloadableMeta(item)
    };
  }

  private nameToId(name: string): string {
    return name.toLowerCase().replace(/[\W\s_]+/g, '-');
  }

  private getThumbnail(item: Resource, random = false): ResourceThumbnail {
    if (item.thumbnail?.src) {
      return item.thumbnail;
    } else {
      if (item.thumbnail) {
        // item.thumbnail.src = RESOURCE_PLACEHOLDER_IMAGE;
        this.downloadResourceImage(item);
        return item.thumbnail;
      } else {
        if (random) {
          const imageIndex = 1 + Math.floor(MAX_IMAGE_INDEX * Math.random());
          return { src: `/assets-shared/img/bg_kiwi_${imageIndex}.jpg` };
        } else {
          return { src: RESOURCE_PLACEHOLDER_IMAGE };
        }
      }
    }
  }

  private getDownloadableMeta(item: Resource): ResourceMetaItem[] {
    const fileTypeMeta = this.getFileTypeMeta(item);
    const fileLastModifiedMeta = this.getFileLastModifiedMeta(item);
    const meta = [
      { icon: 'fa-cloud-arrow-down', title: 'Downloadable file.' },
      fileTypeMeta
    ];
    if (fileLastModifiedMeta) {
      meta.push(fileLastModifiedMeta);
    }
    return meta;
  }

  private getFileTypeMeta(resource: Resource): ResourceMetaItem {
    const fileSize = resource.item.size || '';
    const formattedFileSize = fileSize ? ` (${ fileSize })` : '';
    switch (resource.file_type) {
      case 'pdf': return { icon: 'fa-file-pdf', text: 'PDF' + formattedFileSize, title: 'This is a PDF document.' };
      case 'xlsx': return { icon: 'fa-file-excel', text: 'Excel' + formattedFileSize, title: 'This is an excel document.' };
      case 'excel': return { icon: 'fa-file-excel', text: 'Excel' + formattedFileSize, title: 'This is an excel document.' };
      default: return { icon: 'fa-file', text: resource.file_type + formattedFileSize, title: `This is a ${ resource.file_type } document.` };
    }
  }

  private getFileLastModifiedMeta(resource: Resource): ResourceMetaItem {
    if (resource.last_modified) {
      const formattedDate = Utils.formatDateShort(resource.last_modified);
      const formattedDateTime = Utils.formatDateTimeShort(resource.last_modified);
      return { icon: 'fa-calendar-days', text: formattedDate, title: `Last modified on ${ formattedDateTime }.` };
    }
    return null;
  }

  private showErrorDialog(message: string) {
    this.openDialog?.close();
    this.openDialog = this.dialogService.open(({
      title: 'Error',
      cssClass: 'error-dialog',
      content: message,
      actions: [
        { text: 'Close', cssClass: 'button small rounded light-hover close-icon' },
        { text: 'Retry', cssClass: 'button small rounded light-hover retry-icon' }
      ],
      width: 400
    } as DialogSettings));

    this.openDialog.result.pipe(first()).subscribe((result: DialogResult) => {
      if (result['text'] === 'Retry') {
        this.loadResources();
      }
    });
  }

  // Hack to refresh UI.
  // Toggles isUiRefreshed. Any portion of UI wrapped with and *ngIf="isUiRefreshed" will be re-rendered.
  private refreshUi() {
    this.isUiRefreshed = false;
    setTimeout(() => this.isUiRefreshed = true);
  }
}
