<div class="row">
  <div class="label">{{ label }}</div>
  <div *ngIf="!alwaysOpen"
       class="control">
    <button [class]="'button' + (showForm ? ' fg-danger' : ' fg-ok')"
            [disabled]="!isEnabled"
            type="button"
            (click)="onControlClick()">
      <i [class]="'fas' + (showForm ? ' fa-times' : ' fa-pen-to-square')"></i>
    </button>
  </div>
  <div class="value">
    <div [class.previous-value]="latestRequestedChange || showForm">
      <ng-content select="[dataValue]"></ng-content>

      <div *ngFor="let dataField of _displayedFields | keyvalue: originalOrder">
        @let value = dataField.value?.value;
        {{ value?.text || (value?.text === null ? '' : (value || '')) }}
      </div>
    </div>

    <div *ngIf="latestRequestedChange"
         class="requested-change"
         [class.previous-value]="showForm">
      <div *ngFor="let dataField of _displayedFields | keyvalue: originalOrder; let length = count">
        @let value = dataField.value?.value;
        @let change = dataField.value?.requested_change;
        @let requestedValue = change.value?.text || change?.value || '';
        {{ value && !requestedValue ? (length > 1 ? '' : '*deleted*') : (requestedValue || (value?.text === null ? '' : (value || ''))) }}
      </div>
      <div *ngIf="latestRequestedChange.requested_by_name" class="metadata" >
        {{ latestRequestedChange.requested_by_name }},
        {{ latestRequestedChange.requested_at | date: 'dd/MM/yy, h:mm a' }}
      </div>
    </div>

    <div *ngIf="showForm" #inputs class="inputs">
      <ng-content></ng-content>
    </div>
  </div>
</div>
