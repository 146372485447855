import { map, tap } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpService } from 'app/shared/services/http.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { Utils } from 'app/shared/utils';

@Injectable()
export class DocumentLibraryService extends BehaviorSubject<GridDataResult> implements OnDestroy {
  private ENDPOINT = 'orchards/documents/';
  adminMode = false;
  filters: [];
  private fetchSubscription = new Subscription();

  constructor(private http: HttpService) {
    super(null);
  }

  ngOnDestroy() {
    this.fetchSubscription?.unsubscribe();
  }

  delete(ids: number[]) {
    const url = this.ENDPOINT + '?ids=' + ids.join('&ids=');
    return this.http.delete(url);
  }

  extractFilters(response) {
    this.filters = response['results']?.['filters'];
  }

  protected fetch(state: State): Observable<GridDataResult> {
    let queryStr = toDataSourceRequestString(state);
    queryStr = Utils.convertFilterToQueryString(queryStr);
    if (this.adminMode && queryStr) {
      if (queryStr) {
        queryStr += '&';
      }
      queryStr += 'for_admin=true';
    }

    return this.http
      .get(`${this.ENDPOINT}?${queryStr}`).pipe(
        tap(response => this.extractFilters(response)),
        map(response => ({
          data: response['results']?.['documents'].map((result) => {
            result['uploaded_at'] = Utils.parseDateTime(result['uploaded_at']);
            return result;
          }),
        total: response['count']
      } as GridDataResult)));
  }

  query(state: State): void {
    this.fetchSubscription.add(this.fetch(state).subscribe(x => super.next(x)));
  }
}
