import {
  Component,
  OnInit,
  OnChanges,
  Input,
  SimpleChanges,
  HostBinding,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef, OnDestroy
} from '@angular/core';
import { Utils } from 'app/shared/utils';
import * as d3 from 'd3';
import { Axis, Range, ChartAverage, ChartMargin } from 'app/interfaces/chart.interface';
import { Subject, Subscription } from 'rxjs';
import { GaService } from 'app/shared/services/ga.service';

export interface BarChartGroup {
  label: string;
  master?: string; // Main / third group
  primary: string;
  secondary?: string;
  isSelected?: boolean;
  sortOrder?: string;
  secondarySortOrder?: string;
}

export interface BarChartDataItem {
  value: number;
  [index: string]: string|number|any;
}

export interface SelectedGroup {
  master?: string;
  primary: string;
  secondary: string;
  secondarySortOrder?: string;
}

export interface PlotValueItem {
  value: string;
  label: string;
  precision?: number;
  isPercent?: boolean;
}

interface ChartGroup {
  label: string;
  charts: any[];
}

interface ChartLegendItem {
  label: string;
  index: number;
  cssClass?: string;
}

interface SecondaryValue {
  value: string|number;
  groupLink: string|number;
  cssClass: string;
}

@Component({
  selector: 'app-grouped-bar-chart',
  templateUrl: './grouped-bar-chart.component.html',
  styleUrls: ['./grouped-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupedBarChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: BarChartDataItem[] = [];
  @Input() groups: BarChartGroup[] = [];
  @Input() showZeroValues = true;
  @Input() showLegend = true;
  @Input() showGroupSelector = true;
  @Input() showPlotValueSelector = false;
  @Input() showGroupTotalForPercent100 = true;
  @Input() plotValues: PlotValueItem[] = [];
  @Input() showGrid = true;
  @Input() numGridLines = 6;
  @Input() legendLocation: 'top'|'right'|'bottom'|'left' = 'top';
  @Input() xAxis: Axis;
  @Input() yAxis: Axis;
  @Input() showAverage = false;
  @Input() groupAveragePrefix = '';
  @Input() minValue: number;
  @Input() minValueIfAllValuesArePositive: number;
  @Input() maxValue: number;
  @Input() showValue = true;
  @Input() onlyShowValueOnHover = false;
  @Input() precision = 2;
  @Input() postfix = '';
  @Input() noDataMessage = 'No data found.';
  @Input() noDataSubMessage = 'Select at least one item.';
  @Input() type: 'side-by-side'|'stacked' = 'side-by-side';
  @Input() showTypeSelector = false;
  @Input() addPostMaxGridLine = false;
  @Input() chartAverages: ChartAverage[] = [];
  @Input() mouseOverCallback;
  @Input() mouseOutCallback;
  @Input() switchGroupCallback;
  @Input() selectedGroup: SelectedGroup = { primary: null, secondary: null };
  @Input() showAverageInLegend = true;
  @Input() selectedBarIndex;
  @Input() selectedLegendIndex;
  @Input() groupClickCallback: (group: any) => object;
  @Input() margin: ChartMargin = { top: 0, right: 0, bottom: 0, left: 0 };
  @Input() groupWidth: number;
  @Input() barLeftOffset: number;
  @Input() @HostBinding('class.vertical-labels') useVerticalLabels = false;
  @Input() @HostBinding('class.embedded') isEmbedded = false;
  @Input() @HostBinding('class.no-group-margin') noGroupMargin = false;
  @Input() @HostBinding('class.color-master-groups') colorMasterGroups = false;
  @ViewChild('chartLegend', { static: false }) chartLegend: ElementRef;
  @ViewChild('chartControls', { static: false }) chartControls: ElementRef;
  selectedInternalChartAverageIndex;
  selectedGroupChartAverageValue;
  grid: any[] = [];
  chartGroups: ChartGroup[] = [];
  groupSortOrder: string;
  secondarySortOrder: string;
  chartLegendItems: ChartLegendItem[] = [];
  internalChartAverages: ChartAverage[] = [];
  selectedPlotValue: PlotValueItem;
  private secondaryLookup: object = {};
  private range: Range;
  private plotKey = 'value';
  private fitLegendSubscription: Subscription;
  @HostBinding('class.has-group-select') hasGroupSelect = false;
  @HostBinding('class.has-master-group') hasMasterGroup = false;
  @HostBinding('class.legend-on-bottom') isLegendOnBottom = false;
  @HostBinding('class.legend-on-top') isLegendOnTop = false;
  @HostBinding('class.legend-on-left') isLegendOnLeft = false;
  @HostBinding('class.legend-on-right') isLegendOnRight = false;
  @HostBinding('class.legend-below-controls') isLegendBelowControls = false;

  @Input()
  set forceUpdate(subject: Subject<any>) {
    if (subject) {
      this.fitLegendSubscription = subject.subscribe(() => {
        this.fitLegend();
      });
    }
  }

  constructor(
    private element: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    protected ga: GaService
  ) {}

  ngOnInit() {
    this.setLegendLocation();
    this.setHasMasterGroup();
  }

  ngOnDestroy() {
    this.fitLegendSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.groups && this.groups) {
      const selectedGroup = this.findSelectedGroup();
      if (selectedGroup) {
        this.groupSortOrder = selectedGroup.sortOrder;
        this.secondarySortOrder = selectedGroup.secondarySortOrder;
        this.setSelectedGroups(selectedGroup.primary, selectedGroup.secondary, selectedGroup.master);
      } else {
        this.groupSortOrder = null;
        this.secondarySortOrder = null;
      }
    }

    if (changes.selectedGroup && this.selectedGroup) {
      const currentValue = changes.selectedGroup.currentValue;
      const previousValue = changes.selectedGroup.previousValue;
      if (this.range && (!(currentValue && previousValue) || (currentValue.primary !== previousValue.primary))) {
        this.switchGroupBy(
          this.selectedGroup.primary,
          this.selectedGroup.secondary,
          this.selectedGroup.master,
          this.selectedGroup.secondarySortOrder,
          true
        );
      }
    }

    if (changes.data && this.data && this.data.length && this.dataChanged(changes.data.previousValue, changes.data.currentValue)) {
      this.updateBottomMargin();
      this.updateChart();
    }

    if (changes.chartAverages && this.range && this.range.max) {
      if (!this.internalChartAverages || this.internalChartAverages.length !== this.chartAverages.length) {
        this.internalChartAverages = this.chartAverages;
      }
      this.setRange();
      this.makeGrid();
      this.updateGroups();
      this.updateChartAverages();
    }

    if (changes.plotValues && changes.plotValues.currentValue && changes.plotValues.currentValue.length) {
      this.switchPlotValue(changes.plotValues.currentValue[0]);
    }
  }

  private dataChanged(oldData, newData) {
    return !Utils.compare(oldData, newData);
  }

  private setLegendLocation() {
    switch (this.legendLocation) {
      case 'bottom': return this.isLegendOnBottom = true;
      case 'top': return this.isLegendOnTop = true;
      case 'left': return this.isLegendOnLeft = true;
      case 'right': return this.isLegendOnRight = true;
    }
  }

  private findSelectedGroup() {
    const filteredGroups = this.groups.filter(group => group.isSelected);
    return filteredGroups && filteredGroups.length ? filteredGroups[0] : this.groups[0];
  }

  private updateChart() {
    this.setRange();
    this.makeGrid();
    this.makeSecondaryLookup(this.selectedGroup.primary, this.selectedGroup.secondary);
    this.groupBy(this.selectedGroup.primary, this.selectedGroup.secondary, this.selectedGroup.master);
    this.sortGroup();
    this.sortBars();
    this.makeLegend();
  }

  private updateChartAverages() {
    if (this.chartAverages) {
      this.chartAverages.forEach((chartAverage, index) => {
        const internalAverage = this.internalChartAverages[index];
        internalAverage.label = chartAverage.label;
        internalAverage.value = chartAverage.value;
        internalAverage.y = this.percentOf(chartAverage.value);
      });
    }
  }

  switchType(type: 'side-by-side'|'stacked') {
    if (this.type !== type) {
      this.type = type;
      this.setRange();
      this.makeGrid();
      this.groupBy(this.selectedGroup.primary, this.selectedGroup.secondary, this.selectedGroup.master);
      this.ga.event('button', 'click', 'grouped bar chart type change', type);
    }
  }

  switchGroupBy(primary, secondary, master, secondarySortOrder = null, force = false) {
    if (force || (this.selectedGroup.primary !== primary || this.selectedGroup.secondary !== secondary)) {
      this.secondarySortOrder = secondarySortOrder;
      this.setSelectedGroups(primary, secondary, master);
      this.makeSecondaryLookup(primary, secondary);
      this.setRange();
      this.makeGrid();
      this.groupBy(primary, secondary, master);
      this.sortGroup();
      this.sortBars();
      this.makeLegend();
      this.ga.event('button', 'click', 'grouped bar chart group by change', primary);
    }

    if (Utils.isFunction(this.switchGroupCallback)) {
      this.switchGroupCallback(primary, secondary, secondarySortOrder);
    }
  }

  switchPlotValue(plotValue: PlotValueItem) {
    this.selectedPlotValue = plotValue;
    this.plotKey = this.selectedPlotValue ? this.selectedPlotValue.value : 'value';
    if (this.selectedPlotValue.precision != null) {
      this.precision = this.selectedPlotValue.precision;
    }
    this.setRange();
    this.groupBy(this.selectedGroup.primary, this.selectedGroup.secondary, this.selectedGroup.master);
    this.makeGrid();
    this.sortGroup();
    this.sortBars();
    this.ga.event('button', 'click', 'grouped bar chart plot value change', plotValue.label);
  }

  onBarMouseOver(bar) {
    this.selectedBarIndex = bar.index;
    if (Utils.isFunction(this.mouseOverCallback)) {
      this.mouseOverCallback(bar, 'bar');
    }
  }

  onBarMouseOut() {
    this.selectedBarIndex = null;
    if (Utils.isFunction(this.mouseOutCallback)) {
      this.mouseOutCallback('bar');
    }
  }

  onGroupClick(group) {
    this.ga.event('button', 'click', 'grouped bar chart plot group click', group?.label);
    if (Utils.isFunction(this.groupClickCallback)) {
      this.groupClickCallback(group);
    }
  }

  onLegendItemMouseOver(legendItem) {
    this.selectedLegendIndex = legendItem.index;
    if (Utils.isFunction(this.mouseOverCallback)) {
      this.mouseOverCallback(legendItem, 'legend');
    }
  }

  onLegendItemMouseOut() {
    this.selectedLegendIndex = null;
    if (Utils.isFunction(this.mouseOutCallback)) {
      this.mouseOutCallback('legend');
    }
  }

  onInternalChartAverageMouseOver(averageIndex: number) {
    this.selectedInternalChartAverageIndex = averageIndex;
  }

  onInternalChartAverageMouseOut() {
    this.selectedInternalChartAverageIndex = null;
  }

  onGroupChartAverageMouseOver(group) {
    this.selectedGroupChartAverageValue = group.average.value;
  }

  onGroupChartAverageMouseOut() {
    this.selectedGroupChartAverageValue = null;
  }

  showTooltip(bar: any) {
    bar.isTooltipVisible = true;
  }

  hideTooltip(bar: any) {
    bar.isTooltipVisible = false;
  }

  private sortGroup() {
    this.sortMasterGroup();
    this.sortCharts();
  }

  private sortMasterGroup() {
    if (this.chartGroups?.length > 1 && this.selectedGroup?.master === 'season') {
      this.sortData(this.chartGroups, 'label', 'desc');
    }
  }

  private sortCharts() {
    (this.chartGroups || []).forEach((chartGroup) => {
      if (chartGroup.charts?.length && this.groupSortOrder) {
        this.sortData(chartGroup.charts, 'label', this.groupSortOrder);
      }
    });
  }

  private sortBars() {
    (this.chartGroups || []).forEach((chartGroup) => {
      (chartGroup.charts || []).forEach((group) => {
        this.sortData(group.bars, this.selectedGroup.secondary, this.secondarySortOrder);
      });
    });
  }

  private sortData(data, key, order) {
    if (order === 'asc') {
      data.sort((a, b) => parseFloat(a[key]) - parseFloat(b[key]));
    } else if (order === 'desc') {
      data.sort((a, b) => parseFloat(b[key]) - parseFloat(a[key]));
    }
  }

  private setSelectedGroups(primary: string = null, secondary: string = null, master: string = null) {
    this.initSelectedGroup('primary', primary);
    this.initSelectedGroup('secondary', secondary);
    this.initSelectedGroup('master', master);
  }

  private initSelectedGroup(group, value) {
    if (value) {
      this.selectedGroup[group] = value;
    } else {
      if (!this.selectedGroup[group] && this.groups && this.groups.length) {
        this.selectedGroup[group] = this.groups[0][group];
      }
    }
  }

  private groupBy(primary: string, secondary: string = null, master: string) {
    const groupedCharts = this.getGroupedCharts(primary, secondary, master);
    this.chartGroups = this.getChartGroups(groupedCharts);
    const maxGroupBarLength = this.getMaxBarsInMasterGroups(this.chartGroups);
    this.positionBars();
    this.showAverageInLegend = this.showAverage && maxGroupBarLength > 1;
  }

  private positionBars() {
    let minNegative;
    if (this.type === 'stacked') {
      minNegative = this.getMinNegativeTotalInMasterGroups(this.chartGroups);
    } else {
      minNegative = this.getMinNegativeValueInMasterGroups(this.chartGroups);
    }

    this.chartGroups.forEach((chartGroup) => {
      chartGroup.charts.forEach((chart) => {
        chart.bars.forEach((bar) => {
          if (this.type === 'stacked') {
            bar.topOffset = minNegative ? this.percentOf(minNegative - chart.negativeTotal) : 0;
          } else {
            const value = bar.value || 0;
            const barValue = value < 0 ? value : 0; // Positive bars should be placed at 0
            bar.topOffset = minNegative ? this.percentOf(minNegative - barValue) : 0;
          }
        });
      });
    });
  }

  private getMaxBarsInMasterGroups(masterGroups) {
    return masterGroups.reduce((result, masterGroup) => {
      const maxBarLengthInCharts = this.getMaxBarsInGroups(masterGroup.charts);
      return maxBarLengthInCharts > result ? maxBarLengthInCharts : result;
    }, 0);
  }

  private getMaxBarsInGroups(groups) {
    return groups.reduce((result, group) => {
      return group.bars.length > result ? group.bars.length : result;
    }, 0);
  }

  private getMinNegativeTotalInMasterGroups(masterGroups) {
    return masterGroups.reduce((result, masterGroup) => {
      const minNegative = this.getMinNegative(masterGroup.charts, 'negativeTotal');
      return minNegative < result ? minNegative : result;
    }, 0);
  }

  private getMinNegativeValueInMasterGroups(masterGroups) {
    return masterGroups.reduce((result, masterGroup) => {
      const minNegative = this.getMinNegative(masterGroup.charts, 'minNegativeBarValue');
      return minNegative < result ? minNegative : result;
    }, 0);
  }

  private getMinNegative(data: any[], key: string) {
    return data.reduce((result, item) => {
      return item[key] < result ? item[key] : result;
    }, 0);
  }

  private getChartGroups(groupedCharts): any[] {
    return Object.keys(groupedCharts).map((key) => {
      const groupedBars = groupedCharts[key];
      return {
        label: key,
        charts: this.getCharts(groupedBars)
      };
    });
  }

  private getCharts(groupedBars) {
    return Object.keys(groupedBars).map((key) => {
      const bars = groupedBars[key];
      const [total, negativeTotal] = this.getTotals(bars);
      const minNegativeBarValue = d3.min(bars, (bar) => {
        return bar['value'] < 0 ? bar['value'] : 0;
      });

      const chartGroup: any = {
        label: key,
        bars: bars,
        total: total,
        negativeTotal: negativeTotal,
        minNegativeBarValue: minNegativeBarValue,
        groupIdentifiers: bars[0].groupIdentifiers
      };

      if (this.showAverage && bars.length > 1) {
        const minNegative = this.type === 'stacked' ? negativeTotal : this.range.min;
        chartGroup.average = this.makeGroupAverage(total, bars.length, minNegative);
      }
      return chartGroup;
    });
  }

  private getGroupedCharts(primary: string, secondary: string, master: string): object {
    const res = this.data.reduce((result, item) => {
      const masterItemKey = item[master] || 'all';
      result[masterItemKey] = this.getGroupedBars(primary, secondary, master, masterItemKey);
      return result;
    }, {});
    return res;
  }

  private getGroupedBars(primary: string, secondary: string, master: string, masterFilterValue: string|number = 'all'): object {
    return this.data.reduce((result, item) => {
      const shouldShowValue = (this.showZeroValues && item[this.plotKey] != null) || item[this.plotKey];
      const valueMeetsFilterCondition = masterFilterValue === 'all' || item[master] === masterFilterValue;
      if (shouldShowValue && valueMeetsFilterCondition) {
        const value = item.exclude ? 0 : item[this.plotKey];
        const formattedItem = {
          value: value,
          exclude: item.exclude,
          excludeMessage: item.excludeMessage,
          height: Math.abs(this.percentOf(value)),
          index: this.secondaryLookup[item[secondary]]?.index,
          groupIdentifiers: item.groupIdentifiers,
          cssClass: item.cssClass || ''
        };
        formattedItem[secondary] = item[secondary];
        formattedItem[master] = item[master];

        const primaryItemKey = item[primary];
        if (result[primaryItemKey]) {
          result[primaryItemKey].push(formattedItem);
        } else {
          result[primaryItemKey] = [formattedItem];
        }
      }
      return result;
    }, {});
  }

  private getTotals(bars: any[]): [number, number] {
    let total = 0;
    let negativeTotal = 0;
    bars.forEach((bar) => {
      const value = bar.value || 0;
      total += value;
      if (value < 0) {
        negativeTotal += value;
      }
    });
    return [total, negativeTotal];
  }

  private updateGroups() {
    this.chartGroups.forEach((chartGroup) => {
      chartGroup.charts.forEach((chart) => {
        chart.bars.forEach((bar) => {
          bar.height = Math.abs(this.percentOf(bar.value || 0));
        });

        if (chart.average && chart.total && chart.bars) {
          const minNegative = this.type === 'stacked' ? chart.negativeTotal : this.range.min;
          chart.average = this.makeGroupAverage(chart.total, chart.bars.length, minNegative);
        }
      });
    });
  }

  private makeGroupAverage(total, numItems, minNegative) {
    const avg = total / numItems;
    const y = this.percentOf(avg - minNegative);
    return { value: this.roundFloat(avg, this.precision), y: y };
  }

  private makeGrid() {
    if (this.showGrid) {
      this.grid = [];
      const valueSpan = (this.range.max - this.range.min) || 1;
      let currentValue;
      const gridInterval = this.niceInterval(valueSpan, this.numGridLines);
      const precision = this.calcOptimalPrecision(gridInterval);

      // If the grid includes both negative and positive values they need to be created separately
      if (this.range.min <= 0 && this.range.max >= 0) {
        currentValue = 0;
        this.grid.push(this.makeGridItem(0, valueSpan, precision));

        // Go up till max
        currentValue = gridInterval;
        while (currentValue < this.range.max) {
          this.grid.push(this.makeGridItem(currentValue, valueSpan, precision));
          currentValue += gridInterval;
        }

        // Go down till min
        currentValue = -gridInterval;
        while (currentValue > this.range.min) {
          this.grid.push(this.makeGridItem(currentValue, valueSpan, precision));
          currentValue -= gridInterval;
        }
      } else {
        currentValue = this.range.min;
        while (currentValue < this.range.max) {
          this.grid.push(this.makeGridItem(currentValue, valueSpan, precision));
          currentValue += gridInterval;
        }
      }

      // Push one more item past the max value
      // Makes the chart look more "square"
      if (this.addPostMaxGridLine) {
        this.grid.push(this.makeGridItem(currentValue, valueSpan, precision));
      }
    }
  }

  private calcOptimalPrecision(interval: number) {
    const match = ('' + interval).match(/^(0\.?)+/);
    return match ? match[0].length - 1 : 0;
  }

  private makeGridItem(currentValue, valueSpan, precision = 1) {
    const y = 100 * ((currentValue - this.range.min) / valueSpan);
    return { y: y, value: currentValue.toFixed(precision), isZero: currentValue === 0 };
  }

  private niceInterval(range, targetSteps) {
    const guess = range / targetSteps;
    const magPow = Math.pow(10, Math.floor(Math.log(guess) / Math.log(10)));
    return Math.round(guess / magPow + 0.5) * magPow;
  }

  private makeLegend() {
    if (this.showLegend) {
      this.chartLegendItems = Object.keys(this.secondaryLookup).map((key) => {
        return {
          label: key,
          index: this.secondaryLookup[key].index,
          cssClass: this.secondaryLookup[key].cssClass
        };
      });
      this.sortData(this.chartLegendItems, 'label', this.secondarySortOrder);
      this.fitLegend();
    }
  }

  // Move the legend down if it overlaps the chart controls
  private fitLegend() {
    if (this.legendLocation === 'bottom') {
      setTimeout(() => {
        const componentWidth = this.element.nativeElement.offsetWidth;
        const legendWidth = this.chartLegend.nativeElement.offsetWidth;
        const controls = this.chartControls.nativeElement.children;
        let controlsWidth = 0;
        for (const control of controls) {
          controlsWidth += control.offsetWidth;
        }
        this.isLegendBelowControls = componentWidth < controlsWidth + legendWidth;
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  private makeSecondaryLookup(primary: string, secondary: string) {
    const uniqueSecondaryValues = this.getUniqueSecondaryValues(primary, secondary);
    this.secondaryLookup = this.getSecondaryLookup(uniqueSecondaryValues);
  }

  private getUniqueSecondaryValues(primary: string, secondary: string): SecondaryValue[] {
    return this.data.reduce((result, item) => {
      const secondaryValue = item[secondary];
      if (((this.showZeroValues && item[this.plotKey] != null) || item[this.plotKey])
        && !this.includesPropertyWithValue(result, 'value', secondaryValue)) {
        result.push({ value: secondaryValue, groupLink: (item.groupLinks || {})[primary], cssClass: item.cssClass });
      }
      return result;
    }, []);
  }

  // All values sharing the same non null groupLink are assigned the same index
  private getSecondaryLookup(values: SecondaryValue[]): object {
    const usedGroupLinks = [];
    let currentIndex = -1;
    return values.reduce((result, item) => {
      if (!item.groupLink) {
        currentIndex++;
      } else if (!usedGroupLinks.includes(item.groupLink)) {
        usedGroupLinks.push(item.groupLink);
        currentIndex++;
      }
      result[item.value] = { index: currentIndex, cssClass: item.cssClass };
      return result;
    }, {});
  }

  private includesPropertyWithValue(data: any[], key: string|number, value: string|number) {
    for (const item of data) {
      if (item[key] === value) {
        return true;
      }
    }
    return false;
  }

  private setRange() {
    let [min, max] = this.type === 'stacked' ? this.getStackedChartRange() : this.getSideBySideChartRange();

    if (this.minValue != null) {
      min = this.minValue;
    } else if (min > 0 && this.minValueIfAllValuesArePositive != null) {
      min = this.minValueIfAllValuesArePositive;
    }

    if (this.maxValue != null) {
      max = this.maxValue;
    }

    if (this.chartAverages) {
      this.chartAverages.forEach((average) => {
        if (average.value > max) {
          max = average.value;
        }
      });
    }

    this.range = { min: min, max: max };
  }

  private getSideBySideChartRange() {
    return d3.extent(this.data, item => (item[this.plotKey] || 0));
  }

  // Min is the smallest group sum of negative values, max is the largest group sum of positive values
  private getStackedChartRange() {
    const binnedTotals = {};
    this.data.forEach((item) => {
      const bin = item[this.selectedGroup.primary] + (item[this.selectedGroup.master] || '');
      let binnedTotal = binnedTotals[bin];
      if (!binnedTotal) {
        binnedTotal = { positive: 0, negative: 0 };
        binnedTotals[bin] = binnedTotal;
      }

      if (item.value < 0) {
        binnedTotal.negative += (item[this.plotKey] || 0);
      } else {
        binnedTotal.positive += (item[this.plotKey] || 0);
      }
    });

    let min = 0;
    let max = 0;
    Object.keys(binnedTotals).forEach((key) => {
      const value = binnedTotals[key];
      const negativeValue = value.negative || 0;
      const positiveValue = value.positive || 0;
      min = negativeValue < min ? negativeValue : min;
      max = positiveValue > max ? positiveValue : max;
    });

    return [min, max];
  }

  private percentOf(value: number) {
    return 100 * value / (this.range.max - this.range.min);
  }

  private roundFloat(value: number, precision: number) {
    return parseFloat(value.toFixed(precision));
  }

  private setHasMasterGroup() {
    this.hasMasterGroup = (this.groups || []).some(item => !!item.master);
  }

  private updateBottomMargin() {
    if (this.useVerticalLabels) {
      const letterWidth = 7;
      const primaryKey = this.selectedGroup['primary'];

      const maxLength = this.data.reduce((result, item) => {
        const label = item[primaryKey];
        return label.length > result ? label.length : result;
      }, 0);

      const requiredBottomMargin = maxLength * letterWidth;
      if (this.margin.bottom < requiredBottomMargin) {
        this.margin.bottom = requiredBottomMargin;
      }
    }
  }
}
