import { Component, AfterViewInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { OrchardGeometry, OrchardGeometryLayers, OrchardGeometryService } from './orchard-geometry.service';
import { Subject, of, Subscription, catchError } from 'rxjs';
import { ResizeService } from 'app/shared/services/resize.service';
import {
  featureGroup,
  LatLngBounds,
  divIcon,
  DomEvent,
  DomUtil,
  geoJSON,
  icon,
  marker,
  tileLayer,
  map as lMap,
  Control,
  control as lControl
} from 'leaflet';
import { Document } from 'app/shared/document-viewer/document-viewer.component';
import { SettingsService } from 'app/shared/services/settings.service';
import { MessageService } from 'app/shared/services/message.service';
import { GaService } from 'app/shared/services/ga.service';
import { Utils } from 'app/shared/utils';

export interface LatLng {
  lat: number;
  lng: number;
}

export interface Orchard {
  id: number;
  mapDocumentId?: number;
}

export const CURRENTLY_EDITING_GEOMETRY_MESSAGE = 'Your orchard map is being edited, please try again later.';
export const NO_GEOMETRY_FEATURES = 'No map available.';

@Component({
  selector: 'app-orchard-map',
  templateUrl: './orchard-map.component.html',
  styleUrls: ['./orchard-map.component.scss'],
  providers: [OrchardGeometryService]
})
export class OrchardMapComponent implements AfterViewInit, OnDestroy {
  loadData: Subject<Orchard> = new Subject<Orchard>();
  errorMessage: string;

  private map;
  private orchardId;
  private mapDocumentId;
  private layerControls;
  private dataKeys = {
    blocks: 'blocks',
    monitorBays: 'monitor_bays',
    orchardObjectsLines: 'object_lines',
    orchardObjectsPoints: 'object_points',
    orchardObjectsPolygons: 'object_polygons'
  };
  private renderedLayers = {};
  private geometryData: OrchardGeometryLayers;
  private printMapButtonControl;
  private printMapButtonDisplayed;
  private loadDataSubscription: Subscription;
  private fetchGeometrySubscription: Subscription;

  @Output() error = new EventEmitter();
  @Output() complete = new EventEmitter();
  @ViewChild('mapElement', { static: false }) mapElement: ElementRef;

  constructor(
    private geometry: OrchardGeometryService,
    private resize: ResizeService,
    private messageService: MessageService,
    private ga: GaService
  ) {
    this.resize.addOnEndHandler(this.refreshMap.bind(this));
  }

  ngOnDestroy() {
    this.loadDataSubscription?.unsubscribe();
    this.fetchGeometrySubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    this.initialiseMap();
    this.initialiseData();
  }

  private initialiseMap() {
    const googleStreetTiles = this.createTileLayer(
      'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      { subdomains: ['mt0', 'mt1', 'mt2', 'mt3'] }
    );
    const googleHybridTiles = this.createTileLayer(
      'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
      { subdomains: ['mt0', 'mt1', 'mt2', 'mt3'] }
    );

    this.map = lMap(this.mapElement.nativeElement, {
      maxBoundsViscosity: 1.0,
      layers: [googleHybridTiles],
      center: [-38.97, -177.71],
      zoom: 6
    });
    // Remove the Leaflet attribution link
    this.map.attributionControl.setPrefix('');

    const baseMaps = {
      'Map': googleStreetTiles,
      'Satellite': googleHybridTiles
    };
    this.layerControls = lControl.layers(baseMaps, null, { hideSingleBase: true }).addTo(this.map);

    this.addExtraControls();
    this.addMonitorBaysLayer();
    this.addOrchardObjectsLayer();
    this.addBlocksLayer();
  }

  private initialiseData() {
    this.loadDataSubscription = this.loadData.subscribe((orchard: Orchard) => {
      this.orchardId = orchard.id;
      this.mapDocumentId = orchard.mapDocumentId;
      this.displayPrintMapButton();
      this.geometryData = null;

      this.fetchGeometrySubscription = this.geometry.fetch(orchard.id).pipe(
        catchError((error) => {
          this.error.emit(error);
          this.errorMessage = 'Failed to load map.';
          return of();
        })
      ).subscribe((geometry: OrchardGeometry) => {
        if (geometry.currently_editing_geometry) {
          this.setCurrentlyEditingGeometryState();
        } else if (!geometry.has_geometry) {
          this.setNoGeometryFeaturesState()
        } else {
          this.errorMessage = null;
          this.geometryData = geometry.layers;
          this.updateMapGeometry();
        }
      });
    });
  }

  private setCurrentlyEditingGeometryState() {
    this.errorMessage = CURRENTLY_EDITING_GEOMETRY_MESSAGE;
    this.error.emit(CURRENTLY_EDITING_GEOMETRY_MESSAGE);
  }

  private setNoGeometryFeaturesState() {
    this.errorMessage = NO_GEOMETRY_FEATURES;
    this.error.emit(NO_GEOMETRY_FEATURES);
  }

  private openSuggestMapEditModal() {
    const document: Document = {
      title: 'Suggest a Map Change',
      content: 'feedback',
      messageType: 'map_change',
      topMessage: '',
      thankYouMessage: 'Thank you. We will look in to your request shortly.',
      showSurvey: false,
      showSaveUser: false,
      sendFeedbackNow: true,
      isAdaptive: true,
      additionalData: { orchard_id: this.orchardId }
    };
    this.ga.event('map', 'show', 'suggest an edit open', { orchard_id: this.orchardId });
    this.messageService.send(SettingsService.SHOW_DOCUMENT_MESSAGE, document);
    return false;
  }

  private openOrchardMapDocument() {
    const url = Utils.getDocumentPath(this.mapDocumentId);
    if (url) {
      window.open(url, '_blank');
    }
    return false;
  }

  private createTileLayer(url: string, extraOptions= {}) {
    const defaultOptions = {
      maxZoom: 20,
      minZoom: 5,
      attribution: 'Map data &copy; Google, DigitalGlobe'
    };
    return new tileLayer(url, { ...defaultOptions, ...extraOptions });
  }

  private addExtraControls() {
    const printMapButton = Control.extend({
        options: {
          position: 'bottomright'
        },
        onAdd: (map) => {
          const containerName = 'custom-leaflet-controls';
          const container = DomUtil.create('div', containerName + ' leaflet-bar');
          this.createButton(
            'Print Map',
            null,
            'text-control',
            container,
            this.openOrchardMapDocument.bind(this)
          );

          return container;
        }
      });
    this.printMapButtonControl = new printMapButton();

    const suggestAnEditButton = Control.extend({
      options: {
        position: 'bottomleft'
      },
      onAdd: (map) => {
        const containerName = 'custom-leaflet-controls';
        const container = DomUtil.create('div', containerName + ' leaflet-bar');
        this.createButton(
          'Suggest an edit?',
          null,
          'text-control',
          container,
          this.openSuggestMapEditModal.bind(this)
        );

        return container;
      }
    });
    this.map.addControl(new suggestAnEditButton());

    const centerOnOrchardButton = Control.extend({
      options: {
        position: 'bottomleft'
      },
      onAdd: (map) => {
        const containerName = 'custom-leaflet-controls';
        const container = DomUtil.create('div', containerName + ' leaflet-bar');

        this.createButton(
          '<i class="fas fa-map-marker-alt"></i>',
          'Center on orchard',
          '',
          container,
          this.setMapBounds.bind(this)
        );
        return container;
      }
    });
    this.map.addControl(new centerOnOrchardButton());
  }

  private displayPrintMapButton() {
    if (!this.mapDocumentId) {
      this.map.removeControl(this.printMapButtonControl);
      this.printMapButtonDisplayed = false;
      return;
    }

    if (!this.printMapButtonDisplayed) {
      this.printMapButtonDisplayed = true;
      this.map.addControl(this.printMapButtonControl);
    }
  }

  private createButton(
    html: string,
    title: string,
    className: string,
    container: HTMLElement,
    fn: Function
  ): HTMLElement {
    const button = DomUtil.create('a', className, container);
    button.innerHTML = html;
    button.href = '#';
    if (title) {
      button.title = title;
    }

    DomEvent.disableClickPropagation(button);
    button.addEventListener('click', DomEvent.stop);
    button.addEventListener('click', fn);

    return button;
  }

  private addBlocksLayer() {
    const layerBlocks = geoJSON(
      null,
      {
        style: (feature) => {
          return {
            fill: false,
            className: `block variety-${ feature.properties.variety_code.toLowerCase() }`,
          };
        },
        onEachFeature: (feature, layer) => {
          // Add block labels
          const bounds = layer.getBounds();
          const center = bounds.getCenter();
          const icon = divIcon({
            iconSize: 0,
            html: `<div class="block-label-container">
                     <span class="block-label">
                       Block: ${ feature.properties.block_name }<br>
                       ${ feature.properties.planted_ha } ha
                       ${ feature.properties.maturity_area_name ? `<br>MA: ${ feature.properties.maturity_area_name }`: '' }
                     </span>
                   </div>`,
          });
          marker(center, { icon: icon, zIndexOffset: 999 }).addTo(layerBlocks);
        }
      }
    ).addTo(this.map);
    this.layerControls.addOverlay(layerBlocks, 'Blocks');
    this.renderedLayers['blocks'] = layerBlocks;
  }

  private addMonitorBaysLayer() {
    const layerMonitorBays = geoJSON(
      null,
      {
        pointToLayer: (feature, latLong: LatLng) => {
          const icon = divIcon({
            iconSize: 0,
            html: `<div class="monitor-bay-label-container">
                     <span class="monitor-bay-label">
                       ${ feature.properties.row_number }/${ feature.properties.bay_number }
                     </span>
                   </div>`,
          });
          return marker(latLong, { icon: icon });
        },
      }
    ).addTo(this.map);
    this.layerControls.addOverlay(layerMonitorBays, 'Monitor Bays');

    this.renderedLayers['monitorBays'] = layerMonitorBays;
  }

  private addOrchardObjectsLayer() {
    const layerOrchardObjects = geoJSON(
      null,
      {
        // Style the orchard object points
        pointToLayer: (feature, latLong: LatLng) => {
          return marker(latLong, { icon: this.getOrchardObjectPointIcon(feature) });
        },
        // Style the orchard object lines and polygons
        style: (feature) => {
          return {
            weight: 2,
            className: `orchard-object path ${ this.getOrchardObjectClassName(feature.properties.object_type) }`,
          };
        },
        onEachFeature: (feature, layer) => {
          const objectType = this.getOrchardObjectClassName(feature.properties.object_type);
          if (objectType === 'bay' || objectType === 'note') {
            return;
          }
          setTimeout(() => {
            layer.bindTooltip(
              `${ feature.properties.object_type }<br>${ feature.properties.comment }`,
              { sticky: true }
            );
          }, 0);
        }
      }
    ).addTo(this.map);
    this.layerControls.addOverlay(layerOrchardObjects, 'Orchard Objects');
    this.renderedLayers['orchardObjects'] = layerOrchardObjects;
  }

  private getOrchardObjectClassName(objectType: string): string {
    return objectType.toLowerCase().replace(/\s/g, '-');
  }

  private getOrchardObjectPointIcon(feature) {
    const objectType = this.getOrchardObjectClassName(feature.properties.object_type);
    if (objectType === 'bay' || objectType === 'note') {
      const content = feature.properties.comment;
      if (!content) {
        return divIcon({ iconSize: 0 });
      }
      return divIcon({
        iconSize: 0,
        html: `<div class="orchard-object-label-container">
                 <span class="orchard-object icon ${ objectType }">
                   ${ feature.properties.comment }
                 </span>
               </div>`,
        className: objectType === 'note' ? 'note' : ''
      });
    }

    let iconName = null;
    switch (objectType) {
      case 'first-aid':
      case 'hazard':
      case 'house':
      case 'phone':
      case 'pump':
      case 'restricted':
      case 'shed':
      case 'spray-sign':
      case 'tank':
      case 'toilet':
      case 'trees':
      case 'water':
      case 'water-filler':
      case 'water-inlet':
      case 'windmill':
        iconName = objectType;
        break;
      case 'chemical-shed':
        iconName = 'shed';
        break;
      case 'entry':
        iconName = 'entrance';
        break;
      case 'evac':
        iconName = 'assembly';
        break;
      case 'hs':
        iconName = 'hs-board';
        break;
      case 'load':
      case 'loading bay':
        iconName = 'loading';
        break;
      case 'park':
        iconName = 'parking';
        break;
      case 'powerpole':
        iconName = 'power-pole';
        break;
      default:
        iconName = 'default';
        break;
    }
    const iconUrl =  `/static/images/orchard_map/${ iconName }.svg`;
    return icon({
      iconUrl: iconUrl,
      iconSize: [25, 25],
      className: 'orchard-object icon ' + objectType
    });
  }

  private updateMapGeometry() {
    this.clearLayers();
    this.setRenderedLayers();
    this.refreshMap();
    setTimeout(() => {
      // There appears to be a timing issue where the map hasn't panned to the orchard before it gets the center of the
      // viewbox. The setTimeout fixes that issue.
      this.complete.emit(this.map.getCenter());
    });
  }

  private clearLayers() {
    for (const key in this.renderedLayers) {
      this.renderedLayers[key].clearLayers();
    }
  }

  private setRenderedLayers() {
    if (this.geometryData) {
      this.renderedLayers['blocks'].addData(this.geometryData[this.dataKeys.blocks]);
      this.renderedLayers['monitorBays'].addData(this.geometryData[this.dataKeys.monitorBays]);
      this.renderedLayers['orchardObjects'].addData(this.geometryData[this.dataKeys.orchardObjectsLines]);
      this.renderedLayers['orchardObjects'].addData(this.geometryData[this.dataKeys.orchardObjectsPoints]);
      this.renderedLayers['orchardObjects'].addData(this.geometryData[this.dataKeys.orchardObjectsPolygons]);
    }
  }

  private setMapBounds() {
    const bounds = this.getMaximumBoundingBox();
    if (bounds.isValid()) {
      this.map.fitBounds(bounds);
    } else {
      throw new Error(`Invalid map bounds for orchard ID "${ this.orchardId }"`);
    }
  }

  private getMaximumBoundingBox(): LatLngBounds {
    const layers = Object.keys(this.renderedLayers).map((key) => {
      return this.renderedLayers[key];
    });
    return featureGroup(layers).getBounds();
  }

  private refreshMap() {
    if (this.orchardId) {
      setTimeout(() => {
        if (this.map) {
          this.map.invalidateSize();
          this.setMapBounds();
        }
      });
    }
  }
}
