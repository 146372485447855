import { Component, OnInit, Input } from '@angular/core';
import { TabbedVarietyGrowMethod } from 'app/lib/tabbed-variety-grow-method';
import { HttpService } from 'app/shared/services/http.service';
import { SeasonDropdownItem } from 'app/grower-portal-dashboard/grower-portal-dashboard.component';
import { WidgetHeaderButton } from 'app/widget/widget.component';
import { VarietyGrowMethodTab } from 'app/interfaces/tab.interface';
import { Utils } from 'app/shared/utils';

export const AVOCADO_PACKOUT_SEASONS_ENDPOINT = 'growers/avocado/production/seasons/';
export const AVOCADO_PACKRUN_ENDPOINT = 'growers/avocado/production/summary/orchards/';
export const AVOCADO_AVERAGES_ENDPOINT = 'growers/avocado/production/summary/averages/';
export const PACKOUT_REPORT_URL = 'api2/v1/harvest/reports/packout';

export interface AvocadoProductionAverage {
  area_name?: string;
  class_1_trays_per_bin: number;
  class_1_average_size: number;
  class_2_trays_per_bin: number;
  class_2_average_size: number;
}

export interface AvocadoProductionItem {
  area_id: number;
  area_name: string;
  level?: number;
  parent?: AvocadoProductionItem;
  children?: AvocadoProductionItem[];
  groupColumnValue?: string;
  groupColumnSubValue?: string;
  class_1_average_size: number;
  class_1_trays_per_bin: number;
  class_1_trays_total: number;
  class_2_average_size: number;
  class_2_trays_per_bin: number;
  class_2_trays_total: number;
  estimated_bins: number;
  grow_method_code: string;
  grow_method_id: number;
  grower_number: string;
  isHidden: boolean;
  maturity_area_id: number;
  maturity_area_name: string;
  orchard_id: number;
  packrun_date: string;
  packrun_id: number;
  packrun_name: string;
  tipped_bins: number;
  variety_code: string;
  variety_id: number;
}

@Component({
  selector: 'grow-avocado-production-widget',
  templateUrl: './avocado-production-widget.component.html',
  styleUrls: ['./avocado-production-widget.component.scss']
})
export class AvocadoProductionWidgetComponent extends TabbedVarietyGrowMethod implements OnInit {
  @Input() updatedAt: Date = new Date();
  @Input() emptyPlaceholder = 'NA';
  isLoading = true;
  availableSeasons: SeasonDropdownItem[] = [];
  selectedSeason: SeasonDropdownItem = null;
  headerButtons: WidgetHeaderButton[] = [];
  gridData: AvocadoProductionItem[] = [];
  gridDataFiltered: AvocadoProductionItem[] = [];
  groupedLabelFields = [
    { key: 'grower_number', label: 'PPIN' },
    { key: 'maturity_area_name', label: 'MA' },
    { key: 'packrun_name', label: 'Packrun & Date' }
  ];
  groupedLabels = this.getGroupedLabel(this.groupedLabelFields.map(item => item.label));
  gridColumns = [
    {
      tabGroup: 'main',
      label: this.groupedLabels,
      rowspan: 2, align: 'left',
      key: 'groupColumnValue',
      subValue: 'groupColumnSubValue',
      cssClass: 'fixed-extra-wide'
    },
    {
      label: 'Bins', align: 'center', childColumns: [
        { label: 'Est.', key: 'estimated_bins', precision: 0 },
        { label: 'Tipped', key: 'tipped_bins', precision: 0 }
      ]
    },
    {
      label: 'Class 1 Trays', align: 'center', childColumns: [
        { label: 'Total', key: 'class_1_trays_total', cssClass: 'c1-trays-total', precision: 1 },
        { label: 'Per Bin', key: 'class_1_trays_per_bin', cssClass: 'c1-trays-per-bin', precision: 1 },
        { label: 'Avg. Size', key: 'class_1_average_size', cssClass: 'c1-average-size', precision: 1 }
      ]
    },
    {
      label: 'Class 2 Trays', align: 'center', childColumns: [
        { label: 'Total', key: 'class_2_trays_total', cssClass: 'c2-trays-total', precision: 1 },
        { label: 'Per Bin', key: 'class_2_trays_per_bin', cssClass: 'c2-trays-per-bin', precision: 1 },
        { label: 'Avg. Size', key: 'class_2_average_size', cssClass: 'c2-average-size', precision: 1 }
      ]
    },
    {
      label: 'Packout Report',
      align: 'center',
      key: 'report',
      allowEmpty: true,
      cssClass: 'download-report fixed-70',
      action: this.onDownloadPackoutReportButtonClick.bind(this),
      rowspan: 2
    }
  ];
  areDetailsVisible = true;
  pieChartSubLabel = 'Tipped / Est.';
  pieChartSize = 200;
  tippedPercent = 0;
  isLoadingAverages = true;
  loadingAveragesMessage = '';
  regionName = '';
  additionalDataColumns = [
    {
      label: 'Class 1<br>Trays Per Bin',
      key: 'class_1_trays_per_bin',
      cssClass: 'c1-trays-per-bin' ,
      precision: 1,
      betterIsGreaterThanAverage: true
    },
    {
      label: 'Class 1<br>Average Size',
      key: 'class_1_average_size',
      cssClass: 'c1-average-size' ,
      precision: 1,
      betterIsGreaterThanAverage: false
    },
    {
      label: 'Class 2<br>Trays Per Bin',
      key: 'class_2_trays_per_bin',
      cssClass: 'c2-trays-per-bin' ,
      precision: 1,
      betterIsGreaterThanAverage: true
    },
    {
      label: 'Class 2<br>Average Size',
      key: 'class_2_average_size',
      cssClass: 'c2-average-size' ,
      precision: 1,
      betterIsGreaterThanAverage: false
    },
  ];
  selectedRow: AvocadoProductionItem = {} as AvocadoProductionItem;
  regionAverageData: AvocadoProductionAverage = {} as AvocadoProductionAverage;
  trevelyansAverageData: AvocadoProductionAverage = {} as AvocadoProductionAverage;

  constructor(
    private http: HttpService
  ) {
    super();
  }

  async ngOnInit() {
    this.isLoading = true;
    this.availableSeasons = await this.getSeasons();
    if (this.availableSeasons?.length) {
      this.selectedSeason = this.availableSeasons[0];
      await this.loadGridData();
    }
    this.isLoading = false;
  }

  async selectSeason(season: SeasonDropdownItem) {
    if (this.selectedSeason?.label !== season.label) {
      this.selectedSeason = season;
      this.isLoading = true;
      await this.loadGridData();
      this.isLoading = false;
    }
  }

  onTabSelect(event: any) {
    const newTab = this.tabs[event.index];
    if (newTab.variety_id !== this.selectedTab.variety_id || newTab.grow_method_id !== this.selectedTab.grow_method_id) {
      this.selectedTab = this.tabs[event.index];
      this.filterGridData(this.selectedTab);
      if (this.gridDataFiltered?.length) {
        this.selectRow(this.gridDataFiltered[0]);
        return this.loadAverages();
      }
    }
  }

  onGridRowSelect(row: AvocadoProductionItem) {
    this.selectRow(row);
    this.setTippedPercent();
    if (row.level === 0 && this.selectedRow.orchard_id !== row.orchard_id) {
      return this.loadRegionAverageDataStandAlone();
    }
  }

  private setTippedPercent() {
    if (this.selectedRow) {
      // Use MA data when a packrun level row is selected
      const row = this.selectedRow.level === 2 ? this.selectedRow.parent : this.selectedRow;
      this.tippedPercent = row.estimated_bins ? Utils.roundTo(row.tipped_bins / row.estimated_bins * 100, 1) : 100;
    } else {
      this.tippedPercent = 0;
    }
  }

  protected tabReducer(result: any[], obj: any): any[] {
    result.push({
      title: obj.variety_code + obj.grow_method_code,
      variety_code: obj.variety_code,
      variety_id: obj.variety_id,
      grow_method_code: obj.grow_method_code,
      grow_method_id: obj.grow_method_id,
      selected: true
    });
    return result;
  }

  private async getSeasons(): Promise<SeasonDropdownItem[]> {
    const data = await this.fetchSeasons();

    return data.map((item: { season: number }) => {
      return { label: item.season, value: item.season };
    });
  }

  private fetchSeasons(): Promise<any> {
    return this.http.get(AVOCADO_PACKOUT_SEASONS_ENDPOINT).toPromise();
  }

  private filterGridData(tab: VarietyGrowMethodTab) {
    this.gridDataFiltered = this.gridData.filter(obj => {
      return obj.variety_id === tab.variety_id && obj.grow_method_id === tab.grow_method_id;
    });
  }

  private async loadGridData() {
    const params: any = { season: this.selectedSeason.value };
    const data = await this.http.get(AVOCADO_PACKRUN_ENDPOINT, { params: params }).toPromise() as AvocadoProductionItem[];
    if (data) {
      return this.initLoadedData(data);
    } else {
      this.gridDataFiltered = [];
    }
  }

  private initLoadedData(data) {
    this.gridData = this.flattenChildren(data);
    this.transformGridData();
    this.setTabs(this.gridData, this.tabReducer.bind(this));
    if (this.tabs?.length) {
      this.sortTabs();
      this.initSelectedTab();
      this.filterGridData(this.selectedTab);

      if (this.gridDataFiltered?.length) {
        this.selectRow(this.gridDataFiltered[0]);
        return this.loadAverages();
      }
    }
  }

  private selectRow(row: AvocadoProductionItem) {
    this.selectedRow = row;
  }

  private async loadAverages() {
    this.loadingAveragesMessage = 'Loading comparison data.';
    this.isLoadingAverages = true;
    await Promise.all([
      this.loadTrevelyansAverageData(),
      this.loadRegionAverageData()
    ]);
    this.isLoadingAverages = false;
  }

  private async loadTrevelyansAverageData() {
    this.trevelyansAverageData = await this.fetchAverageData();
  }

  private async loadRegionAverageData() {
    this.regionAverageData = await this.fetchAverageData({ area: this.selectedRow.area_id });
    this.regionAverageData.area_name = this.selectedRow.area_name;
  }

  private async loadRegionAverageDataStandAlone() {
    this.isLoadingAverages = true;
    this.loadingAveragesMessage = 'Loading regional comparison data.';
    await this.loadRegionAverageData();
    this.isLoadingAverages = false;
  }

  private fetchAverageData(additionalParams: object | null = null): Promise<AvocadoProductionAverage> {
    const params: any = {
      season: this.selectedSeason.value,
      grow_method: this.selectedTab.grow_method_id,
      variety: this.selectedTab.variety_id
    };
    Object.assign(params, additionalParams);
    return this.http.get(AVOCADO_AVERAGES_ENDPOINT, { params: params }).toPromise() as Promise<AvocadoProductionAverage>;
  }

  private transformGridData() {
    this.gridData.forEach((item) => {
      if (item.level === 2) {
        item.estimated_bins = null;
      }
    });
  }

  private flattenChildren(data: AvocadoProductionItem[], parent = null, flatData = [], level = 0) {
    data.forEach((item) => {
      item.level = level;
      item.parent = parent;
      item.isHidden = level > 0;
      item.packrun_date = Utils.formatDateShort(item.packrun_date);
      item.groupColumnValue = this.getGroupColumnValue(item, level);
      item.groupColumnSubValue = item.level === 2 ? item.packrun_date : null;
      if (!item.area_name) {
        item.area_name = parent?.area_name;
      }
      flatData.push(item);
      if (item.children) {
        this.flattenChildren(item.children, item, flatData, level + 1);
      }
    });
    return flatData;
  }

  private getGroupColumnValue(row, level) {
    switch (level) {
      case 0: return row.grower_number;
      case 1: return row.maturity_area_name;
      case 2: return this.getAbbreviatedPackrunName(row.packrun_name);
      default: return null;
    }
  }

  private getAbbreviatedPackrunName(packrunName: string): string {
    return (packrunName || '').split('.')[1];
  }

  private getGroupedLabel(groupedLabels) {
    return groupedLabels.reduce((result, groupLabel, index) => {
      return result + `<div class="group-label level-${ index }">${ groupLabel }</div>`;
    }, '');
  }

  private onDownloadPackoutReportButtonClick(row: AvocadoProductionItem) {
    const url = `${ PACKOUT_REPORT_URL }/avocado/?${ this.getPackoutReportQueryString(row) }`;
    Utils.openGrowerReport(url);
  }

  private getPackoutReportQueryString(row: AvocadoProductionItem): string {
    switch (row.level) {
      case 0: return `id=${ row.orchard_id }&type=orchard&variety=${ row.variety_id }&grow_method=${ row.grow_method_id }`;
      case 1: return `id=${ row.maturity_area_id }&type=ma`;
      case 2: return `id=${ row.packrun_id }&type=packrun`;
    }
  }
}
