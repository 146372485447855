import { TutorialService } from 'app/shared/services/tutorial.service';
import {
  Component,
  OnInit,
  AfterViewInit,
  HostBinding,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { SeasonDropdownItem } from 'app/grower-portal-dashboard/grower-portal-dashboard.component';
import { HttpService } from 'app/shared/services/http.service';
import { Utils } from 'app/shared/utils';
import { Details, Tab, DataCategoryTab, Level } from '../lib/details';
import { ActivatedRoute } from '@angular/router';
import { SelectedGroup } from 'app/grouped-bar-chart/grouped-bar-chart.component';
import { getOgrDetailsSteps } from './ogr-details-tutorial';
import { Subscription } from 'rxjs';
import { GaService } from 'app/shared/services/ga.service';

export const OGR_REPORT_FILE_ENDPOINT = 'api2/v1/orchards/documents';
export const OGR_REPORT_ENDPOINT = 'growers/kiwifruit/ogr/details/';
export const OGR_SEASONS_AND_FORECASTS_ENDPOINT = 'growers/kiwifruit/ogr/seasons_and_forecasts/';
export const MIN_OGR_SEASON = 2017;
export const SHOW_CARTAGE_AND_MA_TESTS_EXCLUDED_SEASONS: ApplicableSeasonRange = { start: 2021 };
export const SHOW_KIWIGREEN_INCENTIVE_SEASONS: ApplicableSeasonRange = { start: 2022, end: 2023 };
export const SHOW_JAPAN_PREMIUM_INCENTIVE_SEASONS: ApplicableSeasonRange = { end: 2022 };
export const SHOW_JAPAN_AND_TAIWAN_PREMIUM_INCENTIVE_SEASONS: ApplicableSeasonRange = { start: 2023 };
export const SHOW_EXPLOSIVE_CHARGES_SEASONS: ApplicableSeasonRange = { start: 2023 };
export const SHOW_TGL_HAIL_TOP_UP_SEASONS: ApplicableSeasonRange = { start: 2023, end: 2024 };
export const SHOW_HAIL_COMPENSATION_SEASONS: ApplicableSeasonRange = { start: 2023, end: 2024 };

interface ApplicableSeasonRange {
  // Inclusive
  start?: number;
  // Exclusive
  end?: number;
}

interface DataField {
  key: string;
  label: string;
  cssClass?: string;
}

interface SeasonWithForecastsDropdownItem extends SeasonDropdownItem {
  forecasts: ForecastsDropdownItem[];
}

interface ForecastsDropdownItem {
  label: string;
  value: string;
  id: number;
}

export type CssLookup = Record<string, {
    label: string;
    cssClass: string;
  }>;

@Component({
  selector: 'app-ogr-details',
  templateUrl: './ogr-details.component.html',
  styleUrls: ['./ogr-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OgrDetailsComponent extends Details implements OnInit, OnDestroy, AfterViewInit {
  isLoading = false;
  selectedSeason: SeasonWithForecastsDropdownItem = null;
  availableSeasons: SeasonWithForecastsDropdownItem[] = [];
  availableForecasts: ForecastsDropdownItem[] = null;
  selectedForecast: ForecastsDropdownItem = null;
  rawData: any[] = [];
  dataCategoryTabs: DataCategoryTab[] = [
    { index: 0, title: 'Fruit Payments', cssClass: 'fruit-payments', id: 'fruit-payments-tab' },
    { index: 1, title: 'Service Payments', cssClass: 'service-payments', id: 'service-payments-tab' },
    { index: 2, title: 'Packing Costs', cssClass: 'packing-costs', id: 'packing-costs-tab' },
    { index: 3, title: 'Sundry Costs', cssClass: 'sundry-costs', id: 'sundry-costs-tab' },
    { index: 4, title: 'Net Time & Storage', cssClass: 'net-time-and-storage', id: 'net-time-and-storage-tab' },
    { index: 5, title: 'Sundry Payments', cssClass: 'sundry-payments', id: 'sundry-payments-tab' }
  ];
  selectedDataCategoryTab: DataCategoryTab = this.dataCategoryTabs[0];
  data: any[];
  backData: { season: number, forecast: number, data: any[] }[] = [];
  selectedGridRowIndexes = [];
  selectedItem: any = null;
  groupedLabels = this.getGroupedLabel(['KPIN', 'MA']);
  showCartageAndMaTestsExcludedSeasons = SHOW_CARTAGE_AND_MA_TESTS_EXCLUDED_SEASONS;
  showKiwigreenIncentiveSeasons = SHOW_KIWIGREEN_INCENTIVE_SEASONS;
  showJapanPremiumIncentiveSeasons = SHOW_JAPAN_PREMIUM_INCENTIVE_SEASONS;
  showJapanAndTaiwanPremiumIncentiveSeasons = SHOW_JAPAN_AND_TAIWAN_PREMIUM_INCENTIVE_SEASONS;
  showExplosiveChargesSeasons = SHOW_EXPLOSIVE_CHARGES_SEASONS;
  showTglHailTopUpSeasons = SHOW_TGL_HAIL_TOP_UP_SEASONS;
  showHailCompensationSeasons = SHOW_HAIL_COMPENSATION_SEASONS;
  gridColumns = [
    {
      tabGroup: 'main',
      label: this.groupedLabels,
      rowspan: 2,
      align: 'left',
      key: 'groupColumnValue',
      cssClass: 'fixed-narrow'
    },
    {
      tabGroup: 'main',
      label: 'Prod. Ha',
      rowspan: 2,
      key: 'producing_ha',
      precision: 2
    },
    {
      tabGroup: 'main',
      label: 'Trays',
      align: 'center',
      cssClass: 'two-line-height',
      childColumns: [
        { tabGroup: 'main',
          label: 'Total',
          key: 'submit_trays',
          precision: 0
        },
        { tabGroup: 'main',
          label: 'Per Ha',
          key: 'submit_trays_per_ha',
          precision: 0
        }
      ]
    },
    {
      tabGroup: 'main',
      label: 'Avg Size',
      rowspan: 2,
      key: 'average_size',
      precision: 1,
      allowEmpty: true
    },
    {
      tabGroup: 'main',
      label: 'Actual TZG',
      rowspan: 2,
      key: 'actual_tzg',
      precision: 2,
      allowEmpty: true
    },
    {
      tabGroup: 'main',
      label: 'OGR/Tray',
      align: 'center',
      cssClass: 'two-line-height',
      childColumns: [
        { tabGroup: 'main',
          label: '$',
          key: 'ogr_per_tray',
          precision: 4
        },
        { tabGroup: 'main',
          label: 'Rank',
          key: 'ogr_per_tray_rank',
          subValue: 'rank_string',
          precision: 0
        }
      ]
    },
    {
      tabGroup: 'main',
      label: 'OGR/Ha',
      align: 'center',
      cssClass: 'two-line-height',
      childColumns: [
        { tabGroup: 'main',
          label: '$',
          key: 'ogr_per_ha',
          precision: 0
        },
        { tabGroup: 'main',
          label: 'Rank',
          key: 'ogr_per_ha_rank',
          subValue: 'rank_string',
          precision: 0
        },
        {
          tabGroup: 'main',
          label: 'Δ',
          key: 'ogr_per_ha_rank_delta',
          precision: 0,
          isDelta: true,
          cssClass: 'delta',
          allowEmpty: true
        }
      ]
    },
    {
      tabGroup: 'fruit-payments',
      label: 'Submit & Progress',
      align: 'center',
      cssClass: 'divider two-line-height',
      childColumns: [
        {
          tabGroup: 'fruit-payments',
          label: 'Value',
          key: 'fruit_progress',
          precision: 4,
          cssClass: 'divider normal-narrow'
        },
        {
          tabGroup: 'fruit-payments',
          label: 'Rank',
          key: 'fruit_progress_rank',
          subValue: 'rank_string',
          precision: 0,
          cssClass: 'normal-narrow'
        }
      ],
      tooltipContent: 'Individual fruit values (includes G3 Organic Premium).'
    },
    {
      tabGroup: 'fruit-payments',
      label: 'Payment Pool',
      rowspan: 2,
      key: 'payment_pool',
      cssClass: 'normal-narrow',
      allowEmpty: true
    },
    {
      tabGroup: 'fruit-payments',
      label: 'KiwiStart',
      rowspan: 2,
      key: 'fruit_kiwistart',
      precision: 4,
      cssClass: 'normal-narrow',
      tooltipContent: 'Individual KiwiStart per qualifying tray.'
    },
    {
      tabGroup: 'fruit-payments', label: 'Taste Zespri', align: 'center', cssClass: 'two-line-height', childColumns: [
        {
          tabGroup: 'fruit-payments',
          label: 'Value',
          key: 'fruit_taste_zespri',
          precision: 4,
          cssClass: 'normal-narrow'
        },
        {
          tabGroup: 'fruit-payments',
          label: 'Rank',
          key: 'fruit_taste_zespri_rank',
          subValue: 'rank_string',
          precision: 0,
          cssClass: 'normal-narrow'
        }
      ],
      tooltipContent: 'Individual Taste Zespri premium based on TZG.'
    },
    {
      tabGroup: 'service-payments',
      label: 'Packtype Differential',
      rowspan: 2,
      key: 'service_packtype_differential',
      precision: 4,
      cssClass: 'divider normal',
      tooltipContent: 'This is a Zespri payment for packing fruit into any packtype other than a modular bulk pack.'
    },
    {
      tabGroup: 'service-payments',
      label: 'Time Service Payment',
      rowspan: 2,
      key: 'service_time',
      precision: 4,
      cssClass: 'normal',
      tooltipContent: 'Income received for incremental coolstorage, coolstorage for fruit loss, repacking and condition checking.'
    },
    {
      tabGroup: 'service-payments',
      label: 'Storage Incentives',
      align: 'center',
      cssClass: 'two-line-height',
      childColumns: [
        {
          tabGroup: 'service-payments',
          label: 'Value',
          key: 'service_storage_incentives',
          precision: 4,
          cssClass: 'normal'
        },
        {
          tabGroup: 'service-payments',
          label: 'Rank',
          key: 'service_storage_incentives_rank',
          subValue: 'rank_string',
          precision: 0,
          cssClass: 'normal'
        }
      ],
      tooltipContent: 'Storage incentives earned. Pool splits apply to this income centre.'
    },
    {
      tabGroup: 'service-payments',
      label: 'Supplier Accountability',
      rowspan: 2,
      key: 'service_supplier_accountability',
      precision: 4,
      cssClass: 'normal',
      tooltipContent: 'Estimated total of premiums and penalties earned for quality of fruit delivered to the market.'
    },
    {
      tabGroup: 'packing-costs',
      label: 'Base Pack',
      rowspan: 2,
      key: 'post_harvest_base_pack_no_reject_surcharge',
      precision: 4,
      cssClass: 'divider normal',
      tooltipContent: 'Charge for packing a tray.'
    },
    {
      tabGroup: 'packing-costs',
      label: 'Explosive Charges',
      showAfter: this.showExplosiveChargesSeasons.start,
      rowspan: 2,
      key: 'post_harvest_explosive_charges',
      precision: 4,
      cssClass: 'normal',
      tooltipContent: 'Charge for explosive fruit at bin tip.'
    },
    {
      tabGroup: 'packing-costs',
      label: 'Reject Surcharge',
      rowspan: 2,
      key: 'post_harvest_reject_surcharge',
      precision: 4,
      cssClass: 'normal',
      tooltipContent: 'Charge for % of reject fruit.'
    },
    {
      tabGroup: 'packing-costs',
      label: 'Pack Diff.',
      rowspan: 2,
      key: 'post_harvest_pack_differential',
      precision: 4,
      cssClass: 'normal',
      tooltipContent: 'The cost of packing into any packtype other than a modular bulk pack.'
    },
    {
      tabGroup: 'packing-costs',
      label: 'Base Cool Store',
      rowspan: 2,
      key: 'post_harvest_base_cool_storage',
      precision: 4,
      cssClass: 'normal',
      tooltipContent: 'Base coolstorage - only charged on loaded out trays (FOBS).'
    },
    {
      tabGroup: 'sundry-costs',
      label: 'Admin',
      rowspan: 2,
      key: 'post_harvest_administration',
      precision: 4,
      cssClass: 'divider normal',
      tooltipContent: 'The cost of administering the TGL pools.'
    },
    {
      tabGroup: 'sundry-costs',
      label: 'TGL Hail Top Up Insurance',
      showAfter: this.showTglHailTopUpSeasons.start,
      removeAfter: this.showTglHailTopUpSeasons.end,
      rowspan: 2,
      key: 'post_harvest_hail_top_up_insurance',
      precision: 4,
      cssClass: 'normal',
      tooltipContent: 'The hail top up insurance offered by TGL.'
    },
    {
      tabGroup: 'sundry-costs',
      label: 'Logistics',
      rowspan: 2,
      key: 'post_harvest_logistics',
      precision: 4,
      cssClass: 'normal',
      tooltipContent: 'The cost of getting a packed tray from coolstore door to being loaded on the ship (FOBS), and other sundry expenses.'
    },
    {
      tabGroup: 'sundry-costs',
      label: 'Excluded From OGR',
      showAfter: this.showCartageAndMaTestsExcludedSeasons.start,
      key: 'exclude_from_ogr',
      align: 'center',
      cssClass: 'two-line-height alert',
      childColumns: [
        {
          tabGroup: 'sundry-costs',
          label: 'Cartage',
          rowspan: 2,
          key: 'post_harvest_cartage',
          precision: 4,
          cssClass: 'normal excluded',
          tooltipContent: 'Cost of carting your bins from your orchard to the packhouse.'
        },
        {
          tabGroup: 'sundry-costs',
          label: 'Maturity Tests',
          rowspan: 2,
          key: 'post_harvest_maturity_tests',
          precision: 4,
          cssClass: 'normal excluded',
          tooltipContent: 'Cartage and Maturity Tests are excluded from OGR.'
        }
      ]
    },
    {
      tabGroup: 'sundry-costs',
      label: 'Cartage',
      removeAfter: this.showCartageAndMaTestsExcludedSeasons.start,
      rowspan: 2,
      key: 'post_harvest_cartage',
      precision: 4,
      cssClass: 'normal',
      tooltipContent: 'Cost of carting your bins from your orchard to the packhouse.'
    },
    {
      tabGroup: 'sundry-costs',
      label: 'Maturity Tests',
      removeAfter: this.showCartageAndMaTestsExcludedSeasons.start,
      rowspan: 2,
      key: 'post_harvest_maturity_tests',
      precision: 4,
      cssClass: 'narrow',
      tooltipContent: 'Charges for maturity clearance testing prior to harvest.'
    },
    {
      tabGroup: 'net-time-and-storage',
      label: 'Time Service Payment',
      rowspan: 2,
      key: 'service_time',
      precision: 4,
      cssClass: 'divider normal',
      tooltipContent: 'Income received for incremental coolstorage, coolstorage for fruit loss, repacking and condition checking.'
    },
    {
      tabGroup: 'net-time-and-storage',
      label: 'Storage Incentives',
      rowspan: 2,
      key: 'service_storage_incentives',
      precision: 4,
      cssClass: 'normal',
      tooltipContent: 'Storage incentives earned, pool splits apply to this income centre.'
    },
    {
      tabGroup: 'net-time-and-storage',
      label: 'Onshore Fruit Loss',
      rowspan: 2,
      key: 'storage_onshore_fruit_loss',
      precision: 4,
      cssClass: 'normal',
      tooltipContent: 'Fruit loss incurred after submitting to Zespri.'
    },
    {
      tabGroup: 'net-time-and-storage',
      label: 'Time & CC/RK Charges',
      rowspan: 2,
      key: 'storage_time_and_ccrk_charges',
      precision: 4,
      cssClass: 'normal',
      tooltipContent: 'Charges for incremental coolstorage, repacking and condition checking and repalletisation.'
    },
    {
      tabGroup: 'net-time-and-storage',
      label: 'Net Time & Storage',
      rowspan: 2,
      key: 'storage_net',
      precision: 4,
      cssClass: 'normal net-time-and-storage',
      tooltipContent: 'Sum of Time Service Payments, Storage Incentives, Onshore Fruit Loss, and Time & CC/RK Charges.'
    },
    {
      tabGroup: 'sundry-payments',
      label: 'Loyalty Premium',
      rowspan: 2,
      key: 'sundry_loyalty_premium',
      precision: 4,
      cssClass: 'divider normal-narrow',
      tooltipContent: 'Premium paid on loaded out trays to all growers with a signed loyalty contract.'
    },
    {
      tabGroup: 'sundry-payments',
      label: 'Class 2 Return',
      rowspan: 2,
      key: 'sundry_class_2_return',
      precision: 4,
      cssClass: 'normal-narrow',
      tooltipContent: 'Returns for sale of class 2 fruit by Zespri, NutriKiwi, The Golden Kiwi Company and local market.'
    },
    {
      tabGroup: 'sundry-payments',
      label: 'NSS',
      rowspan: 2,
      key: 'sundry_nss',
      precision: 4,
      cssClass: 'normal-narrow',
      tooltipContent: 'Non-standard supply returns.'
    },
    {
      tabGroup: 'sundry-payments',
      label: 'Japan Premium',
      removeAfter: this.showJapanPremiumIncentiveSeasons.end,
      rowspan: 2,
      key: 'sundry_japan_premium',
      precision: 4,
      cssClass: 'normal-narrow',
      tooltipContent: 'Premium for Japan supply paid to the G3 Organic pool.'
    },
    {
      tabGroup: 'sundry-payments',
      label: 'Japan & Taiwan Premium',
      showAfter: this.showJapanAndTaiwanPremiumIncentiveSeasons.start,
      rowspan: 2,
      key: 'sundry_japan_taiwan_premium',
      precision: 4,
      cssClass: 'normal-narrow',
      tooltipContent: 'Premium for Japan & Taiwan supply paid to the Organic pools.'
    },
    {
      tabGroup: 'sundry-payments',
      label: 'KiwiGreen Incentive',
      showAfter: this.showKiwigreenIncentiveSeasons.start,
      removeAfter: this.showKiwigreenIncentiveSeasons.end,
      rowspan: 2,
      key: 'sundry_kiwigreen_incentive',
      precision: 4,
      cssClass: 'normal-narrow',
      tooltipContent: 'Zespri incentive to support good Pest Management practices on orchard.'
    },
    {
      tabGroup: 'sundry-payments',
      label: 'Hail Compensation',
      showAfter: this.showHailCompensationSeasons.start,
      removeAfter: this.showHailCompensationSeasons.end,
      rowspan: 2,
      key: 'sundry_hail_compensation',
      precision: 4,
      cssClass: 'normal-narrow',
      tooltipContent: 'Compensation for accepted hail damaged fruit claims.'
    },
    {
      tabGroup: 'sundry-payments',
      label: 'Other Income',
      rowspan: 2,
      key: 'sundry_other_income',
      precision: 4,
      cssClass: 'normal-narrow',
      tooltipContent: 'Class 3 returns, used packaging, and DIFOTIS.'
    }
  ];
  private originalGridColumns = Utils.clone(this.gridColumns);
  hiddenColumns: (number|string)[] = [];
  pieChartSize = 270;
  incomeComponentsLabelCssClassLookup: any;
  incomeComponentsChartData: { current: any, previous?: any } = null;
  ogrPerTrayChartGroups: any[] = [];
  ogrPerTrayChartData: any[] = [];
  ogrPerHaChartGroups: any[] = [];
  ogrPerHaChartData: any[] = [];
  selectedIndexChartGroup: SelectedGroup = { primary: null, secondary: null };
  selectedIndexChartBarIndex: any = null;

  fruitPaymentsChartData: any[] = [];
  fruitPaymentsChartGroups: any[] = [];

  servicePaymentsChartData: any[] = [];
  servicePaymentsChartGroups: any[] = [];

  packingCostsChartData: any[] = [];
  packingCostsChartGroups: any[] = [];

  sundryCostsChartData: any[] = [];
  sundryCostsChartGroups: any[] = [];

  netTimeAndStorageChartData: any[] = [];
  netTimeAndStorageChartGroups: any[] = [];

  sundryPaymentsChartData: any[] = [];
  sundryPaymentsChartGroups: any[] = [];

  packingCostsSidebarLabelCssClassLookup: any;
  sundryCostsSidebarLabelCssClassLookup: any;
  packingCostsSidebarChartData: { current: any, previous?: any } = null;
  sundryCostsSidebarChartData: { current: any, previous?: any } = null;

  fruitPaymentFields: DataField[];
  servicePaymentsFields: DataField[];
  packingCostsFields: DataField[];
  sundryCostsFields: DataField[];
  netTimeAndStorageFields: DataField[];
  sundryPaymentsFields: DataField[];

  mainLoadingMessage = 'Loading OGR data...';

  isOgrReportButtonFlashing = false;

  private selectedLevel: string;
  private selectedId: number;
  private queryParamsSubscription: Subscription;
  @HostBinding('class.no-data') hasNoData = true;

  constructor(
    private http: HttpService,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private tutorialService: TutorialService,
    protected ga: GaService
  ) {
    super(ga);
  }

  async ngOnInit() {
    this.isLoading = true;
    this.availableSeasons = await this.getSeasonsAndForecasts();

    if (this.availableSeasons?.length) {
      this.selectedSeason = this.availableSeasons[0];
      this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(params => {
        if (params.season) {
          const selectedSeason = Utils.findItem(this.availableSeasons, 'value', parseInt(params.season, 10));
          if (selectedSeason) {
            this.selectedSeason = selectedSeason;
          }
        }
      });

      this.updateAvailableForecasts();
      this.selectLatestForecast();
      this.init();
    } else {
      this.isLoading = false;
      this.hasNoData = true;
      this.data = [];
      this.changeDetectorRef.markForCheck();
    }
  }

  ngOnDestroy() {
    this.queryParamsSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    this.tutorialService.run('ogr-details', getOgrDetailsSteps);
  }

  onTabSelect(event) {
    this.ga.event('tab', 'change', 'ogr details variety gm tab', event.title);
    this.selectedTab = this.tabs[event.index];
    this.updateData();
    this.updateSelectedGridRowIndexes();
  }

  selectSeason(season: SeasonWithForecastsDropdownItem) {
    if (this.selectedSeason?.label !== season.label) {
      this.ga.event('dropdown', 'change', 'ogr details season change', season.label);
      this.selectedSeason = season;
      this.updateAvailableForecasts();
      this.selectLatestForecast();
      this.init();
    }
  }

  selectForecast(forecast: ForecastsDropdownItem) {
    if (this.selectedForecast.label !== forecast.label) {
      this.ga.event('dropdown', 'change', 'ogr details forecast change', forecast.label);
      this.selectedForecast = forecast;
      this.init();
    }
  }

  switchIndexChartGroupCallback(primary: string, secondary: string, secondarySortOrder: string = null) {
    this.selectedIndexChartGroup = { primary: primary, secondary: secondary, secondarySortOrder: secondarySortOrder };
  }

  indexChartMouseOverCallback(data) {
    this.selectedIndexChartBarIndex = data.index;
  }

  indexChartMouseOutCallback() {
    this.selectedIndexChartBarIndex = null;
  }

  openOgrReport() {
    if (this.selectedItem?.ogr_forecast_file_id) {
      const url = `${ OGR_REPORT_FILE_ENDPOINT }/${ this.selectedItem.ogr_forecast_file_id }/`;
      this.ga.event('button', 'click', 'ogr details ogr report download', url);
      Utils.openGrowerReport(url);
    }
  }

  private async getSeasonsAndForecasts(): Promise<SeasonWithForecastsDropdownItem[]> {
    const data = await this.fetchSeasonsAndForecasts();
    const seasonData = data.reduce((result, item) => {
      const forecastItem: ForecastsDropdownItem = { label: item.forecast, value: item.forecast, id: item.forecast_id };
      if (result[item.season]) {
        result[item.season].forecasts.push(forecastItem);
      } else {
        result[item.season] = { forecasts: [forecastItem] };
      }
      return result;
    }, {});

    const orderedSeasons = Object.keys(seasonData).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
    return orderedSeasons.map((season: string) => {
      const seasonInt = parseInt(season, 10);
      return { label: seasonInt, value: seasonInt, forecasts: seasonData[season].forecasts };
    });
  }

  private async init() {
    this.showLoading();
    this.updateGridColumns();
    this.initAdditionalFields();
    this.initPackingCostsSidebarLabelCssClassLookup();
    this.initSundryCostsSidebarLabelCssClassLookup();
    this.initIncomeComponentsLabelCssClassLookup();
    this.rawData = await this.getData();
    this.changeDetectorRef.markForCheck();

    this.tabs = this.getTabs(this.rawData[0]);
    if (this.tabs && this.tabs.length) {
      this.sortTabs();
      this.initSelectedTab();
      this.clearBackData();
      this.updateData();
      this.updateSelectedGridRowIndexes();
      this.showColumns(this.selectedDataCategoryTab.title);
      this.setColumnWidthClass(this.selectedDataCategoryTab);
    } else {
      this.hasNoData = true;
      this.data = [];
    }

    // The delay ensures the charts update before the page is revealed smoothing out the transition
    this.hideLoading(100);
  }

  private initAdditionalFields() {
    this.fruitPaymentFields = this.getDataFieldsFromGridColumns('fruit-payments', this.gridColumns, ['payment_pool']);
    this.servicePaymentsFields = this.getDataFieldsFromGridColumns('service-payments', this.gridColumns);
    this.packingCostsFields = this.getDataFieldsFromGridColumns('packing-costs', this.gridColumns, ['exclude_from_ogr']);
    this.sundryCostsFields = this.getDataFieldsFromGridColumns('sundry-costs', this.gridColumns, ['exclude_from_ogr']);
    this.netTimeAndStorageFields = this.getDataFieldsFromGridColumns('net-time-and-storage', this.gridColumns, ['storage_net']);
    this.sundryPaymentsFields = this.getDataFieldsFromGridColumns('sundry-payments', this.gridColumns);
  }

  private initPackingCostsSidebarLabelCssClassLookup() {
    this.packingCostsSidebarLabelCssClassLookup = this.getLabelCssClassLookupFromGridColumns('packing-costs', this.gridColumns, ['exclude_from_ogr']);
  }

  private initSundryCostsSidebarLabelCssClassLookup() {
    this.sundryCostsSidebarLabelCssClassLookup = this.getLabelCssClassLookupFromGridColumns('sundry-costs', this.gridColumns, ['exclude_from_ogr']);
  }

  private initIncomeComponentsLabelCssClassLookup() {
    this.incomeComponentsLabelCssClassLookup = {
      fruit_progress: { label: 'Submit & Progress', cssClass: 'submit-progress' },
      fruit_kiwistart: { label: 'KiwiStart', cssClass: 'kiwistart' },
      fruit_taste_zespri: { label: 'Taste Zespri', cssClass: 'taste-zespri' },
      service_supplier_accountability: { label: 'Supplier Accountability', cssClass: 'supplier-accountability' },
      service_time: { label: 'Time Service Payments', cssClass: 'time-service-payments' },
      service_storage_incentives: { label: 'Storage Incentives', cssClass: 'storage-incentives' },
      sundry_loyalty_premium: { label: 'Loyalty Premium', cssClass: 'loyalty-premium' },
      service_packtype_differential: { label: 'Packtype Differential', cssClass: 'packtype-differential' },
      sundry_class_2_return: { label: 'Class 2 Return', cssClass: 'class-2-return' },
      sundry_nss: { label: 'NSS', cssClass: 'sundry-nss' },
      sundry_hail_compensation: { label: 'Hail Compensation', cssClass: 'sundry-hail-compensation' },
      sundry_other_income: { label: 'Sundry Other Income', cssClass: 'sundry-other-income' }
    };

    if (this.selectedSeason.value < this.showJapanPremiumIncentiveSeasons.end) {
      this.incomeComponentsLabelCssClassLookup['sundry_japan_premium'] = {
        label: 'Sundry Japan Premium',
        cssClass: 'sundry-japan-premium'
      };
    }
    if (this.selectedSeason.value >= this.showKiwigreenIncentiveSeasons.start && this.selectedSeason.value < this.showKiwigreenIncentiveSeasons.end) {
      this.incomeComponentsLabelCssClassLookup['sundry_kiwigreen_incentive'] = {
        label: 'Sundry KiwiGreen Incentive',
        cssClass: 'sundry-kiwigreen-incentive'
      };
    }
  }

  private showLoading() {
    this.isLoading = true;
    this.changeDetectorRef.markForCheck();
  }

  private hideLoading(delay = 100) {
    setTimeout(() => {
      this.isLoading = false;
      this.changeDetectorRef.markForCheck();
    }, delay);
  }

  private clearBackData() {
    this.backData = [];
  }

  private updateData() {
    this.data = this.getSeasonData(this.rawData[0]) || [];
    this.clearBackData();
    this.hasNoData = !this.data.length;
    if (this.data.length) {
      this.updateDataBackSeasonData(1);
      this.updateDataBackSeasonData(2);
    }
    this.updateRankDelta('ogr_per_ha_rank', 'ogr_per_ha_rank_delta');
    this.setAverageSizeAndKiwiStart();
    this.copyOgrForecastFileIdToMaturityAreas();
  }

  private setAverageSizeAndKiwiStart() {
    this.data.forEach((item) => {
      item.average_size = this.calculateAverageSize(item);
      if (item.payment_pool === 'Main') {
        item.fruit_kiwistart = null;
      }
    });
  }

  private copyOgrForecastFileIdToMaturityAreas() {
    this.data.forEach((item) => {
      if (item.children) {
        item.children.forEach((child) => {
          child.ogr_forecast_file_id = item.ogr_forecast_file_id;
        });
      }
    });
  }

  private calculateAverageSize(data) {
    const sizes = [16, 18, 22, 25, 27, 30, 33, 36, 39, 42, 46];
    let totalTrays = 0;
    let totalSizeTimesTrays = 0;
    sizes.forEach((size) => {
      if (Utils.isSizeClass1(data.variety, size, data.season)) {
        const sizeTrays = data['trays_class_1_size_' + size] || 0;
        totalSizeTimesTrays += size * sizeTrays;
        totalTrays += sizeTrays;
      }
    });
    return totalSizeTimesTrays / totalTrays;
  }

  private updateDataBackSeasonData(seasonsBack: number) {
    const backSeasonData = this.getSeasonData(this.rawData[seasonsBack]);
    if (backSeasonData) {
      this.saveBackDataItem(backSeasonData, this.selectedSeason.value - seasonsBack, null);
    }
  }

  private updateRankDelta(sourceKey: string, targetKey: string) {
    const backData = this.findBackData(this.selectedSeason.value - 1);
    if (backData) {
      this.data.forEach((item) => {
        const backDataItem = this.findDataItem(backData.data, item.grower_number, item.block_names, item.level);
        const itemRank = item[sourceKey];

        if (backDataItem && itemRank != null) {
          const backDataRank = backDataItem[sourceKey];
          if (backDataRank != null) {
            item[targetKey] = backDataRank - itemRank;
          }
        }
      });
    }
  }

  private getSeasonData(rawData) {
    let data = Utils.clone(rawData);
    data = this.filterDataAndChildren(data, this.selectedTab.varietyId, this.selectedTab.growMethodId);
    if (data && data.length) {
      data = this.flattenChildren(data);
      this.setRankString(data);
      return data;
    }
  }

  private getTabs(data: any[]): Tab[] {
    const tabData = [];
    (data || []).forEach((orchardData) => {
      (orchardData.children || []).forEach((maturityAreaData) => {
        tabData.push({
          title: maturityAreaData.variety + maturityAreaData.grow_method,
          varietyId: maturityAreaData.variety_id,
          growMethodId: maturityAreaData.grow_method_id
        });
      });
    });
    return Utils.uniqueObjectArrayByKey(tabData, 'title');
  }

  protected findSelectedRow() {
    for (const item of this.rawData[0]) {
      if (
        (this.selectedLevel === 'orchard' && item.orchard_id === this.selectedId) ||
        (this.selectedLevel === 'ma' && item.maturity_area_id === this.selectedId)
      ) {
        return item;
      }
    }
  }

  private setRankString(flatData) {
    flatData.forEach((item) => {
      item.rank_string = item.number_of_results_in_group ? 'of ' + item.number_of_results_in_group : null;
    });
  }

  private flattenChildren(data: any[], parent = null, flatData = [], level = 0): any[] {
    data.forEach((item) => {
      item.level = level;
      item.parent = parent;
      item.isHidden = level > 0;
      item.groupColumnValue = this.getGroupColumnValue(item, level);
      item.storage_net = this.computeNetTimeAndStorage(item);

      if (!item.grower_number && parent) {
        item.grower_number = parent.grower_number;
      }

      flatData.push(item);
      if (item.children) {
        this.flattenChildren(item.children, item, flatData, level + 1);
      }
    });
    return flatData;
  }

  private computeNetTimeAndStorage(data): number {
    const netTimeAndStorageKeys = ['service_time', 'service_storage_incentives', 'storage_onshore_fruit_loss', 'storage_time_and_ccrk_charges'];
    return netTimeAndStorageKeys.reduce((result, key) => {
      result += data[key] || 0;
      return result;
    }, 0);
  }

  private getGroupColumnValue(row, level) {
    switch (level) {
      case 0: return row.grower_number;
      case 1: return row.maturity_area;
      default: return null;
    }
  }

  private filterDataAndChildren(data: any[], varietyId: number, growMethodId: number): any[] {
    return (data || []).filter((item) => {
      item.children = this.filterData(item.children, varietyId, growMethodId);
      return item.children.length > 0;
    });
  }

  private updateSelectedGridRowIndexes() {
    let selectedRowIndex = this.findSelectedRowIndex(this.data);
    if (selectedRowIndex == null) {
      selectedRowIndex = this.getFirstMaturityAreaGridRowIndex();
    }

    if (selectedRowIndex != null) {
      this.selectedGridRowIndexes = [selectedRowIndex];
    }
  }

  private updateAvailableForecasts() {
    this.availableForecasts = this.selectedSeason.forecasts;
  }

  private selectLatestForecast() {
    this.selectedForecast = this.availableForecasts[0];
  }

  protected selectGridRow(row) {
    if (this.selectedItem !== row) {
      if (this.selectedItem) {
        // Do not flash on initial load (too much change in UI)
        this.flashOgrReportButton();
      }
      this.selectedItem = row;
      this.setOgrChartData(row, 'ogrPerTrayChartData', 'ogrPerTrayChartGroups', 'ogr_per_tray');
      this.setOgrChartData(row, 'ogrPerHaChartData', 'ogrPerHaChartGroups', 'ogr_per_ha');

      this.setPieChartData(row, 'incomeComponentsChartData', this.incomeComponentsLabelCssClassLookup);
      this.setPieChartData(row, 'packingCostsSidebarChartData', this.packingCostsSidebarLabelCssClassLookup);
      this.setPieChartData(row, 'sundryCostsSidebarChartData', this.sundryCostsSidebarLabelCssClassLookup);

      this.setHistoryChartData(row, 'fruitPaymentsChartData', 'fruitPaymentsChartGroups', this.fruitPaymentFields);
      this.setHistoryChartData(row, 'servicePaymentsChartData', 'servicePaymentsChartGroups', this.servicePaymentsFields);
      this.setHistoryChartData(row, 'packingCostsChartData', 'packingCostsChartGroups', this.packingCostsFields);
      this.setHistoryChartData(row, 'sundryCostsChartData', 'sundryCostsChartGroups', this.sundryCostsFields);
      this.setHistoryChartData(row, 'netTimeAndStorageChartData', 'netTimeAndStorageChartGroups', this.netTimeAndStorageFields);
      this.setHistoryChartData(row, 'sundryPaymentsChartData', 'sundryPaymentsChartGroups', this.sundryPaymentsFields);

      this.changeDetectorRef.markForCheck();
    }
  }

  private setPieChartData(data, key: string, labelCssClassLookup) {
    const currentData = this.getPieChartData(data, labelCssClassLookup);
    let previousData: any;
    const backDataItem = this.findBackData(this.selectedSeason.value - 1);

    if (backDataItem) {
      const previousSeasonData = this.findDataItem(backDataItem.data, data.grower_number, data.block_names, data.level);
      if (previousSeasonData) {
        previousData = this.getPreviousSeasonPieChartData(previousSeasonData, currentData, labelCssClassLookup);
      }
    }

    this[key] = {
      current: { label: this.selectedSeason.value, data: currentData },
      previous: { label: this.selectedSeason.value - 1, data: previousData }
    };
  }

  private getPieChartData(data, labelCssClassLookup) {
    const keys = Object.keys(labelCssClassLookup);
    const totalCurrentSeason = keys.reduce((aggregate, key) => {
      return aggregate + Math.abs(data[key] || 0);
    }, 0);

    const chartData = keys.map((key) => {
      const value = Math.abs(data[key] || 0);
      const keyData = labelCssClassLookup[key];
      return {
        cssClass: keyData.cssClass,
        label: keyData.label,
        value: 100 * value / totalCurrentSeason,
        key: key
      };
    });

    this.sortByDescValue(chartData);
    return chartData;
  }

  // This method ensures the previous season chart data has the same order as the current season chart data
  private getPreviousSeasonPieChartData(previousSeasonData, currentSeasonChartData, labelCssClassLookup) {
    const keys = Object.keys(labelCssClassLookup);
    const totalCurrentSeason = keys.reduce((aggregate, key) => {
      return aggregate + Math.abs(previousSeasonData[key] || 0);
    }, 0);
    const chartData = [];

    currentSeasonChartData.forEach((currentSeasonChartItem) => {
      const value = Math.abs(previousSeasonData[currentSeasonChartItem.key] || 0);
      chartData.push({
        cssClass: currentSeasonChartItem.cssClass,
        label: currentSeasonChartItem.label,
        value: 100 * value / totalCurrentSeason
      });
    });

    return chartData;
  }

  private setOgrChartData(data, chartDataKey: string, chartGroupKey: string, valueKey: string) {
    let groupLabel;
    if (data.level === Level.Orchard) {
      this[chartDataKey] = this.getOgrChartDataForLevel('KPIN', valueKey);
      groupLabel = 'KPIN';
    } else if (data.level === Level.Ma) {
      this[chartDataKey] = this.getOgrChartDataForLevel('MA', valueKey, data.grower_number);
      groupLabel = 'MA';
    }

    this[chartGroupKey] = [
      { label: groupLabel, primary: 'group', secondary: 'season', secondarySortOrder: 'desc' },
      { label: 'Season', primary: 'season', secondary: 'group', isSelected: true, sortOrder: 'desc' }
    ];
  }

  private getOgrChartDataForLevel(levelName, valueKey: string, growerNumber: number = null) {
    let data = [];
    this.data.forEach((item) => {
      if (levelName === 'KPIN' && item.level === Level.Orchard) {
        data = data.concat(this.getOgrChartDataWithHistory(item, 'grower_number', valueKey));
      } else if (levelName === 'MA' && item.level === Level.Ma && item.grower_number === growerNumber) {
        data = data.concat(this.getOgrChartDataWithHistory(item, 'maturity_area', valueKey));
      }
    });
    return data;
  }

  private getOgrChartDataWithHistory(item, groupKey: string, valueKey: string): any[] {
    const season = this.selectedSeason.value;
    const data = [this.getOgrChartData(item, groupKey, valueKey, season)];
    this.pushOgrChartDataFromHistoryItem(data, season - 1, item.grower_number, item.block_names, item.level, groupKey, valueKey);
    this.pushOgrChartDataFromHistoryItem(data, season - 2, item.grower_number, item.block_names, item.level, groupKey, valueKey);
    return data;
  }

  private pushOgrChartDataFromHistoryItem(target: any[], season: number, growerNumber: number, blockNames: string, level: number, groupKey: string, valueKey: string) {
    const backDataItem = this.findBackData(season);
    if (backDataItem) {
      const item = this.findDataItem(backDataItem.data, growerNumber, blockNames, level);
      if (item) {
        target.push(this.getOgrChartData(item, groupKey, valueKey, season));
      }
    }
  }

  private getOgrChartData(item, groupKey: string, valueKey: string, season: number) {
    return {
      season: season,
      group: item[groupKey],
      value: item[valueKey],
      groupLinks: { season: item['block_names'] }
    };
  }

  private findDataItem(data: any[], growerNumber: number, blockNames: string, level: number) {
    for (const item of data || []) {
      if (item.grower_number === growerNumber && item.level === level && (level === Level.Orchard || (item.block_names === blockNames && level === Level.Ma))) {
        return item;
      }
    }
  }

  private findBackData(season: number, forecast: number = null) {
    for (const item of this.backData || []) {
      if (item.season === season && (forecast === null || item.forecast === forecast)) {
        return item;
      }
    }
  }

  private saveBackDataItem(data: any[], season: number, forecast: number = null) {
    this.backData.push({ season: season, forecast: forecast, data: data });
  }

  private setHistoryChartData(data, chartDataKey: string, chartGroupKey: string, fields: DataField[]) {
    let groupLabel;
    if (data.level === Level.Orchard) {
      this[chartDataKey] = this.getHistoryChartDataForLevel('KPIN', fields);
      groupLabel = 'KPIN';
    } else if (data.level === Level.Ma) {
      this[chartDataKey] = this.getHistoryChartDataForLevel('MA', fields, data.grower_number);
      groupLabel = 'MA';
    }

    this[chartGroupKey] = [
      { label: groupLabel, primary: 'group', secondary: 'type', isSelected: true }
    ];
  }

  private getHistoryChartDataForLevel(levelName: string, fields: DataField[], grower_number: number = null) {
    let data = [];
    let groupIdentifiers;

    [{ season: this.selectedSeason.value, data: this.data }].concat(this.backData).forEach((seasonAndData) => {
      seasonAndData.data.forEach((item) => {
        if (levelName === 'KPIN' && item.level === Level.Orchard) {
          groupIdentifiers = { grower_number: item.grower_number };
          data = data.concat(this.getHistoryChartData(seasonAndData.season, item, 'grower_number', fields, groupIdentifiers));
        } else if (levelName === 'MA' && item.level === Level.Ma && item.grower_number === grower_number) {
          groupIdentifiers = { grower_number: item.grower_number, maturity_area: item.maturity_area };
          data = data.concat(this.getHistoryChartData(seasonAndData.season, item, 'maturity_area', fields, groupIdentifiers));
        }
      });
    });
    return data;
  }

  private getHistoryChartData(season: number, item, groupKey: string, fields: DataField[], groupIdentifiers) {
    const data = [];
    fields.forEach((field) => {
      data.push({
        season: season,
        group: item[groupKey],
        type: field.label,
        cssClass: field.cssClass,
        value: item[field.key],
        groupIdentifiers: groupIdentifiers
      });
    });
    return data;
  }

  private sortByDescValue(data: any[]) {
    data.sort((a, b) => {
      return b.value - a.value;
    });
  }

  protected showColumns(category: string) {
    switch (category) {
      case 'Fruit Payments': return this.showFruitPaymentsColumns();
      case 'Service Payments': return this.showServicePaymentsColumns();
      case 'Packing Costs': return this.showPackingCostsColumns();
      case 'Sundry Costs': return this.showSundryCostsColumns();
      case 'Net Time & Storage': return this.showNetTimeAndStorageColumns();
      case 'Sundry Payments': return this.showSundryPaymentsColumns();
    }
  }

  private showFruitPaymentsColumns() {
    this.hiddenColumns = ['service-payments', 'packing-costs', 'sundry-costs', 'net-time-and-storage', 'sundry-payments'];
  }

  private showServicePaymentsColumns() {
    this.hiddenColumns = ['fruit-payments', 'packing-costs', 'sundry-costs', 'net-time-and-storage', 'sundry-payments'];
  }

  private showPackingCostsColumns() {
    this.hiddenColumns = ['fruit-payments', 'service-payments', 'sundry-costs', 'net-time-and-storage', 'sundry-payments'];
  }

  private showSundryCostsColumns() {
    this.hiddenColumns = ['fruit-payments', 'service-payments', 'packing-costs', 'net-time-and-storage', 'sundry-payments'];
  }

  private showNetTimeAndStorageColumns() {
    this.hiddenColumns = ['fruit-payments', 'service-payments', 'packing-costs', 'sundry-costs', 'sundry-payments'];
  }

  private showSundryPaymentsColumns() {
    this.hiddenColumns = ['fruit-payments', 'service-payments', 'packing-costs', 'sundry-costs', 'net-time-and-storage'];
  }

  protected setColumnWidthClass(tab: DataCategoryTab) {
    if (['packing-costs', 'sundry-costs', 'sundry-payments'].includes(tab.cssClass)) {
      this.getNumberOfVisibleColumns(tab.cssClass);
    }
  }

  private getNumberOfVisibleColumns(tabGroup: string) {
    const visibleGridColumns = this.gridColumns.reduce((result, column) => {
      if (column.tabGroup === tabGroup) {
        if (column.childColumns?.length) {
          result.push(...column.childColumns);
        } else {
          result.push(column);
        }
      }
      return result;
    }, []);
    const cssClass = this.getCssColumnWidthClass(visibleGridColumns.length);
    visibleGridColumns.forEach((column) => {
      if (column.cssClass) {
        column.cssClass = column.cssClass.replace(/normal-narrow|narrow|normal|wide/g, cssClass);
      }
    });
    this.gridColumns = [...this.gridColumns];
  }

  private getCssColumnWidthClass(numberOfColumns: number): string {
    switch (numberOfColumns) {
      case 4: return 'wide';
      case 5: return 'normal';
      case 6: return 'normal-narrow';
      default: return 'narrow';
    }
  }

  private findSelectedRowIndex(data: any[]) {
    for (let i = 0; i < this.data.length; i++) {
      const item = data[i];
      if (this.selectedLevel === 'orchard' && item.level === Level.Orchard && item.orchard_id === this.selectedId) {
        return i;
      } else if (this.selectedLevel === 'ma' && item.level === Level.Ma && item.maturity_area_id === this.selectedId) {
        return i;
      }
    }
  }

  private getFirstMaturityAreaGridRowIndex() {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].level === Level.Ma) {
        return i;
      }
    }
  }

  private async getData() {
    const selectedSeason = this.selectedSeason.value;
    const data = [];
    const currentSeasonData = await this.fetchData(selectedSeason, this.selectedForecast.id);

    if (currentSeasonData && currentSeasonData.length) {
      data.push(currentSeasonData);

      for (let i = 1; i <= 2; i++) {
        const season = selectedSeason - i;
        if (season >= MIN_OGR_SEASON) {
          data.push(this.fetchData(season, this.getLatestForecastIdForSeason(season)));
        }
      }
    }
    return Promise.all(data);
  }

  private getLatestForecastIdForSeason(season: number): number {
    const seasonData = this.availableSeasons.find(item => item.value === season);
    if (seasonData && seasonData.forecasts && seasonData.forecasts.length) {
      return seasonData.forecasts[0].id;
    } else {
      return null;
    }
  }

  private getLabelCssClassLookupFromGridColumns(tabGroup: string, columns: any[], exclude: any[] = [], result = {}, labelPrefix = ''): CssLookup {
    columns.forEach((item) => {
      if (item.tabGroup === tabGroup) {
        if (item.childColumns && !exclude.includes(item.key)) {
          this.getLabelCssClassLookupFromGridColumns(tabGroup, item.childColumns, exclude, result, item.label + ' ');
        } else {
          if (!exclude.includes(item.key)) {
            result[item.key] = { label: labelPrefix + item.label, cssClass: item.key.replace(/_/g, '-') };
          }
        }
      }
    });
    return result;
  }

  private getDataFieldsFromGridColumns(
    tabGroup: string,
    columns: any[],
    exclude: string[] = [],
    result: DataField[] = [],
    labelPrefix = ''
  ): DataField[] {
    columns.forEach((item) => {
      if (item.tabGroup === tabGroup) {
        if (item.childColumns && !exclude.includes(item.key)) {
          this.getDataFieldsFromGridColumns(tabGroup, item.childColumns, exclude, result, item.label + ' ');
        } else {
          if (item.label !== 'Rank' && !exclude.includes(item.key)) {
            const label = item.label === 'Value' ? '' : item.label;
            result.push({ key: item.key, label: labelPrefix + label, cssClass: item.key.replace(/_/g, '-') });
          }
        }
      }
    });
    return result;
  }

  private fetchData(season: number, forecast = null): Promise<any> {
    const params = { season: season, forecast_period: forecast };
    return this.http.get(OGR_REPORT_ENDPOINT, { params: params }).toPromise();
  }

  private fetchSeasonsAndForecasts(): Promise<any> {
    return this.http.get(OGR_SEASONS_AND_FORECASTS_ENDPOINT).toPromise();
  }

  private flashOgrReportButton() {
    this.isOgrReportButtonFlashing = true;
    setTimeout(() => {
      this.isOgrReportButtonFlashing = false;
    }, 1500);
  }

  private updateGridColumns() {
    this.gridColumns = this.originalGridColumns.filter((column) => {
      return  (!column.showAfter || this.selectedSeason.value >= column.showAfter)
        && (!column.removeAfter || this.selectedSeason.value < column.removeAfter);
    });
  }
 }
