<form [formGroup]="form">

  <div class="card-header">Signer Details</div>
  <div class="row card-row">
    <div class="card">

      <div class="row">
        <div class="label">Authorised Signer</div>
        <div class="control"></div>
        <div class="value">{{ data.authorized_signer_name }}</div>
      </div>

      <changeable-field formGroupName="change_requests"
                        label="Postal Address"
                        [data]="data"
                        [dataKeys]="[
                          'postal_address_line_1',
                          'postal_address_line_2',
                          'postal_address_city',
                          'postal_address_country',
                          'postal_address_postal_code'
                        ]"
                        [controls]="[
                          changeRequestsForm.controls['postal_address_line_1'],
                          changeRequestsForm.controls['postal_address_line_2'],
                          changeRequestsForm.controls['postal_address_city'],
                          changeRequestsForm.controls['postal_address_country'],
                          changeRequestsForm.controls['postal_address_postal_code']
                        ]">
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="postalAddressLine1"
                       text="Address Line 1">
          </kendo-label>
          <kendo-textbox #postalAddressLine1 formControlName="postal_address_line_1">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="postalAddressLine2"
                       text="Address Line 2">
          </kendo-label>
          <kendo-textbox #postalAddressLine2 formControlName="postal_address_line_2">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="postalAddressCity"
                       text="City">
          </kendo-label>
          <kendo-textbox #postalAddressCity formControlName="postal_address_city">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="postalAddressCountry"
                       text="Country">
          </kendo-label>
          <kendo-textbox #postalAddressCountry formControlName="postal_address_country">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="postalAddressPostalCode"
                       text="Postcode">
          </kendo-label>
          <kendo-numerictextbox #postalAddressPostalCode
                                [spinners]="false"
                                [decimals]="0"
                                [format]="postcodeFormatOptions"
                                formControlName="postal_address_postal_code">
          </kendo-numerictextbox>
          <kendo-formerror>Error: Invalid Postcode</kendo-formerror>
        </kendo-formfield>
      </changeable-field>

      <changeable-field formGroupName="change_requests"
                        label="Home Address"
                        [data]="data"
                        [dataKeys]="[
                          'physical_address_line_1',
                          'physical_address_line_2',
                          'physical_address_city',
                          'physical_address_country',
                          'physical_address_postal_code'
                        ]"
                        [controls]="[
                          changeRequestsForm.controls['physical_address_line_1'],
                          changeRequestsForm.controls['physical_address_line_2'],
                          changeRequestsForm.controls['physical_address_city'],
                          changeRequestsForm.controls['physical_address_country'],
                          changeRequestsForm.controls['physical_address_postal_code']
                        ]">
        <ng-container dataValue *ngIf="data.physical_address_same_as_postal">
          Same as Postal Address.
        </ng-container>

        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="physicalAddressLine1"
                       text="Address Line 1">
          </kendo-label>
          <kendo-textbox #physicalAddressLine1 formControlName="physical_address_line_1">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="physicalAddressLine2"
                       text="Address Line 2">
          </kendo-label>
          <kendo-textbox #physicalAddressLine2 formControlName="physical_address_line_2">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="physicalAddressCity"
                       text="City">
          </kendo-label>
          <kendo-textbox #physicalAddressCity formControlName="physical_address_city">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="physicalAddressCountry"
                       text="Country">
          </kendo-label>
          <kendo-textbox #physicalAddressCountry formControlName="physical_address_country">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="physicalAddressPostalCode"
                       text="Postcode">
          </kendo-label>
          <kendo-numerictextbox #physicalAddressPostalCode
                                [spinners]="false"
                                [decimals]="0"
                                [format]="postcodeFormatOptions"
                                formControlName="physical_address_postal_code">
          </kendo-numerictextbox>
          <kendo-formerror>Error: Invalid Postcode</kendo-formerror>
        </kendo-formfield>
      </changeable-field>

      <changeable-field formGroupName="change_requests"
                        label="Mobile Number"
                        [data]="data"
                        dataKeys="phone_number_mobile"
                        [controls]="changeRequestsForm.controls['phone_number_mobile']">
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="phoneNumberMobile"
                       text="Mobile Number">
          </kendo-label>
          <kendo-maskedtextbox #phoneNumberMobile
                               prompt=""
                               [maskValidation]="false"
                               mask="00000000000"
                               formControlName="phone_number_mobile">
          </kendo-maskedtextbox>
          <kendo-formerror>Error: Invalid Mobile Number</kendo-formerror>
        </kendo-formfield>
      </changeable-field>

      <div class="row">
        <div class="label">Email Address</div>
        <div class="control"></div>
        <div class="value">{{ data.email_address }}</div>
      </div>
    </div>
  </div>

  <div class="card-header">Entity Details</div>
  <div class="row card-row">
    <div class="card">
      <div class="row">
        <div class="label">Full Name of Legal Entity</div>
        <div class="control"></div>
        <div class="value">{{ data.entity_name }}</div>
      </div>

      <changeable-field formGroupName="change_requests"
                        label="Bank Account Name"
                        [data]="data"
                        dataKeys="entity_bank_account_name"
                        [controls]="changeRequestsForm.controls['entity_bank_account_name']">
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="entityBankAccountName"
                       text="Bank Account Name">
          </kendo-label>
          <kendo-textbox #entityBankAccountName formControlName="entity_bank_account_name">
          </kendo-textbox>
        </kendo-formfield>
      </changeable-field>

      <changeable-field formGroupName="change_requests"
                        label="Bank Account Number"
                        [data]="data"
                        dataKeys="entity_bank_account_number"
                        [controls]="changeRequestsForm.controls['entity_bank_account_number']">
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="entityBankAccountNumber"
                       text="Bank Account Number">
          </kendo-label>
          <kendo-maskedtextbox #entityBankAccountNumber
                               formControlName="entity_bank_account_number"
                               includeLiterals="true"
                               mask="00-0000-0000000-000">
          </kendo-maskedtextbox>
        </kendo-formfield>
      </changeable-field>

      <changeable-field formGroupName="change_requests"
                        label="GST Number"
                        [data]="data"
                        dataKeys="entity_gst_number"
                        [controls]="changeRequestsForm.controls['entity_gst_number']">
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="entityGstNumber"
                       text="GST Number">
          </kendo-label>
          <kendo-maskedtextbox #entityGstNumber
                               formControlName="entity_gst_number"
                               includeLiterals="true"
                               mask="000-000-000">
          </kendo-maskedtextbox>
        </kendo-formfield>
      </changeable-field>
    </div>
  </div>

  <div class="card-header">PPINs</div>
  <div class="row card-row">
    <div class="value" formArrayName="orchard_list">
      <div *ngFor="let orchardForm of orchardFormGroups; let i = index"
           [formGroupName]="i"
           class="orchard card">
        <div class="control">
          <input #included
                 kendoCheckBox
                 type="checkbox"
                 formControlName="included"/>
        </div>
        <div class="orchard-details" [class.not-included]="!included.checked">
          <strong>{{ orchardForm.metadata.grower_number }}, {{ orchardForm.metadata.orchard_name }}</strong>
          <div>{{ orchardForm.metadata.orchard_address }}</div>
          <div *ngIf="orchardForm.metadata.biogro_number">
            BioGro #: {{ orchardForm.metadata.biogro_number }}
            <ng-container *ngIf="orchardForm.metadata.biogro_expiry_date">
              (Expires {{ orchardForm.metadata.biogro_expiry_date | date: dateFormat }})
            </ng-container>
          </div>
          <div *ngFor="let area of orchardForm.metadata.variety_grow_methods">
            {{ area }}
          </div>

          <changeable-field formGroupName="change_requests"
                            label="Exporter"
                            [enableControl]="included.checked"
                            [data]="orchardForm.metadata"
                            dataKeys="orchard_avocado_export_through"
                            [controls]="orchardForm.get('change_requests').get('orchard_avocado_export_through')">
            <kendo-formfield showHints="initial" showErrors="initial">
              <kendo-label [for]="exporter"
                           text="Exporter">
              </kendo-label>
              <kendo-dropdownlist #exporter
                                  formControlName="orchard_avocado_export_through"
                                  textField="text"
                                  valueField="value"
                                  [data]="exporterOptions">
              </kendo-dropdownlist>
            </kendo-formfield>
          </changeable-field>

          <changeable-field formGroupName="change_requests"
                            label="Pest Monitor Center"
                            [enableControl]="included.checked"
                            [data]="orchardForm.metadata"
                            dataKeys="orchard_pest_monitor_center"
                            [controls]="orchardForm.get('change_requests').get('orchard_pest_monitor_center')">
            <kendo-formfield showHints="initial" showErrors="initial">
              <kendo-label [for]="avogreenMonitor"
                           text="Pest Monitor Center">
              </kendo-label>
              <kendo-dropdownlist #avogreenMonitor
                                  [data]="avogreenMonitorOptions"
                                  textField="text"
                                  valueField="value"
                                  formControlName="orchard_pest_monitor_center">
              </kendo-dropdownlist>
            </kendo-formfield>
          </changeable-field>

          <button class="button-modal-with-status selectable button rounded small with-status align-left"
                  type="button"
                  [class]="orchardForm.get('food_safety_declaration').valid ? 'ok' : 'danger'"
                  (click)="openFoodSafetyDeclarationForm(orchardForm, foodSafetyDeclarationForm)">
            <i class="far fa-rectangle-list"></i>
            Food Safety Declaration
            <ng-container *ngIf="!orchardForm.get('food_safety_declaration').valid; else completed">
              <i class="fa fa-times status-icon"></i>
            </ng-container>
            <ng-template #completed>
              <i class="fa fa-check status-icon"></i>
            </ng-template>
          </button>

          <agreement-food-safety-declaration #foodSafetyDeclarationForm
                                             formControlName="food_safety_declaration"
                                             (onClose)="onCloseFoodSafetyDeclarationWindow()">
          </agreement-food-safety-declaration>
        </div>
      </div>
    </div>
  </div>

  <div class="signing-formfield">
    <kendo-formfield>
      <span #signatureRow></span>
      <kendo-textbox class="signing-field"
                     placeholder="Type your name here"
                     formControlName="signature_value">
      </kendo-textbox>
      <kendo-formerror>
        <i class="fas fa-exclamation-triangle"></i>
        Your signature is required.
      </kendo-formerror>
    </kendo-formfield>
  </div>

  <div *ngIf="isDisabled; else signHereMessage" class="sign-here disabled">
    Signing disabled when impersonating a grower.
  </div>

  <ng-template #signHereMessage>
    <div class="sign-here" [class.below]="signSuffix === 'Below'">
      Sign {{ signSuffix }}
    </div>
  </ng-template>

  <div *ngIf="data.countersigner_signature_image" class="countersigner-row">
    <div class="countersigner-row-content">
      <div class="countersigner-signature"
           [style.backgroundImage]="'url(/api/media/' + data.countersigner_signature_image + ')'"></div>
      <div class="countersigner-signature-description">
        {{ data.countersigner_signature_description | lowercase }}
      </div>
    </div>
  </div>

  <div class="controls">
    <div *ngIf="isFormInvalid" class="invalid-form-message">
      <i class="fas fa-exclamation-triangle"></i>
      Please complete all required fields.
    </div>
    <button type="button" class="button rounded" (click)="cancel()">
      <i class="fas fa-chevron-left"></i>
      {{ isViewOnly ? 'Back' : 'Cancel' }}
    </button>
    <button
      class="button ok rounded save-button"
      [class.disabled]="isFormInvalid"
      type="button"
      (click)="saveForm()">
      <i class="fas fa-file-circle-check"></i>
      Save
    </button>
  </div>
</form>
