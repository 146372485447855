import { Component } from '@angular/core';
import {
  AgreementFormComponent, BaseCommitmentFormData, OrchardList
} from 'app/agreements/agreement-page/agreement-form/agreement-form.component';
import { FormArray, FormControl, FormGroup, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import { HttpService } from 'app/shared/services/http.service';
import { StateService } from 'app/shared/services/state.service';
import { Router } from '@angular/router';
import { NotificationService } from 'app/notification/notification.service';
import { GrowerPortalScrollService } from 'app/grower-portal-layout/grower-portal-scroll.service';
import {
  ChangeableField,
  ChangeRequestDescriptor,
  ChangeRequestFormArray,
  ChangeRequestFormControl,
  ChangeRequestFormGroup,
  RequestedChange
} from 'app/agreements/agreement-page/agreement-form/changeable-field/changeable-field.component';
import { AgreementsService } from 'app/agreements/agreements.service';
import { GaService } from 'app/shared/services/ga.service';
import { MetadataFormGroup } from 'app/shared/forms/metadata-form-group';
import { DropdownOption } from 'app/shared/utils';

// TODO: Consider moving some code to a shared base class to remove duplication between this and the AvoGreen agreement form.

export interface KiwiGreenData extends BaseCommitmentFormData {
  kiwigreen_orchard_list: Orchard[];
  receive_results_default: DropdownOption;
  pest_monitor_center_options: DropdownOption[];
}

export interface Contact {
  id: number;
  orchard_contact_orchard_id: DropdownOption;
  orchard_contact_first_name: ChangeableField<string>;
  orchard_contact_last_name: ChangeableField<string>;
  orchard_contact_email: ChangeableField<string>;
  orchard_contact_send_results: ChangeableField<DropdownOption | boolean>;
  contact_first_when_monitoring: ChangeableField<boolean>;
  contact_second_when_monitoring: ChangeableField<boolean>;
}

export interface Orchard extends OrchardList {
  contacts: Contact[];
  orchard_pest_monitor_center: ChangeableField<DropdownOption>;
  season: string;
  showSelectMonitorMessage: boolean;
  isMonitoringWithTrevelyans: boolean;
  showSelectFirstContactMessage: boolean;
}

const TREVELYANS_PMC = 'Trevelyans';

@Component({
  selector: 'agreement-form-kiwigreen',
  templateUrl: './agreement-form-kiwigreen.component.html',
  styleUrls: ['agreement-form-kiwigreen.component.scss', '../agreement-form.component.scss'],
})
export class AgreementFormKiwiGreenComponent extends AgreementFormComponent<KiwiGreenData> {
  endpoint = 'kiwigreen';
  data = {} as KiwiGreenData;
  changeRequestsForm = new ChangeRequestFormGroup({
    postal_address_line_1: new ChangeRequestFormControl('authorized_signer_user_id'),
    postal_address_line_2: new ChangeRequestFormControl('authorized_signer_user_id'),
    postal_address_city: new ChangeRequestFormControl('authorized_signer_user_id'),
    postal_address_country: new ChangeRequestFormControl('authorized_signer_user_id'),
    postal_address_postal_code: new ChangeRequestFormControl('authorized_signer_user_id', { validators: this.postalCodeValidators }),
    physical_address_line_1: new ChangeRequestFormControl('authorized_signer_user_id'),
    physical_address_line_2: new ChangeRequestFormControl('authorized_signer_user_id'),
    physical_address_city: new ChangeRequestFormControl('authorized_signer_user_id'),
    physical_address_country: new ChangeRequestFormControl('authorized_signer_user_id'),
    physical_address_postal_code: new ChangeRequestFormControl('authorized_signer_user_id', { validators: this.postalCodeValidators }),
    phone_number_mobile: new ChangeRequestFormControl('authorized_signer_user_id', { validators: this.mobileNumberValidators }),
  });
  form = new FormGroup({
    change_requests: this.changeRequestsForm,
    kiwigreen_orchard_list: new FormArray([]),
    signature_value: new FormControl('', [Validators.required]),
  });
  showGateFeeMessage = false;
  successMessage = 'Thank you for signing the KiwiGreen agreement.';
  receiveResultsDefault: DropdownOption;
  pestMonitorCenterOptions = [];
  private originalPestMonitorCenter: Record<number, DropdownOption> = {};

  constructor(
    private _http: HttpService,
    private _router: Router,
    private _stateService: StateService,
    private _notificationService: NotificationService,
    private _growerPortalScrollService: GrowerPortalScrollService,
    private _agreementsService: AgreementsService,
    private _gaService: GaService,
  ) {
    super(_http, _router, _stateService, _notificationService, _growerPortalScrollService, _agreementsService, _gaService);
  }

  get orchardFormGroups(): MetadataFormGroup<OrchardList>[] {
    return this.form.controls['kiwigreen_orchard_list'].controls as MetadataFormGroup<OrchardList>[];
  }

  protected handleFormData() {
    super.handleFormData();

    this.pestMonitorCenterOptions = this.data.pest_monitor_center_options || [];
    this.receiveResultsDefault = this.data.receive_results_default;

    const orchardListFormArray = this.form.get('kiwigreen_orchard_list') as FormArray;
    for (const orchard of this.data.kiwigreen_orchard_list) {
      const contactFormArray = new ChangeRequestFormArray('orchard_contact', false, []);
      for (const contact of orchard.contacts) {
        contactFormArray.push(this.createContactFormGroup(orchard, contact));
      }

      const changeRequestForm = new ChangeRequestFormGroup({
        orchard_pest_monitor_center: new ChangeRequestFormControl('orchard_id', { nonNullable: true, validators: [Validators.required] }, null),
        loadout_area_description: new ChangeRequestFormControl('orchard_id', null, ''),
        contacts: contactFormArray,
      });

      // Store the original value of the pest monitor center to determine if the user has changed it later.
      this.originalPestMonitorCenter[orchard.orchard_id] = orchard.orchard_pest_monitor_center.value;

      // Clear the requested change value to hide it in the UI and force the user to (re)select it.
      orchard.orchard_pest_monitor_center = { requested_change: null, value: null };
      changeRequestForm.patchValue(orchard);

      const orchardFormGroup = new MetadataFormGroup<OrchardList>({
        orchard_id: new FormControl(orchard.orchard_id),
        association_id: new FormControl(orchard.association_id),
        change_requests: changeRequestForm
      });

      this.setInitialShowOrchardMonitorForm(orchard, orchardFormGroup);
      orchardFormGroup.metadata = orchard;
      orchardListFormArray.push(orchardFormGroup);
    }

    this.changeRequestsForm.patchValue(this.data);
  }

  private createContactFormGroup(orchard: Orchard, contact: Contact): ChangeRequestFormGroup {
    const sendResultsValue = contact.orchard_contact_send_results.value;

    const form = new ChangeRequestFormGroup({
      orchard_contact_orchard_id: new ChangeRequestFormControl('id', null, contact.orchard_contact_orchard_id),
      orchard_contact_first_name: new ChangeRequestFormControl(
        'id',
        [this.requiredValidator.bind(this), Validators.maxLength(30)]
      ),
      orchard_contact_last_name: new ChangeRequestFormControl(
        'id',
        [this.requiredValidator.bind(this), Validators.maxLength(30)]
      ),
      orchard_contact_email: new ChangeRequestFormControl('id', [Validators.email]),
      orchard_contact_send_results: new ChangeRequestFormControl(
        'id',
        { nonNullable: true },
        typeof sendResultsValue === 'boolean' ? sendResultsValue : sendResultsValue?.value,
        {
          'true': this.receiveResultsDefault,
          'false': 'null',
          '': 'null'
        }
      ),
      contact_first_when_monitoring: new ChangeRequestFormControl(
        'id',
        {
          validators: [
            this.getContactWhenMonitoringValidator(orchard, contact, 'contact_first_when_monitoring'),
            this.getContactFirstWhenMonitoringValidator(orchard)
          ]
        }
      ),
      contact_second_when_monitoring: new ChangeRequestFormControl('id', { validators: [this.getContactWhenMonitoringValidator(orchard, contact, 'contact_second_when_monitoring')]}),
    });
    form.patchValue(contact);
    return form;
  }

  // TODO: Consider removing duplication between validators.
  private getContactFirstWhenMonitoringValidator(orchard: Orchard): ValidatorFn {
    // Validates that at least one contact is selected to be contacted when monitoring.
    const orchardList = this.form.get('kiwigreen_orchard_list');

    return (control: ChangeRequestFormControl): ValidationErrors | null => {
      const controlValue = control.value;
      if (!controlValue) {
        for (const orchardForm of (orchardList as FormArray<MetadataFormGroup<Orchard>>).controls) {
          if (orchardForm.metadata === orchard) {
            for (const _contact of ((orchardForm.get('change_requests.contacts') as FormArray<ChangeRequestFormGroup>).controls)) {
              const contactWhenMonitoringField = _contact.controls.contact_first_when_monitoring as ChangeRequestFormControl;
              if (contactWhenMonitoringField.getLatestValue()) {
                orchard.showSelectFirstContactMessage = false;
                return null;
              }
            }
          }
        }
        orchard.showSelectFirstContactMessage = true;
        return { required: 'At least one person must be set to be contacted first when monitoring' };
      }
      orchard.showSelectFirstContactMessage = false;
      return null;
    };
  }

  private getContactWhenMonitoringValidator(orchard: Orchard, contact: Contact, field: string): ValidatorFn {
    // Validates that only one contact is selected to be contacted when monitoring.
    const orchardList = this.form.get('kiwigreen_orchard_list');

    return (control: ChangeRequestFormControl): ValidationErrors | null => {
      const controlValue = control.value;
      if (controlValue) {
        for (const orchardForm of (orchardList as FormArray<MetadataFormGroup<Orchard>>).controls) {
          if (orchardForm.metadata === orchard) {
            for (const _contact of ((orchardForm.get('change_requests.contacts') as FormArray<ChangeRequestFormGroup>).controls)) {
              const id = (_contact.controls.orchard_contact_orchard_id as ChangeRequestFormControl).objectId;
              if (id !== contact.id) {
                const contactWhenMonitoringField = _contact.controls[field] as ChangeRequestFormControl;
                if (contactWhenMonitoringField.getLatestValue()) {
                  return { duplicate: true }
                }
              }
            }
          }
        }
      }
      return null;
    };
  }

  onContactWhenMonitoringChange(contact: Contact, controlName: string) {
    const formArray = this.form.get('kiwigreen_orchard_list') as FormArray<MetadataFormGroup<Orchard>>;
    for (const orchardForm of formArray.controls) {
      const contacts = orchardForm.get('change_requests.contacts') as FormArray<ChangeRequestFormGroup>;
      for (const _contact of contacts.controls) {
        const contactWhenMonitoringControl = _contact.controls[controlName] as ChangeRequestFormControl;
        if (contactWhenMonitoringControl.objectId !== contact.id) {
          contactWhenMonitoringControl.updateValueAndValidity();
        }
      }
    }
  }

  saveForm() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    this.ga.event(
      'button',
      'click',
      `agreement form: ${ this.agreement.name } save`,
      this.agreement.entity_name
    );

    const formData = this.form.getRawValue();
    this.updateStringToNull(formData);
    this.cleanupPestMonitorCenterChangeRequests(formData);

    super.sendSaveRequest(formData);
  }

  // This function removes the change request for the Pest Monitor Center, if the user has re-selected the same one.
  // Otherwise, it restores the old value to the change request.
  private cleanupPestMonitorCenterChangeRequests(formData: any) {
    for (const orchard of formData.kiwigreen_orchard_list) {
      const originalValue = this.originalPestMonitorCenter[orchard.orchard_id];
      if (originalValue) {
        const changeIndex = (orchard.change_requests || []).findIndex((change: ChangeRequestDescriptor) => change.name === 'orchard_pest_monitor_center');
        if (changeIndex > -1) {
          const changeRequest = orchard.change_requests[changeIndex];
          const newValue = changeRequest.new_value[0].orchard_pest_monitor_center;
          if (newValue?.value === originalValue.value) {
            orchard.change_requests.splice(changeIndex, 1);
          } else {
            changeRequest.old_value[0].orchard_pest_monitor_center = originalValue;
          }
        }
      }
    }
  }

  // Null values are replaced in changeable-field component. It seemed too big of a change to modify this behaviour.
  private updateStringToNull(formData: any) {
    for (const orchard of formData.kiwigreen_orchard_list) {
      for (const changes of orchard.change_requests) {
        for (const change of changes.new_value) {
          for (const key in change) {
            if (change[key] === 'null') {
              change[key] = null;
            }
          }
        }
      }
    }
  }

  formatBoolean(value: boolean): string {
    return value ? 'Yes' : 'No';
  }

  onPestMonitorCenterChange(orchard: Orchard, value: DropdownOption, orchardForm: MetadataFormGroup<OrchardList>) {
    this.setMonitorFormBooleans(orchard, value?.text, '', orchardForm);
    this.setShowGateFeeMessage();

    if (value?.text === TREVELYANS_PMC) {
      const contacts = orchardForm.get('change_requests.contacts') as FormArray<ChangeRequestFormGroup>;
      contacts.reset();
    }
  }

  private setShowGateFeeMessage() {
    this.showGateFeeMessage = this.orchardFormGroups.some(orchardForm => {
      return orchardForm.get('change_requests.orchard_pest_monitor_center').value?.text === TREVELYANS_PMC;
    });
  }

  private setMonitorFormBooleans(orchard: Orchard, newValue: string, oldValue = '', orchardForm: MetadataFormGroup<OrchardList> = null) {
    newValue ||= '';
    orchard.showSelectMonitorMessage = !(this.isViewOnly || oldValue || newValue);
    orchard.isMonitoringWithTrevelyans = newValue.includes(TREVELYANS_PMC) || (!newValue && oldValue.includes(TREVELYANS_PMC));

    if (orchardForm) {
      this.setValidationOnOrchardContacts(orchardForm, orchard.isMonitoringWithTrevelyans);
    }
  }

  private setInitialShowOrchardMonitorForm(orchard: Orchard, orchardForm: MetadataFormGroup<OrchardList>) {
    const newValue = (orchard.orchard_pest_monitor_center.requested_change?.value || '') as string;
    const oldValue = (orchard.orchard_pest_monitor_center.value?.text || '') as string;
    this.setMonitorFormBooleans(orchard, newValue, oldValue, orchardForm);
  }

  private setValidationOnOrchardContacts(orchardForm: MetadataFormGroup<OrchardList>, enable: boolean) {
    const contacts = orchardForm.get('change_requests.contacts') as FormArray<ChangeRequestFormGroup>;
    if (enable) {
      contacts.enable();
    } else {
      contacts.disable();
    }
  }

  addContact(orchard: Orchard, controls: FormArray) {
    const orchardName = [orchard.season, orchard.grower_number, orchard.orchard_name].join(' - ');
    const contact = {
      id: -1,
      orchard_contact_orchard_id: { text: orchardName, value: orchard.orchard_id },
      orchard_contact_first_name: { value: '', requested_change: {} as RequestedChange },
      orchard_contact_last_name: { value: '', requested_change: {} as RequestedChange },
      orchard_contact_email: { value: '', requested_change: {} as RequestedChange },
      orchard_contact_send_results: { value: true, requested_change: {} as RequestedChange },
      contact_first_when_monitoring: { value: false, requested_change: {} as RequestedChange },
      contact_second_when_monitoring: { value: false, requested_change: {} as RequestedChange },
    };
    controls.push(this.createContactFormGroup(orchard, contact));
    orchard.contacts.push(contact);
  }

  deleteContact(orchard: Orchard, controls: FormArray, index: number) {
    orchard.contacts.splice(index, 1);
    controls.removeAt(index);
  }

  private getOldValueFromControl(control: FormControl): any {
    const rawValue = control.getRawValue();
    return rawValue.old_value[0]?.[rawValue.name];
  }

  private requiredValidator(control: FormControl): ValidationErrors | null {
    let value = control.value;

    if (!control.touched) {
      value = this.getOldValueFromControl(control);
    }

    if (Array.isArray(value)) {
      return value.length ? null : { required: true };
    } else {
      return value ? null : { required: true };
    }
  }
}
