import * as Sentry from '@sentry/angular';
import { KiwifruitCountsWidgetComponent } from './kiwifruit-counts-widget/kiwifruit-counts-widget.component';
import { BrowserModule } from '@angular/platform-browser';
import { provideHttpClient, HTTP_INTERCEPTORS, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';
import { ProductGuard } from './shared/product.guard';
import { TrevErrorHandler } from './shared/trev-error-handler';
import { HttpService } from './shared/services/http.service';
import { MaintenanceInterceptor } from './shared/services/http.interceptors.service';
import { ProductInterceptor } from './interceptors/product.interceptor';
import { AuthService } from './shared/services/auth.service';
import { StateService } from './shared/services/state.service';
import { SettingsService } from './shared/services/settings.service';
import { MessageService } from './shared/services/message.service';
import { ClearSearchService } from './shared/services/clear-search.service';
import { ResizeService } from './shared/services/resize.service';
import { AppComponent } from './app.component';
import { UnauthenticatedLayoutComponent } from './shared/unauthenticated-layout/unauthenticated-layout.component';
import { MainMenuComponent } from './shared/main-menu/main-menu.component';
import { MainSearchComponent } from './shared/main-search/main-search.component';
import { LoginComponent } from './shared/login/login.component';
import { DropdownComponent } from './shared/dropdown/dropdown.component';
import { OutsideClickService } from './shared/services/outside-click.service';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { PasswordResetComponent } from './shared/password-reset/password-reset.component';
import { FeedbackFormComponent } from './shared/feedback-form/feedback-form.component';
import { RankComponent } from './shared/rank/rank.component';
import { MaintenanceComponent } from './shared/maintenance/maintenance.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogModule, DialogService, WindowModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ComboBoxModule, DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DatePickerModule, CalendarComponent, TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { UploadModule } from '@progress/kendo-angular-upload';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GrowerPortalLayoutComponent } from './grower-portal-layout/grower-portal-layout.component';
import { GrowerPortalDashboardComponent } from './grower-portal-dashboard/grower-portal-dashboard.component';
import { ToggleComponent } from './shared/toggle/toggle.component';
import { OrchardCalendarComponent } from './orchard-calendar/orchard-calendar.component';
import { LatestNewsComponent } from './latest-news/latest-news.component';
import { TitleService } from './shared/services/title.service';
import { environment } from 'environments/environment';
import { StrToFormattedDatePipe } from './pipes/str-to-formatted-date.pipe';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { SummaryPipe } from './pipes/summary.pipe';
import { DocumentViewerComponent } from './shared/document-viewer/document-viewer.component';
import 'd3';
import { OrchardMapWidgetComponent } from './orchard-map-widget/orchard-map-widget.component';
import { KiwifruitProductionWidgetComponent } from './kiwifruit-production-widget/kiwifruit-production-widget.component';
import { AvocadoProductionWidgetComponent } from './avocado-production-widget/avocado-production-widget.component';
import { SimpleGridComponent } from './simple-grid/simple-grid.component';
import { FormatValuePipe } from 'app/shared/pipes/format-value.pipe';
import { OgrInventoryWidgetComponent } from './ogr-inventory-widget/ogr-inventory-widget.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { GroupedBarChartComponent } from './grouped-bar-chart/grouped-bar-chart.component';
import { GroupedBarChartWithHistoryComponent } from './grouped-bar-chart-with-history/grouped-bar-chart-with-history.component';
import { HelpService } from './shared/services/help.service';
import { HelpButtonComponent } from './shared/help-button/help-button.component';
import { UserOverrideComponent } from './shared/user-override/user-override.component';
import { PackoutDetailsComponent } from './packout-details/packout-details.component';
import { BreadcrumbsModule } from './shared/breadcrumbs/breadcrumbs.module';
import { SimpleTabsComponent } from './simple-tabs/simple-tabs.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { SimpleBarChartModule } from './shared/simple-bar-chart/simple-bar-chart.module';
import { GaService } from './shared/services/ga.service';
import { FooterComponent } from './shared/footer/footer.component';
import { DocumentLibraryComponent } from './shared/document-library/document-library.component';
import { WidgetComponent } from './widget/widget.component';
import { OgrDetailsComponent } from './ogr-details/ogr-details.component';
import { InventoryDetailsComponent } from './inventory-details/inventory-details.component';
import { OrchardCalendarService } from './shared/services/orchard-calendar.service';
import { StripHtmlPipe } from './shared/pipes/strip_html.pipe';
import { DefaultPipe } from './shared/pipes/default.pipe';
import { JoinPipe } from './shared/pipes/join.pipe';
import { SumPipe } from './shared/pipes/sum.pipe';
import { GetItemsPipe } from './shared/pipes/get-items.pipe';
import { ToFrontendUrlPipe } from './shared/pipes/to_frontend_url.pipe';
import { AppendIfExistsModule } from './shared/pipes/append-if-exists.module';
import { DropdownFilterComponent } from './shared/dropdown-filter/dropdown-filter.component';
import { ComboBoxFilterComponent } from './shared/combobox-filter/combobox-filter.component';
import { TabbedContentComponent } from './tabbed-content/tabbed-content.component';
import { EnvIndicatorService } from './shared/services/env-indicator.service';
import { ThinningCalculatorComponent } from 'app/calculators/thinning-calculator/thinning-calculator.component';
import { CosmeticThinningCalculatorComponent } from 'app/calculators/cosmetic-thinning-calculator/cosmetic-thinning-calculator.component';
import { OrchardMapComponent } from './shared/orchard-map/orchard-map.component';
import { OrchardWeatherComponent } from './shared/orchard-weather/orchard-weather.component';
import { TutorialService } from './shared/services/tutorial.service';
import { MultiChartComponent } from './multi-chart/multi-chart.component';
import { GridPagerComponent } from './grid-pager/grid-pager.component';
import { ClearDatePickerDirective } from './directives/clear-date-picker.directive';
import { KiwifruitSampleInformationWidgetComponent } from './kiwifruit-sample-information-widget/kiwifruit-sample-information-widget.component';
import { AvocadoSampleInformationWidgetComponent } from './avocado-sample-information-widget/avocado-sample-information-widget.component';
import { UserProductsService } from './shared/services/user-products.service';
import { ProductSelectorComponent } from './shared/product-selector/product-selector.component';
import { ProductMenuItemComponent } from './shared/product-menu-item/product-menu-item.component';
import '@progress/kendo-angular-intl/locales/en-NZ/all';
import { WindowContainerModule } from './shared/window-container/window-container.module';
import { LoadingIndicatorModule } from './shared/loading-indicator/loading-indicator.module';
import { CheckboxModule } from './shared/checkbox/checkbox.module';
import { SharedModule } from 'app/shared/shared.module';
import { AgreementsListComponent } from 'app/agreements/agreements-list/agreements-list.component';
import { AgreementsService } from 'app/agreements/agreements.service';
import { AgreementPageComponent } from 'app/agreements/agreement-page/agreement-page.component';
import { ContainerRefDirective } from 'app/directives/container-ref.directive';
import { AgreementViewerComponent } from 'app/agreements/agreement-page/agreement-viewer/agreement-viewer.component';
import {
  AgreementFormAvocadoPackingComponent
} from 'app/agreements/agreement-page/agreement-form/agreement-form-avocado-packing/agreement-form-avocado-packing.component';
import {
  AgreementFormAvogreenComponent
} from 'app/agreements/agreement-page/agreement-form/agreement-form-avogreen/agreement-form-avogreen.component';
import {
  AgreementFormKiwiGreenComponent
} from 'app/agreements/agreement-page/agreement-form/agreement-form-kiwigreen/agreement-form-kiwigreen.component';
import {
  AgreementFormKiwifruitPackingComponent
} from 'app/agreements/agreement-page/agreement-form/agreement-form-kiwifruit-packing/agreement-form-kiwifruit-packing.component';
import {
  AgreementFormKiwifruitSupplyComponent
} from 'app/agreements/agreement-page/agreement-form/agreement-form-kiwifruit-supply/agreement-form-kiwifruit-supply.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { LabelModule } from '@progress/kendo-angular-label';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { NotificationComponent } from 'app/notification/notification.component';
import { NotificationService } from 'app/notification/notification.service';
import { GrowerPortalScrollService } from 'app/grower-portal-layout/grower-portal-scroll.service';
import { ChangeableFieldComponent } from './agreements/agreement-page/agreement-form/changeable-field/changeable-field.component';
import { NullableValueSelectorComponent } from 'app/shared/nullable-value-selector/nullable-value-selector.component';
import { AgreementFoodSafetyDeclarationComponent } from './agreements/agreement-page/agreement-form/agreement-food-safety-declaration/agreement-food-safety-declaration.component';
import { ResourceCategoryPageComponent } from 'app/resources/resource-category-page/resource-category-page.component';
import { ResourceTileComponent } from 'app/resources/resource-tile/resource-tile.component';
import { ResourceGroupPageComponent } from 'app/resources/resource-group-page/resource-group-page.component';
import { BadgeComponent } from './badge/badge.component';
import { ResourceImageTileComponent } from 'app/resources/resource-image-tile/resource-image-tile.component';
import { ResourcePageComponent } from './resources/resource-page/resource-page.component';
import { MessageOverlayComponent } from './message-overlay/message-overlay.component';
import { NewsViewerComponent } from './news-viewer/news-viewer.component';

import { IconsModule } from '@progress/kendo-angular-icons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { PickingContractorRankingComponent } from 'app/picking-contractor-ranking/picking-contractor-ranking.component';
import { HeatmapItemComponent } from './heatmap-item/heatmap-item.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

const appRoutes: Routes = [
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    data: { title: 'Maintenance' }
  },
  {
    path: '',
    component: GrowerPortalLayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: GrowerPortalDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Dashboard',
          products: ['Avocado', 'Kiwifruit']
        }
      },
      {
        path: 'packout-details',
        component: PackoutDetailsComponent,
        canActivate: [AuthGuard, ProductGuard],
        data: {
          title: 'Packout Details',
          products: ['Kiwifruit']
        }
      },
      {
        path: 'ogr-details',
        component: OgrDetailsComponent,
        canActivate: [AuthGuard, ProductGuard],
        data: {
          title: 'OGR Details',
          products: ['Kiwifruit']
        }
      },
      {
        path: 'inventory-details',
        component: InventoryDetailsComponent,
        canActivate: [AuthGuard, ProductGuard],
        data: {
          title: 'Inventory Details',
          products: ['Kiwifruit']
        }
      },
      {
        path: 'agreements',
        children: [
          {
            path: '',
            component: AgreementsListComponent,
            canActivate: [AuthGuard, ProductGuard],
            data: {
              title: 'Agreements List',
              products: ['Avocado', 'Kiwifruit']
            }
          },
          {
            path: ':uuid',
            component: AgreementPageComponent,
            canActivate: [AuthGuard, ProductGuard],
            data: {
              title: 'Agreement',
              products: ['Avocado', 'Kiwifruit']
            }
          }
        ]
      },
      {
        path: 'resources',
        children: [
          {
            path: '',
            component: ResourceCategoryPageComponent,
            canActivate: [AuthGuard],
            data: {
              title: 'Resources',
              products: ['Avocado', 'Kiwifruit']
            }
          },
          {
            path: ':category',
            children: [
              {
                path: '',
                component: ResourceGroupPageComponent,
                canActivate: [AuthGuard],
                data: {
                  products: ['Avocado', 'Kiwifruit']
                }
              },
              {
                path: ':group',
                component: ResourcePageComponent,
                canActivate: [AuthGuard],
                data: {
                  products: ['Avocado', 'Kiwifruit']
                }
              }
            ]
          }
        ]
      },
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '',
    component: UnauthenticatedLayoutComponent,
    children: [
      {
        matcher: (segments) => {
          if (segments?.[0].path.startsWith('login')) {
            return {
              consumed: [segments[0]],
            };
          }

          return null;
        },
        component: LoginComponent,
        data: {
          title: 'Login'
        }
      },
      {
        path: 'password_reset/:uidb64/:token',
        component: PasswordResetComponent,
        data: {
          title: 'Password Reset'
        }
      },
      {
        path: 'user_override',
        component: UserOverrideComponent
      },
      {
        path: '**',
        component: PageNotFoundComponent,
        data: {
          title: 'Not Found'
        }
      }
    ]

  }
];

if (environment['SENTRY_DSN']) {
  Sentry.init({
    dsn: environment['SENTRY_DSN'],

    // Note: This value must match the 'release' setting in the SentryWebpackPlugin constructor
    // found in the custom webpack config file.
    release: 'grower_portal_' + environment['COMMIT_HASH']
  });
}

@NgModule({
  declarations: [
    AppComponent,
    MainMenuComponent,
    MainSearchComponent,
    LoginComponent,
    UserOverrideComponent,
    DropdownComponent,
    PageNotFoundComponent,
    UnauthenticatedLayoutComponent,
    PasswordResetComponent,
    FeedbackFormComponent,
    RankComponent,
    MaintenanceComponent,
    GrowerPortalLayoutComponent,
    GrowerPortalDashboardComponent,
    ToggleComponent,
    OrchardCalendarComponent,
    LatestNewsComponent,
    StrToFormattedDatePipe,
    FormatNumberPipe,
    SummaryPipe,
    DocumentViewerComponent,
    TooltipComponent,
    OrchardMapWidgetComponent,
    KiwifruitProductionWidgetComponent,
    AvocadoProductionWidgetComponent,
    SimpleGridComponent,
    FormatValuePipe,
    OgrInventoryWidgetComponent,
    PieChartComponent,
    GroupedBarChartComponent,
    GroupedBarChartWithHistoryComponent,
    DocumentLibraryComponent,
    HelpButtonComponent,
    PackoutDetailsComponent,
    OgrDetailsComponent,
    InventoryDetailsComponent,
    SimpleTabsComponent,
    LineChartComponent,
    FooterComponent,
    WidgetComponent,
    StripHtmlPipe,
    DefaultPipe,
    ToFrontendUrlPipe,
    JoinPipe,
    GetItemsPipe,
    SumPipe,
    DropdownFilterComponent,
    ComboBoxFilterComponent,
    TabbedContentComponent,
    KiwifruitCountsWidgetComponent,
    ThinningCalculatorComponent,
    CosmeticThinningCalculatorComponent,
    OrchardMapComponent,
    OrchardWeatherComponent,
    MultiChartComponent,
    GridPagerComponent,
    ClearDatePickerDirective,
    KiwifruitSampleInformationWidgetComponent,
    AvocadoSampleInformationWidgetComponent,
    ProductSelectorComponent,
    ProductMenuItemComponent,
    AgreementsListComponent,
    AgreementPageComponent,
    AgreementViewerComponent,
    ContainerRefDirective,
    AgreementFormAvocadoPackingComponent,
    AgreementFormAvogreenComponent,
    AgreementFormKiwiGreenComponent,
    AgreementFormKiwifruitPackingComponent,
    AgreementFormKiwifruitSupplyComponent,
    NotificationComponent,
    ChangeableFieldComponent,
    NullableValueSelectorComponent,
    AgreementFoodSafetyDeclarationComponent,
    ResourceCategoryPageComponent,
    ResourceTileComponent,
    ResourceGroupPageComponent,
    BadgeComponent,
    ResourceImageTileComponent,
    ResourcePageComponent,
    MessageOverlayComponent,
    NewsViewerComponent,
    PickingContractorRankingComponent,
    HeatmapItemComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
    BrowserAnimationsModule,
    GridModule,
    DialogModule,
    ButtonsModule,
    DatePickerModule,
    InputsModule,
    LayoutModule,
    TimePickerModule,
    ComboBoxModule,
    DropDownListModule,
    WindowModule,
    UploadModule,
    SimpleBarChartModule,
    WindowContainerModule,
    LoadingIndicatorModule,
    CheckboxModule,
    SharedModule,
    LabelModule,
    NgxExtendedPdfViewerModule,
    NotificationModule,
    BreadcrumbsModule,
    IconsModule,
    DropDownsModule,
    AppendIfExistsModule,
    TooltipModule,
  ],
  providers: [
    SettingsService,
    MessageService,
    AuthGuard,
    ProductGuard,
    HttpService,
    AuthService,
    UserProductsService,
    StateService,
    OutsideClickService,
    ClearSearchService,
    CalendarComponent,
    DialogService,
    ResizeService,
    HelpService,
    GaService,
    OrchardCalendarService,
    TrevErrorHandler,
    TitleService,
    {
      provide: ErrorHandler,
      useClass: TrevErrorHandler
    },
    EnvIndicatorService,
    TutorialService,
    AgreementsService,
    NotificationService,
    GrowerPortalScrollService,
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: MaintenanceInterceptor
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: ProductInterceptor
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-NZ'
    },
  ],
  bootstrap: [
    AppComponent
  ],
  exports: [
    ProductSelectorComponent,
    ClearDatePickerDirective
  ]
})
export class AppModule { }
