import { HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { UserOverrideComponent } from 'app/shared/user-override/user-override.component';
import { environment } from 'environments/environment';
import { DropdownItem } from 'app/shared/dropdown/dropdown.component';
import { SettingsService } from 'app/shared/services/settings.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import moment from 'moment';

const OPEN_ENTITY_PATH = '/ip/entities/';
const OPEN_ORCHARD_PATH = '/ip/orchard/';
const OPEN_MATURITY_AREA_PATH = '/ip/matarea/';
const OPEN_MATURITY_SAMPLE_PATH = '/ip/matsample/';
const OPEN_BLOCK_PATH = '/ip/block/';
const OPEN_MAP_PATH = '/api2/v1/orchards/reports/map/';
const OPEN_DOCUMENT_ENDPOINT = 'api2/v1/orchards/documents/';
const OPEN_CLEARANCE_TO_PICK_ENDPOINT = '/api2/v1/orchards/maturity_areas/reports/kiwifruit/clearance_to_pick/';
const OPEN_PACKOUT_REPORT_ENDPOINT = '/api2/v1/harvest/reports/packout/';

export type ChangeRequestOption = string | number | boolean | Date | DropdownOption | DropdownOption[];
export interface DropdownOption {
  text: string;
  value: string|number;
}

export type PackoutReportType = 'orchard' | 'ma' | 'packrun' | 'batch';
export const MAIN_MENU_HEIGHT = 60;  // px

export class Utils {
  static isMobile(): boolean {
    // Max width for our mobile layout
    return window.matchMedia('(max-width: 1083px)').matches;
  }

  static getLocalStorageValue(key, defaultValue = ''): string {
    if (typeof(Storage) !== 'undefined') {
      return localStorage.getItem(key) || defaultValue;
    }
    return null;
  }

  static setLocalStorageValue(key, value) {
    if (typeof(Storage) !== 'undefined') {
      localStorage.setItem(key, value);
    }
  }

  static joinHttpParams(a: HttpParams, b: HttpParams) {
    if (a && b) {
      for (const key of a.keys()) {
        for (const value of (a.getAll(key) || [])) {
          b = b.append(key, value);
        }
      }
      return b;
    } else {
      return a || b;
    }
  }

  static getQueryParams(url: string): any {
    if (!url) {
      return {};
    }

    url = url.substring(url.indexOf('?') + 1);
    return url.split('&').reduce((result, item) => {
      let key, value;
      [key, value] = item.split('=');
      if (key && value) {
        result[key] = value;
      }
      return result;
    }, {});
  }

  static urlDecode(url: string) {
    if (!url) {
      return '';
    }
    return decodeURIComponent(url.replace(/\+/g, ' '));
  }

  static formatShortDateTime(value): string {
    return moment(value).local().format(SettingsService.SHORT_DATE_TIME_FORMAT);
  }

  static formatShortDate(value): string {
    return moment(value).local().format(SettingsService.SHORT_DATE_FORMAT);
  }

  static formatServerDate(value): string {
    return moment(value).local().format(SettingsService.SERVER_DATE_FORMAT);
  }

  static formatServerDateTime(value): string {
    return moment(value).local().format(SettingsService.SERVER_DATE_TIME_FORMAT);
  }

  static parseDate(value: string): Date {
    if (value && value.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return moment(value, SettingsService.SERVER_DATE_FORMAT).toDate();
    }
    return null;
  }

  static parseDateTime(value: string): Date {
    if (value) {
      return moment(value, SettingsService.SERVER_DATE_TIME_FORMAT).toDate();
    }
    return null;
  }

  static formatPackOutDate(value: string): string {
    return moment(value).local().format(SettingsService.PACK_OUT_DATE_FORMAT);
  }

  static formatWeekdayDateShort(value: Date | string | number): string {
    return this.getFormattedDate(value, SettingsService.SHORT_WEEKDAY_DATE_FORMAT);
  }

  static formatDateShort(value: Date | string | number): string {
    return this.getFormattedDate(value, SettingsService.ANGULAR_SHORT_DATE_FORMAT);
  }

  static formatDateTimeShort(value: Date | string | number): string {
    return this.getFormattedDate(value, SettingsService.ANGULAR_SHORT_DATE_TIME_FORMAT);
  }

  static getFormattedDate(value: Date | string | number, format: string) {
    return new DatePipe('en-NZ').transform(value, format);
  }

  static parseTime(value): Date {
    return moment(value, SettingsService.SERVER_TIME_FORMAT).toDate();
  }

  static formatServerTime(value): string {
    return moment(value).local().format(SettingsService.SERVER_TIME_FORMAT);
  }

  static uniqueObjectArrayByKey(data, key): any[] {
    const uniqueList = [];
    data.forEach((value) => {
      for (const obj of uniqueList) {
        if (obj[key] === value[key]) {
          return;
        }
      }
      uniqueList.push(value);
    });
    return uniqueList;
  }

  static isFunction(value) {
    return typeof(value) === 'function';
  }

  static compareDate(dateTimeA, dateTimeB): boolean {
    return moment(dateTimeA).isSame(dateTimeB, 'day');
  }

  // Deep compare of two objects
  static compare(a, b, excluded = [], included = []): boolean {
    if (a === b) {
      return true;
    }

    if (!(a instanceof Object) || !(b instanceof Object)) {
      return false;
    }

    if (a.constructor !== b.constructor) {
      return false;
    }

    if (a.constructor.name === 'Date' && a.getTime() !== b.getTime()) {
      return false;
    }

    for (const prop in a) {
      if (!a.hasOwnProperty(prop) || excluded.includes(prop) || (included.length && !included.includes(prop))) {
        continue;
      }

      if (!b.hasOwnProperty(prop)) {
        return false;
      }

      if (a[prop] === b[prop]) {
        continue;
      }

      if (typeof(a[prop]) !== 'object') {
        return false;
      }

      if (!this.compare(a[prop], b[prop], excluded, included)) {
        return false;
      }
    }

    for (const prop in b) {
      if (excluded.includes(prop)) {
        continue;
      }

      if (b.hasOwnProperty(prop) && !a.hasOwnProperty(prop)) {
        return false;
      }
    }

    return true;
  }

  // Deep clone of an object
  static clone(src: any): any {
    if (src == null || typeof(src) != 'object') {
      return src;
    }

    if (src.constructor != Object && src.constructor != Array) {
      return src;
    }

    if (src.constructor == Date || src.constructor == RegExp || src.constructor == Function ||
      src.constructor == String || src.constructor == Number || src.constructor == Boolean) {
      return new src.constructor(src);
    }

    let copy = new src.constructor();

    for (const key in src) {
      copy[key] = typeof(copy[key]) === 'undefined' ? this.clone(src[key]) : copy[key];
    }

    return copy;
  }

  static convertFilterToQueryString(queryStr): string {
    const start = queryStr.search('filter=');
    let end = queryStr.substring(start).search('&');
    end = end > -1 ? end : queryStr.length - 1;
    end += start;

    const filterString = queryStr.substring(start, end);
    const cleanedQueryStr = filterString
      .replace(/(?:filter=)*(\()*(\))*(')*/g, '')
      .replace(/(?:~contains~)|(?:~eq~datetime)|(?:~eq~)/g, '=')
      .replace(/~and~/g, '&');

    return queryStr.substring(0, start) + cleanedQueryStr + queryStr.substring(end);
  }

  static randomBgCssClass(maxIndex) {
    return 'bg-' + Math.ceil(Math.random() * maxIndex);
  }

  static fixDateStringFormat(src: string): string {
    return src.replace(/:?00$/, ':00');
  }

  static findItem(data: any[], key, value) {
    return data.find(item => item[key] === value);
  }

  static findItems(data, key, value): any[] {
    return data.filter((item) => {
      return item[key] === value;
    });
  }

  static isSizeClass1(variety: string, traySize: number, season: number) {
    return (variety === 'GA' && ((season < 2022 && traySize < 42) || (season >= 2022 && traySize < 39))) ||
      (variety !== 'GA' && traySize < 46);
  }

  static openGrowerReport(url: string): void {
    const override = UserOverrideComponent.getOverride();
    if (override?.user_id) {
      const indexOfParamsStart = url.indexOf('?');
      const joiner = indexOfParamsStart >= 0 ? '&' : '?';
      url = `${ url }${ joiner }user_override_id=${ override.user_id }`;
    }

    window.open(url, '_blank');
  }

  static reloadIframeContent() {
    document.getElementById('main-content-frame')['contentWindow'].location.reload();
  }

  static openOrchard(item) {
    const orchardId = item.orchard_id || item.id;
    window.open(OPEN_ORCHARD_PATH + orchardId, '_blank');
  }

  static openEntity(item) {
    const entityId = item.entity_id || item.id;
    window.open(`${OPEN_ENTITY_PATH}${entityId}/`, '_blank');
  }

  static openMaturityArea(item) {
    const maturityAreaId = item.maturity_area_id || item.id;
    window.open(OPEN_MATURITY_AREA_PATH + maturityAreaId, '_blank');
  }

  static openMaturitySample(id: number) {
    window.open(OPEN_MATURITY_SAMPLE_PATH + id, '_blank');
  }

  static openBlock(item) {
    const blockId = item.block_id || item.id;
    window.open(OPEN_BLOCK_PATH + blockId, '_blank');
  }

  static openOrchardMap(item) {
    const orchardId = item.orchard_id || item.id;
    window.open(OPEN_MAP_PATH + orchardId, '_blank');
  }

  static openKiwifruitClearanceToPick(maturity_area_id: number) {
    window.open(OPEN_CLEARANCE_TO_PICK_ENDPOINT + maturity_area_id, '_blank');
  }

  static openPackoutReport(product_name: string, type: PackoutReportType, id: number, variety_id: number = null, grow_method_id: number = null) {
    let params = new HttpParams({
      fromObject: {
        day_grouping: 0,
        type: type,
        id: id,
      }
    });
    if (type === 'orchard') {
      params = params.appendAll({
        variety: variety_id,
        grow_method: grow_method_id,
      });
    }
    const url = `${ OPEN_PACKOUT_REPORT_ENDPOINT }${ product_name.toLowerCase() }/?${ params.toString() }`;
    window.open(url, '_blank');
  }

  static getDocumentPath(documentId: number): string {
    if (!documentId) {
      return null;
    }
    return `${OPEN_DOCUMENT_ENDPOINT}${documentId}/`;
  }

  static roundTo(value: number, decimalPlaces = 2) {
    const scale = Math.pow(10, decimalPlaces);
    return Math.round((value + Number.EPSILON) * scale) / scale;
  }

  static isGrowerPortal(): boolean {
    return environment.APP_NAME === 'grower portal';
  }

  static getDropdownItems(data: any[], labelKey: string, valueKey: string, isOrdered = false): DropdownItem[] {
    return this.getUniqueItemsByKey(data, labelKey, isOrdered).map((item: any) => {
      return { label: item[labelKey], value: item[valueKey] };
    });
  }

  static getUniqueItemsByKey(data: any[], key: string, isOrdered = false) {
    const uniqueValues = this.uniqueObjectArrayByKey(data, key);
    if (isOrdered) {
      return uniqueValues.sort((a, b) => {
        return ('' + a[key]).localeCompare(b[key]);
      });
    } else {
      return uniqueValues;
    }
  }

  static immutableReverse(source: any[]): any[] {
    return source.slice().reverse();
  }

  static getRestrictedValue<T = string>(value: T, restrictedList: any[], defaultValue: T, key = 'value'): T {
    return value && restrictedList.find(item => item[key] === value) ? value : defaultValue;
  }

  static isWindows(): boolean {
    return navigator.appVersion.indexOf('Win') > -1;
  }

  static snakeToTitle(value: string): string {
    return value.replace (/^_*(.)/, (_, c) => c.toUpperCase())
                .replace (/_+(.)/g, (_, c) => ' ' + c.toUpperCase());
  }

  static titleCase(value: string): string {
    return value.replace(/\b\w/g, (c) => c.toUpperCase());
  }

  static getCurrentRouteDataItem(snapshot: ActivatedRouteSnapshot, key: string = null): any {
    const data = {};
    while (snapshot.firstChild) {
      snapshot = snapshot.firstChild;
      if (Object.keys(snapshot.data).length) {
        const value = snapshot.data[key];
        if (key && value) {
          return value;
        } else {
          Object.assign(data, snapshot.data);
        }
      }
    }
    return key ? null : data;
  }
}
