<kendo-window #windowContainer
              class="window-container"
              [class.maximized]="isWindowMaximized"
              [width]="windowWidth"
              [minWidth]="minWindowWidth"
              [height]="windowHeight"
              [minHeight]="minWindowHeight"
              [top]="windowTop"
              (close)="closeHandler()"
              (resizeEnd)="resizeEndHandler()"
              (resizeStart)="resizeStartHandler()"
              [resizable]="resizable"
              [draggable]="draggable"
              *ngIf="isWindowVisible">
  <kendo-window-titlebar [class]="windowTitleClass">
    <div class="window-title-content" (dblclick)="onHeaderDoubleClick($event)">
      <div *ngIf="windowTitle" class='k-window-title'>{{ windowTitle }}</div>
      <div *ngIf="htmlTitle" class='k-window-title' [innerHTML]="htmlTitle"></div>
    </div>
    <button *ngIf="showMaximizeButton" class="button transparent maximize-button" (click)="maximizeWindow()">
      <i [class]="'fas ' + (isWindowMaximized ? 'fa-window-restore' : 'fa-window-maximize')"></i>
    </button>
    <button kendoWindowCloseAction class="k-dialog-close" id="window-container-titlebar-close-button" (click)="closeWindow()"></button>
  </kendo-window-titlebar>
  <div class="content">
    <ng-content></ng-content>
  </div>
  <kendo-dialog-actions *ngIf="buttonsList?.length" class="window-actions">
    <button *ngFor="let button of buttonsList"
            (click)="button.action() || null"
            kendoButton
            [type]="button.type || 'button'"
            [attr.form]="button['formId'] || ''"
            [class]="button['cssClass'] || ''"
            [primary]="button['primary']">
      <i *ngIf="button['icon']" [class]="button['icon']"></i>
      {{ button.label }}
      <div class="sub-label" *ngIf="button['subLabel']">{{ button['subLabel'] }}</div>
    </button>
  </kendo-dialog-actions>
  <loading-indicator [class.visible]="isLoading"
                     [isAbsolute]="true"
                     [noFade]="true"></loading-indicator>
</kendo-window>
