import { Component, OnInit, ViewChild, HostBinding, OnDestroy } from '@angular/core';
import { formatNumber } from '@angular/common';
import { HttpService } from 'app/shared/services/http.service';
import { Utils } from 'app/shared/utils';
import { LineChartDataItem, LineChartComponent, TooltipConfig } from '../line-chart/line-chart.component';
import { SeasonDropdownItem } from 'app/grower-portal-dashboard/grower-portal-dashboard.component';
import { ChartAverage } from 'app/interfaces/chart.interface';
import { ActivatedRoute } from '@angular/router';
import { Details, DataCategoryTab, Level } from '../lib/details';
import { BarChartGroup } from 'app/grouped-bar-chart/grouped-bar-chart.component';
import { Subscription } from 'rxjs';
import { TutorialService } from 'app/shared/services/tutorial.service';
import { FormatValuePipe } from 'app/shared/pipes/format-value.pipe';
import { GridRowTooltip } from 'app/simple-grid/simple-grid.component';
import { GaService } from 'app/shared/services/ga.service';

const REJECT_CHART_FIELDS = [
  { key: 'rejects', label: 'Rejects' },
  { key: 'c3_percent', label: 'Class 3' },
  { key: 'low_dry_matter', label: 'Low DM' },
  { key: 'c2_trays_percent', label: 'Class 2' },
  { key: 'nss', label: 'NSS' },
  { key: 'undersize', label: 'Undersize' }
];
export const PRODUCTION_SEASONS_ENDPOINT = 'growers/kiwifruit/production/seasons/';
const PRODUCTION_REPORT_ENDPOINT = 'growers/kiwifruit/production/details/pack_runs/';
const PRODUCTION_REJECT_ENDPOINT = 'growers/kiwifruit/production/details/rejects/';
const PACKOUT_URL = 'api2/v1/harvest/reports/packout';
const SCORECARD_URL = 'api2/v1/harvest/reports/packout/scorecard';
export const PACKING_CHARGES_ENDPOINT = 'growers/kiwifruit/production/packing_charges/';
const AVERAGES_ENDPOINT = 'growers/kiwifruit/production/summary/averages/';
const UNCONFIRMED_PRODUCING_HA_MESSAGE = 'Unconfirmed producing Ha';
const BINS_HEADER_TOOLTIP_MESSAGE = 'Bins tipped include bin store bins tipped.<br><br>Bins receipted include bin store bins receipted.';

export interface PackingCharge {
  season: number;
  variety: number;
  variety_code: string;
  grow_method: number;
  grow_method_code: string;
  base_rate: number;
  rejects_first_bracket_limit: number;
  rejects_first_bracket_rate: number;
  rejects_second_bracket_limit: number;
  rejects_second_bracket_rate: number;
  rejects_third_bracket_rate: number;
  nir_rate: number;
}

interface RawRequestResultItemBase {
  grow_method_id: number;
  maturity_area_id: number;
  orchard_id: number;
  variety_id: number;
  [key: string]: any;
}

interface RawRequestResult {
  maturity_area_bins: RawRequestResultItemBase[];
  pack_dates: RawRequestResultItemBase[];
}

@Component({
  selector: 'app-packout-details',
  templateUrl: './packout-details.component.html',
  styleUrls: ['./packout-details.component.scss']
})
export class PackoutDetailsComponent extends Details implements OnInit, OnDestroy {
  updatedAt = new Date();
  groupedLabels = this.getGroupedLabel(['Grower Number', 'Maturity Area', 'Pack Date']);
  hiddenColumns: number|string[] = [];
  availableSizes: number[] = [];
  dataCategoryTabs: DataCategoryTab[] = [
    { title: 'Rejects' },
    { title: 'Profile' }
  ];
  selectedDataCategoryTab: DataCategoryTab = this.dataCategoryTabs[0];
  gridColumns = [
    {
      tabGroup: 'main', label: this.groupedLabels, rowspan: 2, align: 'left', key: 'groupColumnValue', cssClass: 'fixed-wide'
    },
    {
      tabGroup: 'main', label: 'Bins<br>(incl. BS)', align: 'center', cssClass: 'two-line-height', childColumns: [
        {
          tabGroup: 'main',
          label: 'Tipped /<br>Receipted',
          key: 'bins_tipped_over_receipted',
          cssClass: 'fixed-regular',
          tooltipContent: BINS_HEADER_TOOLTIP_MESSAGE
        }
      ],
      tooltipContent: BINS_HEADER_TOOLTIP_MESSAGE
    },
    {
      tabGroup: 'main', label: 'Bin Store', align: 'center', cssClass: 'two-line-height', childColumns: [
        {
          tabGroup: 'main',
          label: 'Tipped /<br>Receipted',
          key: 'buffer_store_bins_tipped_over_receipted',
          cssClass: 'fixed-regular',
          tooltipContent: BINS_HEADER_TOOLTIP_MESSAGE
        },
      ],
      tooltipContent: BINS_HEADER_TOOLTIP_MESSAGE
    },
    {
      tabGroup: 'main',
      label: 'Prod. Ha',
      rowspan: 2,
      precision: 2,
      key: 'confirmed_producing_ha',
      messages: {
        '?': UNCONFIRMED_PRODUCING_HA_MESSAGE
      }
    },
    {
      tabGroup: 'main', label: 'Class 1 Trays<br>(excl. NSS Trays)', align: 'center', cssClass: 'two-line-height', childColumns: [
        { tabGroup: 'main', label: 'Total', key: 'c1_trays_total', precision: 1, cssClass: 'c1-trays-total' },
        { tabGroup: 'main', label: 'Per Bin', key: 'c1_trays_per_bin', precision: 1, cssClass: 'c1-trays-per-bin' },
        { tabGroup: 'main', label: '%', key: 'c1_trays_percent', precision: 1, cssClass: 'c1-trays-percent' }
      ]
    },
    {
      tabGroup: 'main', label: 'Size', cssClass: 'two-line-height', childColumns: [
        { tabGroup: 'main', label: 'Avg', key: 'size', precision: 1, cssClass: 'size' }
      ]
    },
    {
      tabGroup: 'main', label: 'Dry Matter Weight<br>(Tonnes)', align: 'center', cssClass: 'two-line-height', childColumns: [
        { tabGroup: 'main', label: 'Total', key: 'dry_matter_weight_total', precision: 1, cssClass: 'dry-matter-weight-total' },
        {
          tabGroup: 'main',
          label: 'Per Ha',
          key: 'dry_matter_weight_per_ha',
          precision: 1,
          cssClass: 'dry-matter-weight-per-ha',
          messages: {
            '?': UNCONFIRMED_PRODUCING_HA_MESSAGE
          }
        },
      ]
    },
    {
      tabGroup: 'main', label: 'Packing Cost', align: 'center', cssClass: 'two-line-height', childColumns: [
        { tabGroup: 'main', label: 'Tray', key: 'packing_cost_per_tray', precision: 2, cssClass: 'packing-cost-per-tray', prefix: '$' },
        {
          tabGroup: 'main',
          label: 'Per Ha',
          key: 'packing_cost_per_ha',
          precision: 2,
          cssClass: 'packing-cost-per-ha',
          prefix: '$',
          messages: {
            '?': UNCONFIRMED_PRODUCING_HA_MESSAGE
          }
        },
      ]
    },
    {
      tabGroup: 'main', label: 'Actual TZG', rowspan: 2, key: 'actual_tzg', precision: 2, cssClass: 'actual-tzg'
    },
    {
      tabGroup: 'rejects',
      label: 'Class 2 Trays',
      align: 'center',
      dataCategory: 'rejects',
      cssClass: 'divider two-line-height',
      childColumns: [
        { tabGroup: 'rejects', label: 'Total', key: 'c2_trays_total', precision: 1, cssClass: 'divider c2-trays-total' },
        { tabGroup: 'rejects', label: '%', key: 'c2_trays_percent', precision: 1, cssClass: 'c2-trays-percent' }
      ]
    },
    {
      tabGroup: 'rejects',
      label: 'NSS',
      dataCategory: 'rejects',
      cssClass: 'two-line-height',
      childColumns: [
        {
          tabGroup: 'rejects',
          label: '%',
          key: 'nss',
          precision: 1,
          cssClass: 'nss'
        }
      ]
    },
    {
      tabGroup: 'rejects',
      label: 'Chargeable Rejects',
      dataCategory: 'rejects',
      cssClass: 'two-line-height chargeable-rejects',
      childColumns: [
        {
          tabGroup: 'rejects',
          label: '%',
          key: 'chargeable_rejects',
          precision: 1,
          cssClass: 'chargeable-rejects'
        }
      ]
    },
    {
      tabGroup: 'rejects',
      label: 'Low DM',
      dataCategory: 'rejects',
      cssClass: 'two-line-height',
      childColumns: [
        {
          tabGroup: 'rejects',
          label: '%',
          key: 'low_dry_matter',
          precision: 1,
          cssClass: 'low-dry-matter'
        }
      ]
    },
    {
      tabGroup: 'rejects',
      label: 'Class 3',
      dataCategory: 'rejects',
      cssClass: 'two-line-height',
      childColumns: [
        {
          tabGroup: 'rejects',
          label: '%',
          key: 'c3_percent',
          precision: 1,
          cssClass: 'c3-percent'
        }
      ]
    },
    {
      tabGroup: 'rejects',
      label: 'Under Size',
      dataCategory: 'rejects',
      cssClass: 'two-line-height undersize',
      childColumns: [
        {
          tabGroup: 'rejects',
          label: '%',
          key: 'undersize',
          precision: 1,
          cssClass: 'undersize'
        }
      ]
    },
    {
      tabGroup: 'rejects',
      label: 'Rejects',
      dataCategory: 'rejects',
      cssClass: 'two-line-height',
      childColumns: [
        {
          tabGroup: 'rejects',
          label: '%',
          key: 'rejects',
          precision: 1,
          cssClass: 'rejects'
        }
      ]
    }
  ];
  categoryProfileChartTooltipConfig: TooltipConfig = {
    title: { prefix: 'Size ', key: 'x', suffix: '' },
    width: 150,
    content: {
      showHeader: false,
      columns: [
        { key: 'y', unit: '%' }
      ]
    },
    precision: 1
  };
  gridRowTooltip: GridRowTooltip = {
    showEmpty: false,
    triggerColumns: ['bins_tipped_over_receipted', 'buffer_store_bins_tipped_over_receipted'],
    excludeRows: [ { key: 'level', value:  2 } ], // Exclude pack-date level
    dataColumns: [
      { key: 'bins_tipped', label: 'Bins Tipped' },
      { key: 'bins_receipted', label: 'Bins Receipted' },
      { key: 'buffer_store_bins_tipped', label: 'Bin Store Bins Tipped' },
      { key: 'buffer_store_bins_receipted', label: 'Bin Store Bins Receipted' }
    ]
  };
  rawData: RawRequestResultItemBase[] = [];
  rawMaBins: RawRequestResultItemBase[] = [];
  data = [];
  emptyPlaceholder = 'NA';
  isLoading = false;
  isRejectDataLoading = false;
  rejectsChartGroups: BarChartGroup[] = [];
  rejectsChartData: any[] = [];
  rejectChartAverages: ChartAverage[] = [];
  productionChartGroups: BarChartGroup[] = [];
  productionChartData: any[] = [];
  productionChartAverages: ChartAverage[] = [];
  profileChartData: LineChartDataItem[] = null;
  showTzgBySizeChart = false;
  tzgBySizeChartData: any[] = null;
  tzgBySizeChartColumns = { label: 'Size', valueLabel: 'TZG' };
  rejectsData = [];
  rejectsSampleSize = 0;
  rejectCategoriesChartData: { current: any, previous?: any } = null;
  hasPreviousSeasonRejects = true;
  rejectCategoryClassLookup = {};
  pieChartSize = 225;
  selectedGridRowIndexes = [];
  @ViewChild('categoryProfileChart', { static: false }) categoryProfileChart: LineChartComponent;
  availableSeasons: SeasonDropdownItem[] = [];
  selectedSeason: SeasonDropdownItem = null;
  previousSeason: SeasonDropdownItem = null;
  tzgUnavailableMessage = '';
  packoutReportLevelLabel: string;
  isPackoutReportButtonFlashing = false;
  packoutReportSummaryData: { label: string, value: any }[] = null;
  hideSidebarControls = false;
  selectedRow: any = null;
  errorMessage = 'Packout data not yet available.';
  private selectedLevel: string;
  private selectedId: number;
  private selectedGrowerNumber: number;
  private selectedVariety: string;
  private selectedGrowMethod: string;
  private packingCharges: PackingCharge[];
  private queryParamsSubscription: Subscription;
  private formatValuePipe = new FormatValuePipe();
  @HostBinding('class.no-data') hasNoData = true;
  @HostBinding('class.navigated-from-email') navigatedFromEmail = false;

  constructor(
    private http: HttpService,
    private activatedRoute: ActivatedRoute,
    private tutorialService: TutorialService,
    protected ga: GaService
  ) {
    super(ga);
  }

  async ngOnInit() {
    this.tutorialService.hideTutorialFooterButton();
    this.isLoading = true;
    this.availableSeasons = await this.getSeasons();

    if (this.availableSeasons?.length) {
      this.selectedSeason = this.availableSeasons[0];
      this.previousSeason = { label: this.selectedSeason.label - 1, value: this.selectedSeason.value - 1 };

      this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(params => {
        if (params.season) {
          const selectedSeason = Utils.findItem(this.availableSeasons, 'value', parseInt(params.season, 10));
          if (selectedSeason) {
            this.selectedSeason = selectedSeason;
          }
        }

        if (params.growernumber && params.variety && params.growMethod) {
          this.selectedGrowerNumber = params.growernumber;
          this.selectedVariety = params.variety;
          this.selectedGrowMethod = params.growMethod;
        }

        if (params.level) {
          this.selectedLevel = params.level;
        }

        if (params.id) {
          this.selectedId = parseInt(params.id, 10);
        }

        if (params.source === 'email') {
          this.navigatedFromEmail = true;
        }
      });

      this.init();
    } else {
      this.isLoading = false;
      this.hasNoData = true;
      this.data = [];
    }
  }

  ngOnDestroy() {
    this.queryParamsSubscription?.unsubscribe();
  }

  onTabSelect(event) {
    this.ga.event('tab', 'change', 'packout details variety gm tab', event.title);
    const newTab = this.tabs[event.index];
    if (newTab.varietyId !== this.selectedTab?.varietyId || newTab.growMethodId !== this.selectedTab?.growMethodId) {
      this.selectedTab = newTab;
      this.updateData();
      this.updateSelectedGridRowIndexes();
      setTimeout(() => {
        this.categoryProfileChart?.update();
      });
    }
  }

  onGridRowToggle() {
    this.ga.event('grid', 'change', 'packout details grid row toggle');
    this.categoryProfileChart.update();
  }

  openPackoutReport() {
    const url = `${ PACKOUT_URL }/${ this.selectedRow.product.toLowerCase() }/?${ this.getPackoutQueryString(this.selectedRow) }`;
    this.ga.event('button', 'click', 'packout details packout report download', url);
    Utils.openGrowerReport(url);
  }

  openScorecardReport() {
    const url = `${ SCORECARD_URL }/${ this.selectedRow.product.toLowerCase() }/?${ this.getScorecardQueryString(this.selectedRow) }`;
    this.ga.event('button', 'click', 'packout details scorecard download', url);
    Utils.openGrowerReport(url);
  }

  selectSeason(season: SeasonDropdownItem) {
    if (this.selectedSeason?.label !== season.label) {
      this.ga.event('dropdown', 'change', 'packout details season change', season.label);
      this.selectedSeason = season;
      this.init();
    }
  }

  private setTzgBySizeChartData(data) {
    this.showTzgBySizeChart = data.variety === 'GA' && data.level > 0 && this.selectedSeason.value !== 2020;
    if (this.showTzgBySizeChart) {
      this.tzgBySizeChartData = data.traysTzg.reduce((result, item) => {
        if (item.size < 42) {
          result.push({ label: item.size, value: item.tzg });
        }
        return result;
      }, []);
    } else {
      if (data.variety !== 'GA') {
        this.tzgUnavailableMessage = 'TZG by size is unavailable for this variety.';
      } else if (this.selectedSeason.value === 2020) {
        this.tzgUnavailableMessage = 'TZG is unavailable in the 2020 season.';
      } else {
        this.tzgUnavailableMessage = 'TZG by size is unavailable at the orchard level.';
      }
    }
  }

  private getPackoutQueryString(row: any): string {
    if (row.level === 0) {
      return `id=${ row.orchard_id }&type=orchard&variety=${ row.variety_id }&grow_method=${ row.grow_method_id }`;
    } else {
      return `id=${ row.maturity_area_id }&type=ma`;
    }
  }

  private getScorecardQueryString(row: any): string {
    return `orchard_id=${ row.orchard_id }&variety_id=${ row.variety_id }&grow_method_id=${ row.grow_method_id }`;
  }

  private async init() {
    [this.rawData, this.rawMaBins] = await this.getData();
    this.tabs = this.getTabs(this.rawData, this.rawMaBins);

    if (this.tabs?.length) {
      this.sortTabs();
      this.initSelectedTab();
      this.updateData();
      this.showColumns(this.selectedDataCategoryTab.title);
      this.updateSelectedGridRowIndexes();
    } else {
      this.hasNoData = true;
      this.data = [];
    }
  }

  private updateSelectedGridRowIndexes() {
    let selectedRowIndex = this.findSelectedRowIndex(this.data);
    if (selectedRowIndex == null) {
      selectedRowIndex = this.getFirstPackrunGridRowIndex();
    }

    if (selectedRowIndex != null) {
      this.selectedGridRowIndexes = [selectedRowIndex];
      this.hideSidebarControls = false;
      this.showTzgBySizeChart = true;
    } else {
      this.selectedGridRowIndexes = [];
      this.productionChartData = [];
      this.rejectsChartData = [];
      this.profileChartData = [];
      this.packoutReportSummaryData = [];
      this.tzgBySizeChartData = [];
      this.rejectsData = [];
      this.rejectCategoriesChartData = { current: null };
      this.hideSidebarControls = true;
      this.showTzgBySizeChart = false;
    }
  }

  protected findSelectedRow() {
    for (const item of this.rawData) {
      if (
        (
          !this.selectedLevel &&
          item.growernumber === this.selectedGrowerNumber &&
          item.variety === this.selectedVariety &&
          item.grow_method === this.selectedGrowMethod
        ) ||
        (this.selectedLevel === 'orchard' && item.orchard_id === this.selectedId) ||
        (this.selectedLevel === 'ma' && item.maturity_area_id === this.selectedId) ||
        (this.selectedLevel === 'packrun' && item.packrun_ids.split(',').indexOf(this.selectedId + '') > -1)
      ) {
        return item;
      }
    }
  }

  private findSelectedRowIndex(data: any[]) {
    for (let i = 0; i < this.data.length; i++) {
      const item = data[i];
      if (
        (
          !this.selectedLevel &&
          item.level === 2 &&
          item.growernumber === this.selectedGrowerNumber &&
          item.variety === this.selectedVariety &&
          item.grow_method === this.selectedGrowMethod
        ) ||
        (this.selectedLevel === 'orchard' && item.level === 0 && item.orchard_id === this.selectedId) ||
        (this.selectedLevel === 'ma' && item.level === 1 && item.maturity_area_id === this.selectedId) ||
        (this.selectedLevel === 'packrun' && item.level === 2 && item.packrun_ids.split(',').indexOf(this.selectedId + '') > -1)
      ) {
        return i;
      }
    }
  }

  private getFirstPackrunGridRowIndex() {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].level === 2) {
        return i;
      }
    }
  }

  protected selectGridRow(row) {
    if (this.selectedRow !== row) {
      if (this.selectedRow) {
        // Do not flash on initial load (too much change in UI)
        this.flashPackoutReportButton();
      }
      this.selectedRow = row;
      this.setPackoutReportLevelLabel(row);
      this.setProductionChartData(row);
      this.setRejectChartData(row);
      this.setChartAverages(row);
      this.setProfileChartData(row);
      this.setTzgBySizeChartData(row);
      this.setRejectSidebar(row);

      if (this.navigatedFromEmail) {
        this.setPackoutReportSummaryData(row);
      }
    }
  }

  private setPackoutReportSummaryData(row) {
    this.packoutReportSummaryData = [
      { label: 'Grower', value: row.growernumber },
      { label: 'Variety', value: row.variety },
      { label: 'Grow Method', value: row.grow_method }
    ];

    if (row.level === 1) {
      this.packoutReportSummaryData.splice(1, 0, { label: 'MA', value: row.maturity_area });
    }
  }

  private setPackoutReportLevelLabel(row) {
    this.packoutReportLevelLabel = row.level === 0 ? 'Orchard' : 'MA';
  }

  private setProfileChartData(data) {
    if (data.level === 0) {
      this.profileChartData = this.getProfileChartDataForOrchardLevel();
    } else if (data.level === 1) {
      this.profileChartData = this.getProfileChartDataForMaturityLevel(data.growernumber);
    } else if (data.level === 2) {
      this.profileChartData = this.getProfileChartDataForPackDateLevel(data.growernumber, data.maturity_area_id);
    }
  }

  private getProfileChartDataForOrchardLevel() {
    let profileChartData = [];
    let index = 0;
    this.data.forEach((item) => {
      if (item.level === 0) {
        profileChartData = profileChartData.concat(this.getProfileChartData(item, 'growernumber', index));
        index++;
      }
    });
    return profileChartData;
  }

  private getProfileChartDataForMaturityLevel(growernumber) {
    let profileChartData = [];
    let index = 0;
    this.data.forEach((item) => {
      if (item.level === 1 && item.growernumber === growernumber) {
        profileChartData = profileChartData.concat(this.getProfileChartData(item, 'maturity_area', index));
        index++;
      }
    });
    return profileChartData;
  }

  private getProfileChartDataForPackDateLevel(growernumber, maturityAreaId) {
    let profileChartData = [];
    let index = 0;
    this.data.forEach((item) => {
      if (item.level === 2 && item.growernumber === growernumber && item.maturity_area_id === maturityAreaId) {
        profileChartData = profileChartData.concat(this.getProfileChartData(item, 'pack_date', index));
        index++;
      }
    });
    return profileChartData;
  }

  private getProfileChartData(item, groupKey, index) {
    if (item.isNotSelectable) {
      return [];
    } else {
      return {
        label: item[groupKey],
        cssClass: 'item-' + index,
        values: this.availableSizes.map((size) => {
          return { x: size, y: item['percent_' + size] };
        })
      };
    }
  }

  private async setChartAverages(data) {
    const averages = await this.fetchAverages(this.selectedSeason.value, data.variety_id, data.grow_method_id);
    this.productionChartAverages = [];
    this.rejectChartAverages = [];
    this.productionChartAverages.push(this.getAverageC1TraysPerHaData());
    this.setRemoteProductionChartAverages(averages);
    this.setRejectChartAverages(averages);
  }

  private getAverageC1TraysPerHaData() {
    const averageC1TraysPerHa = this.getAverageC1TraysPerHa();
    return {
      label: `Your Avg: ${ this.number(averageC1TraysPerHa) }`,
      value: averageC1TraysPerHa,
      cssClass: 'externally-computed',
      addToLegend: true
    };
  }

  private setRejectChartAverages(averages) {
    if (averages.non_class_1_percentage) {
      this.rejectChartAverages.push(
        {
          label: `Trevelyan's Avg. Non Class 1: ${ this.number(averages.non_class_1_percentage) }%`,
          value: averages.non_class_1_percentage
        }
      );
    }

    if (averages.rejects_percentage) {
      this.rejectChartAverages.push(
        {
          label: `Trevelyan's Avg. Chargeable Rejects: ${ this.number(averages.rejects_percentage) }%`,
          value: averages.rejects_percentage
        }
      );
    }
  }

  private setRemoteProductionChartAverages(averages) {
    if (averages.class_1_trays_per_ha) {
      this.productionChartAverages.push(
        {
          label: `Trevelyan's Avg: ${ this.number(averages.class_1_trays_per_ha) }`,
          value: averages.class_1_trays_per_ha
        }
      );
    }
  }

  private number(value = 0, precision = 1, defaultValue = 0): string {
    return formatNumber(value || defaultValue, 'en-NZ', `1.${ precision }-${ precision }`);
  }

  private async fetchAverages(season: number, varietyId: number, growMethodId: number): Promise<any> {
    const params: any = {
      season: season,
      variety: varietyId,
      grow_method: growMethodId
    };
    return this.http.get(AVERAGES_ENDPOINT, { params: params }).toPromise();
  }

  private setProductionChartData(data) {
    this.productionChartGroups = [
      { label: 'Season', primary: 'season', secondary: 'group', isSelected: true }
    ];

    let productionChartData;

    if (data.level === 0) {
      productionChartData = this.getProductionChartDataForOrchardLevel();
    } else if (data.level === 1 || data.level === 2) {
      productionChartData = this.getProductionChartDataForMaturityLevel(data.growernumber);
    }

    this.productionChartData = productionChartData;
  }

  private getProductionChartDataForOrchardLevel() {
    let productionChartData = [];
    this.data.forEach((item) => {
      if (item.level === 0) {
        productionChartData = productionChartData.concat(this.getProductionChartData(item, 'growernumber'));
      }
    });
    return productionChartData;
  }

  private getProductionChartDataForMaturityLevel(growernumber) {
    let productionChartData = [];
    this.data.forEach((item) => {
      if (item.level === 1 && item.growernumber === growernumber) {
        productionChartData = productionChartData.concat(this.getProductionChartData(item, 'maturity_area'));
      }
    });
    return productionChartData;
  }

  private getProductionChartData(item, groupKey) {
    if (item.isNotSelectable) {
      return [];
    } else {
      return {
        season: this.selectedSeason.value,
        group: item[groupKey],
        value: item.producing_ha ? item.c1TraysTotal / item.producing_ha : 0,
        exclude: !item.ma_area_confirmed,
        excludeMessage: UNCONFIRMED_PRODUCING_HA_MESSAGE
      };
    }
  }

  private setRejectChartData(data) {
    this.rejectsChartGroups = [
      { label: 'Group', primary: 'group', secondary: 'type', isSelected: true }
    ];

    let rejectsChartData;

    if (data.level === 0) {
      rejectsChartData = this.getRejectChartDataForOrchardLevel();
    } else if (data.level === 1) {
      rejectsChartData = this.getRejectChartDataForMaturityLevel(data.growernumber);
    } else if (data.level === 2) {
      rejectsChartData = this.getRejectChartDataForPackDateLevel(data.growernumber, data.maturity_area_id);
    }

    this.rejectsChartData = this.orderRejectChartData(rejectsChartData);
  }

  private orderRejectChartData(data): any[] {
    let orderedData = [];
    const orderedTypes = ['Class 2', 'NSS', 'Low DM', 'Class 3', 'Undersize', 'Rejects'];
    orderedTypes.forEach((type) => {
      const items = Utils.findItems(data, 'type', type);
      orderedData = orderedData.concat(items);
    });
    return orderedData;
  }

  private getRejectChartDataForOrchardLevel() {
    let rejectsChartData = [];
    this.data.forEach((item) => {
      if (item.level === 0) {
        rejectsChartData = rejectsChartData.concat(this.getRejectChartData(item, 'growernumber'));
      }
    });
    return rejectsChartData;
  }

  private getRejectChartDataForMaturityLevel(growernumber) {
    let rejectsChartData = [];
    this.data.forEach((item) => {
      if (item.level === 1 && item.growernumber === growernumber) {
        rejectsChartData = rejectsChartData.concat(this.getRejectChartData(item, 'maturity_area'));
        }
    });
    return rejectsChartData;
  }

  private getRejectChartDataForPackDateLevel(growernumber, maturityAreaId) {
    let rejectsChartData = [];
    this.data.forEach((item) => {
      if (item.level === 2 && item.growernumber === growernumber && item.maturity_area_id === maturityAreaId) {
        rejectsChartData = rejectsChartData.concat(this.getRejectChartData(item, 'pack_date'));
      }
    });
    return rejectsChartData;
  }

  private getRejectChartData(item, groupKey) {
    const data = [];
    REJECT_CHART_FIELDS.forEach((field) => {
      data.push({
        group: item[groupKey],
        type: field.label,
        value: item[field.key]
      });
    });
    return data;
  }

  private updateData() {
    let data = this.filterData(this.rawData, this.selectedTab.varietyId, this.selectedTab.growMethodId);
    const maBins = this.filterData(this.rawMaBins, this.selectedTab.varietyId, this.selectedTab.growMethodId);
    this.selectedRow = null;

    if (data.length || maBins.length) {
      this.hasNoData = false;
      if (data.length) {
        this.availableSizes = this.getAvailableSizes(data[0]);
        this.createGridSizeColumns();
        data = this.transformData(data);
      }
      this.updateMaAreaConfirmedDependents(data);
      this.mergeMaBufferStoreData(data, maBins);
      this.mergeOrchardBufferStoreData(data);
      this.data = this.flattenChildren(data);
      this.orderData(this.data);
    } else {
      this.hasNoData = true;
      this.data = [];
    }
  }

  private updateMaAreaConfirmedDependents(data: any) {
    data.forEach((orchard) => {
      orchard.children.forEach((ma) => {
        ma.children.forEach((packDate) => {
          packDate.dry_matter_weight_total = '';
          packDate.dry_matter_weight_per_ha = '';
          packDate.confirmed_producing_ha = '';
        });
        this.updateMaAreaConfirmedDependent(ma);
      });
      this.updateMaAreaConfirmedDependent(orchard);
    });
  }

  private updateMaAreaConfirmedDependent(item: any) {
    item.confirmed_producing_ha = item.ma_area_confirmed ? item.producing_ha : '?';
    item.dry_matter_weight_total = item.totalDryMatterWeight / 1000;
    if (item.ma_area_confirmed) {
      item.dry_matter_weight_per_ha = item.dry_matter_weight_total / item.producing_ha;
    } else {
      item.dry_matter_weight_per_ha = '?';
      item.packing_cost_per_ha = '?';
    }
  }

  private createGridSizeColumns() {
    this.removeProfileColumns();
    const sizeCount = this.availableSizes.length;
    this.availableSizes.forEach((size, index) => {
      let cssClass = 'two-line-height size-col-' + sizeCount;
      let childCssClass = 'size-col-' + sizeCount;
      if (index === 0) {
        cssClass = 'divider ' + cssClass;
        childCssClass = 'divider ' + childCssClass;
      }
      const sizeGridColumn = {
        tabGroup: 'profile',
        label: '' + size,
        cssClass: cssClass,
        noEvenOddShading: true,
        childColumns: [
          {
            tabGroup: 'profile',
            label: '%', key: 'percent_' + size,
            precision: 1,
            cssClass: childCssClass,
            hasHeatMap: true
          }
        ]
      };
      this.gridColumns.push(sizeGridColumn);
    });
  }

  private removeProfileColumns() {
    this.gridColumns = this.gridColumns.filter((column) => {
      return column.tabGroup !== 'profile';
    });
  }

  private getAvailableSizes(row) {
    return row.trays.reduce((result, tray) => {
      if (Utils.isSizeClass1(row.variety, tray.size, this.selectedSeason.value)) {
        result.push(tray.size);
      }
      return result;
    }, []);
  }

  private getTabs(data: any[], maBins: RawRequestResultItemBase[]): any[] {
    const dataTabs = (data || []).map((item) => {
      return { title: item.variety + item.grow_method, varietyId: item.variety_id, growMethodId: item.grow_method_id };
    });
    const maBinsTabs = (maBins || []).map((item) => {
      return { title: item.variety + item.grow_method, varietyId: item.variety_id, growMethodId: item.grow_method_id };
    });
    return Utils.uniqueObjectArrayByKey(dataTabs.concat(maBinsTabs), 'title');
  }

  protected showColumns(category: string) {
    switch (category) {
      case 'Rejects': return this.showRejectColumns();
      case 'Profile': return this.showProfileColumns();
      case 'Key Markets': return this.showKeyMarketsColumns();
    }
  }

  private showRejectColumns() {
    this.hiddenColumns = ['profile'];
  }

  private showProfileColumns() {
    this.hiddenColumns = ['rejects'];
  }

  private showKeyMarketsColumns() {
    this.hiddenColumns = ['key-markets'];
  }

  private flattenChildren(data, parent = null, flatData = [], level = 0) {
    data.forEach((item) => {
      item.level = level;
      item.parent = parent;
      item.isHidden = level > 0;

      if (level === Level.Orchard) {
        item.maturity_area = '';
        item.maturity_area_id = null;
        item.pack_date = '';
      } else if (level === Level.Ma) {
        item.pack_date = '';
      } else {
        item.pack_date = this.formatPackDate(item.pack_date);
      }

      item.groupColumnValue = this.getGroupColumnValue(item, level);
      flatData.push(item);
      if (item.children) {
        this.flattenChildren(item.children, item, flatData, level + 1);
      }
    });
    return flatData;
  }

  private formatPackDate(source: string) {
    return Utils.formatPackOutDate(source);
  }

  private getGroupColumnValue(row, level) {
    switch (level) {
      case 0: return row.growernumber;
      case 1: return row.maturity_area;
      case 2: return row.pack_date;
      default: return null;
    }
  }

  private async getPackingChargesForSeason(): Promise<PackingCharge[]> {
    return await this.fetchData(PACKING_CHARGES_ENDPOINT);
  }

  private async getSeasons(): Promise<SeasonDropdownItem[]> {
    const data = await this.fetchSeasons();

    return data.map((item: { season: number }) => {
      return { label: item.season, value: item.season };
    });
  }

  private async getData(): Promise<[any[], any[]]> {
    let data = [];
    let rawMaBins = [];
    this.isLoading = true;
    const result: RawRequestResult = await this.fetchData();

    if (result) {
      if (result.maturity_area_bins?.length) {
        rawMaBins = result.maturity_area_bins.sort((a, b) => a.orchard_id - b.orchard_id);
      }
      if (result.pack_dates?.length) {
        data = result.pack_dates;
        this.packingCharges = await this.getPackingChargesForSeason();
      }
    }

    this.isLoading = false;
    return [data, rawMaBins];
  }

  // Data is ordered by: grower #, ma and pack date
  private orderData(data) {
    data.sort((a, b) => {
      const growerNumberResult = a.growernumber.localeCompare(b.growernumber);
      if (growerNumberResult !== 0) {
        return growerNumberResult;
      }

      const maturityResult = a.maturity_area.localeCompare(b.maturity_area);
      if (maturityResult !== 0) {
        return maturityResult;
      }

      return a.pack_date_in_ms - b.pack_date_in_ms;
    });
  }

  private fetchSeasons(): Promise<any> {
    return this.http.get(PRODUCTION_SEASONS_ENDPOINT).toPromise();
  }

  private async fetchData(url = PRODUCTION_REPORT_ENDPOINT): Promise<any> {
    const params: any = { season: this.selectedSeason.value };
    return this.http.get(url, { params: params }).toPromise();
  }

  private transformData(data) {
    const orchards =  data.reduce((result, item) => {
      this.aggregate(result, item);
      return result;
    }, []);

    orchards.forEach((orchard) => {
      orchard.packing_cost_per_tray = this.calculateOrchardPackingCostPerTray(orchard.children);
      orchard.producing_ha = this.objectArraySum(orchard.children, 'producing_ha');
      orchard.packing_cost_per_ha = this.calculatePackingCostPerHa(
        orchard.packing_cost_per_tray,
        orchard.c1_trays_total,
        orchard.producing_ha
      );
    });

    return orchards;
  }

  private aggregate(data, row) {
    const existingRow = Utils.findItem(data, 'growernumber', row.growernumber);

    if (existingRow) {
      this.aggregateRow(existingRow, row, 'orchard');
      const existingMaturityAreaRow = Utils.findItem(existingRow.children, 'maturity_area_id', row.maturity_area_id);

      if (existingMaturityAreaRow) {
        this.aggregateRow(existingMaturityAreaRow, row, 'maturity_area');
        existingMaturityAreaRow.children.push(this.createAggregateObject(row, 'packrun'));
      } else {
        this.addNewMaturityAreaAggregateRow(existingRow.children, row);
      }
    } else {
      this.addNewOrchardAggregateRow(data, row);
    }
  }

  private addNewOrchardAggregateRow(collection, item) {
    const aggregateObjectGrowerNumber = this.createAggregateObject(item, 'orchard');
    const aggregateObjectPackDate = this.cloneAggregateObject(aggregateObjectGrowerNumber);
    const aggregateObjectMaturityArea = this.cloneAggregateObject(aggregateObjectGrowerNumber);
    aggregateObjectPackDate.packing_cost_per_tray = '';
    aggregateObjectPackDate.packing_cost_per_ha = '';
    aggregateObjectMaturityArea.children = [aggregateObjectPackDate];
    aggregateObjectGrowerNumber.children = [aggregateObjectMaturityArea];
    collection.push(aggregateObjectGrowerNumber);
  }

  private addNewMaturityAreaAggregateRow(collection, item) {
    const aggregateObjectGrowerNumber = this.createAggregateObject(item, 'maturity_area');
    const aggregateObjectPackDate = Object.assign({}, aggregateObjectGrowerNumber);
    aggregateObjectPackDate.packing_cost_per_tray = '';
    aggregateObjectPackDate.packing_cost_per_ha = '';
    aggregateObjectGrowerNumber.children = [aggregateObjectPackDate];
    collection.push(aggregateObjectGrowerNumber);
  }

  private createAggregateObject(item, type: string): any {
    const c1TraysTotal = this.objectArraySum(item.trays, 'class_1');
    const c2TraysTotal = this.objectArraySum(item.trays, 'class_2');
    const totalWeight = this.objectValueSum(item.weights);
    const sizeTraysTotal = this.sizeTraysSum(item.trays);
    const chargeableTotal = this.rejectsUndersizeClass3LowDryMatterSum(item.weights, item.nir_used);
    const chargeableRejects = 100 * chargeableTotal / totalWeight;
    const weights = Object.assign({}, item.weights);
    const traysClass1 = item.trays.reduce((result, tray) => {
      result[tray.size] = tray.class_1;
      return result;
    }, {});
    const traysTzg = item.trays.map((tray) => {
      return { size: tray.size, tzg: tray.tzg };
    });
    const totalDryMatterWeight = item.dry_matter_weight;
    const aggregate = {
      growernumber: item.growernumber,
      orchard_id: item.orchard_id,
      variety: item.variety,
      variety_id: item.variety_id,
      grow_method: item.grow_method,
      grow_method_id: item.grow_method_id,
      maturity_area_id: item.maturity_area_id,
      maturity_area: item.maturity_area,
      producing_ha: item.producing_ha,
      packrun_ids: item.packrun_ids,
      pack_date: item.pack_date,
      bins_tipped: item.bins_tipped,
      ma_area_confirmed: item.ma_area_confirmed,
      c1_trays_total: c1TraysTotal,
      c1_trays_per_bin:  c1TraysTotal / item.bins_tipped,
      c1_trays_percent: 100 * weights.class_1 / totalWeight,
      size: sizeTraysTotal / c1TraysTotal,
      actual_tzg: this.selectedSeason.value === 2020 ? null : item.actual_tzg,
      c2_trays_total: c2TraysTotal,
      c2_trays_percent: 100 * weights.class_2 / totalWeight,
      chargeable_rejects: chargeableRejects,
      rejects: 100 * weights.rejects / totalWeight,
      undersize: 100 * weights.undersize / totalWeight,
      low_dry_matter: 100 * weights.low_dry_matter / totalWeight,
      nss: 100 * weights.nss / totalWeight,
      c3_percent: 100 * weights.class_3 / totalWeight,
      c1TraysTotal: c1TraysTotal,
      c2TraysTotal: c2TraysTotal,
      totalWeight: totalWeight,
      sizeTraysTotal: sizeTraysTotal,
      chargeableTotal: chargeableTotal,
      weights: weights,
      traysClass1: traysClass1,
      traysTzg: traysTzg,
      nirUsed: item.nir_used,
      totalDryMatterWeight: totalDryMatterWeight,
      product: item.product,
    };

    if (type === 'packrun') {
      aggregate['packing_cost_per_tray'] = '';
      aggregate['packing_cost_per_ha'] = '';
    } else {
      const packingCostPerTray = this.calculatePackingCostPerTray(item.variety, item.grow_method, chargeableRejects, item.nir_used);
      const packingCostPerHa = this.calculatePackingCostPerHa(packingCostPerTray, c1TraysTotal, item.producing_ha);
      aggregate['packing_cost_per_tray'] = packingCostPerTray;
      aggregate['packing_cost_per_ha'] = packingCostPerHa;
      aggregate['packing_cost_per_tray'] = packingCostPerTray;
    }

    let maxPercentSize = 0;
    this.availableSizes.forEach((size) => {
      const percentSize = 100 * traysClass1[size] / c1TraysTotal;
      if (maxPercentSize < percentSize) {
        maxPercentSize = percentSize;
      }
      aggregate['percent_' + size] = percentSize;
    });
    aggregate['heat_map_max'] = maxPercentSize;

    return aggregate;
  }

  private aggregateRow(row, item, type: string) {
    row.c1TraysTotal += this.class1TraysSum(item);
    row.c2TraysTotal += this.objectArraySum(item.trays, 'class_2');
    row.totalWeight += this.objectValueSum(item.weights);
    row.sizeTraysTotal += this.sizeTraysSum(item.trays);
    row.chargeableTotal += this.rejectsUndersizeClass3LowDryMatterSum(item.weights, item.nir_used);

    row.weights.class_1 += item.weights.class_1;
    row.weights.class_2 += item.weights.class_2;
    row.weights.rejects += item.weights.rejects;
    row.weights.undersize += item.weights.undersize;
    row.weights.low_dry_matter += item.weights.low_dry_matter;
    row.weights.nss += item.weights.nss;
    row.weights.class_3 += item.weights.class_3;
    row.bins_tipped += item.bins_tipped;
    row.ma_area_confirmed = row.ma_area_confirmed ? item.ma_area_confirmed : false;

    row.c1_trays_total = row.c1TraysTotal;
    row.c1_trays_per_bin = row.c1_trays_total / row.bins_tipped;
    row.c1_trays_percent = 100 * row.weights.class_1 / row.totalWeight;
    row.size = row.sizeTraysTotal / row.c1TraysTotal;
    row.actual_tzg = type === 'orchard' ? '' : item.actual_tzg;
    row.c2_trays_total = row.c2TraysTotal;
    row.c2_trays_percent = 100 * row.weights.class_2 / row.totalWeight;
    row.chargeable_rejects = 100 * row.chargeableTotal / row.totalWeight;
    row.rejects = 100 * row.weights.rejects / row.totalWeight;
    row.undersize = 100 * row.weights.undersize / row.totalWeight;
    row.low_dry_matter = 100 * row.weights.low_dry_matter / row.totalWeight;
    row.nss = 100 * row.weights.nss / row.totalWeight;
    row.c3_percent = 100 * row.weights.class_3 / row.totalWeight;

    row.totalDryMatterWeight += item.dry_matter_weight;

    if (type === 'maturity_area') {
      row.packing_cost_per_tray = this.calculatePackingCostPerTray(row.variety, row.grow_method, row.chargeable_rejects, row.nirUsed);
      row.packing_cost_per_ha = this.calculatePackingCostPerHa(row.packing_cost_per_tray, row.c1_trays_total, row.producing_ha);
    } else {
      row.packing_cost_per_tray = '';
      row.packing_cost_per_ha = '';
    }

    this.aggregateTraysClass1(row.traysClass1, item.trays);
    let maxPercentSize = 0;
    this.availableSizes.forEach((size) => {
      const percentSize = 100 * row.traysClass1[size] / row.c1TraysTotal;
      if (maxPercentSize < percentSize) {
        maxPercentSize = percentSize;
      }
      row['percent_' + size] = 100 * row.traysClass1[size] / row.c1TraysTotal;
    });
    row['heat_map_max'] = maxPercentSize;
  }

  private calculateOrchardPackingCostPerTray(maturityAreas) {
    let numerator = 0;
    let denominator = 0;
    maturityAreas.forEach((maturityArea) => {
      numerator += maturityArea.c1_trays_total * (maturityArea.packing_cost_per_tray || 0);
      denominator += maturityArea.c1_trays_total;
    });
    return numerator / (denominator || 1);
  }

  private calculatePackingCostPerTray(variety: string, growMethod: string, chargeableRejects: number, nirUsed: boolean) {
    let packingCostPerTray = null;
    const packingCharge = this.packingCharges.find((item) => {
      return item.variety_code === variety && item.grow_method_code === growMethod;
    });

    if (!packingCharge) {
      return packingCostPerTray;
    }

    packingCostPerTray = packingCharge.base_rate;
    const topBracket = packingCharge.rejects_second_bracket_limit;
    const middleBracket = packingCharge.rejects_first_bracket_limit;

    if (chargeableRejects > topBracket) {
      packingCostPerTray += (chargeableRejects - topBracket) * packingCharge.rejects_third_bracket_rate;
      chargeableRejects = topBracket;
    }
    if (chargeableRejects > middleBracket) {
      packingCostPerTray += (chargeableRejects - middleBracket) * packingCharge.rejects_second_bracket_rate;
      chargeableRejects = middleBracket;
    }
    packingCostPerTray += (chargeableRejects) * packingCharge.rejects_first_bracket_rate;

    if (nirUsed && packingCharge.nir_rate) {
      packingCostPerTray += packingCharge.nir_rate;
    }

    return packingCostPerTray;
  }

  private calculatePackingCostPerHa(packingCostPerTray: number, c1TraysTotal: number, producingHa: number) {
    return (packingCostPerTray * c1TraysTotal) / (producingHa || 1);
  }

  private aggregateTraysClass1(collection, itemTrays) {
    itemTrays.forEach((tray) => {
      collection[tray.size] += tray.class_1;
    });
  }

  private cloneAggregateObject(source) {
    const clone = Object.assign({}, source);
    clone.weights = Object.assign({}, source.weights);
    clone.traysClass1 = Object.assign({}, source.traysClass1);
    return clone;
  }

  private rejectsUndersizeClass3LowDryMatterSum(weights, isNirUsed) {
    let chargeableRejects = weights.rejects + weights.undersize + weights.class_3;
    if (isNirUsed && !(this.selectedSeason.value in [2023])) {
      chargeableRejects += weights.low_dry_matter;
    } else {
      chargeableRejects += (weights.low_dry_matter / 2);
    }
    return chargeableRejects;
  }

  // Excludes NSS
  private class1TraysSum(row) {
    return row.trays.reduce((result, tray) => {
      if (Utils.isSizeClass1(row.variety, tray.size, this.selectedSeason.value)) {
        result += tray.class_1;
      }
      return result;
    }, 0);
  }

  private sizeTraysSum(trays) {
    return trays.reduce((result, tray) => {
      return result + (tray.size * tray.class_1);
    }, 0);
  }

  private objectValueSum(data) {
    return Object.keys(data).reduce((result, key) => {
      return result + data[key];
    }, 0);
  }

  private objectArraySum(data, key) {
    return data.reduce((result, item) => {
      return result + item[key];
    }, 0);
  }

  private async setRejectSidebar(row) {
    try {
      const data = await this.getRejectData(row);
      if (data) {
        this.rejectCategoriesChartData = this.getRejectCategoriesChartData(data);
        this.setRejectCategoryClassLookup(this.rejectCategoriesChartData.current['data']);
        this.classifyRejectData(data);
        this.rejectsSampleSize = this.getRejectSampleSize(data);
        this.rejectsData = data;
      }
    } catch (error) {
      // We need a graceful way of handling promise rejection errors thrown by await
      // For now re-throwing the error to be dealt with by the global handler
      console.log('-----> ERROR: ', error);
      throw(error);
    }
  }

  private setRejectCategoryClassLookup(data) {
    this.rejectCategoryClassLookup = data.reduce((result, item) => {
      result[item.id] = item.cssClass;
      return result;
    }, {});
  }

  private classifyRejectData(data) {
    data.forEach((item) => {
      item.cssClass = this.rejectCategoryClassLookup[item.category_id];
    });
  }

  private getRejectSampleSize(data) {
    return data.reduce((result, item) => {
      return result + item.current.reject_sum;
    }, 0);
  }

  private async getRejectData(row) {
    this.isRejectDataLoading = true;
    const season = this.selectedSeason.value;
    const level = this.getLevelName(row.level);
    const packrunIds = this.getPackrunIds(row);
    const data = await this.fetchRejectData(season, level, packrunIds);
    this.isRejectDataLoading = false;
    return data;
  }

  private async fetchRejectData(season: number, level: 'packrun'|'ma'|'orchard', packrunIds: string): Promise<any> {
    const params: any = { season: season, level: level, packrun_ids: packrunIds };
    return this.http.get(PRODUCTION_REJECT_ENDPOINT, { params: params }).toPromise();
  }

  private getLevelName(levelId: number): 'packrun'|'ma'|'orchard' {
    switch (levelId) {
      case 0: return 'orchard';
      case 1: return 'ma';
      case 2: return 'packrun';
    }
  }

  private getPackrunIds(data, packruns = []) {
    if (data.children) {
      data.children.forEach((child) => {
        this.getPackrunIds(child, packruns);
      });
    } else if (data.packrun_ids != null) {
      packruns.push(data.packrun_ids);
    }
    return packruns.join(',');
  }

  private getRejectCategoriesChartData(data) {
    const currentData = [];
    const previousData = [];
    let totalCurrent = 0;
    let totalPrevious = 0;

    const aggregatedData = data.reduce((aggregate, item) => {
      const existing = aggregate[item.category_id];
      totalCurrent += item.current.reject_sum;
      totalPrevious += item.previous.reject_sum;
      if (existing) {
        existing.current += item.current.reject_sum;
        existing.previous += item.previous.reject_sum;
      } else {
        aggregate[item.category_id] = {
          category: item.category,
          current: item.current.reject_sum || 0,
          previous: item.previous.reject_sum || 0
        };
      }
      return aggregate;
    }, {});

    this.hasPreviousSeasonRejects = totalPrevious > 0;

    Object.keys(aggregatedData).forEach((key, index) => {
      const item = aggregatedData[key];
      currentData.push({
        id: key,
        label: item.category,
        value: 100 * item.current / totalCurrent,
        cssClass: 'item-' + index
      });

      if (this.hasPreviousSeasonRejects) {
        previousData.push({
          id: key,
          label: item.category,
          value: 100 * item.previous / totalPrevious,
          cssClass: 'item-' + index
        });
      }
    });

    this.sortByDescValue(currentData);
    const result = {
      current: { label: this.selectedSeason.value, data: currentData },
      previous: null
    };

    if (this.hasPreviousSeasonRejects) {
      this.sortByDescValue(previousData);
      result.previous = { label: this.previousSeason.label, data: previousData };
    }

    return result;
  }

  private sortByDescValue(data) {
    data?.sort((a, b) => {
      return b.value - a.value;
    });
  }

  private flashPackoutReportButton() {
    this.isPackoutReportButtonFlashing = true;
    setTimeout(() => {
      this.isPackoutReportButtonFlashing = false;
    }, 1500);
  }

  private getAverageC1TraysPerHa(): number {
    if (!this.selectedRow) {
      return 0;
    }

    const level = this.selectedRow.level === 0 ? 0 : 1;
    const [sumC1Trays, sumConfirmedProducingHa] = this.data.reduce((result, item) => {
      if ((level === 0 && item.level === 0) || (level === 1 && item.level === 1 && item.growernumber === this.selectedRow.growernumber)) {
        result[0] += item.c1TraysTotal;
        result[1] += item.confirmed_producing_ha;
      }
      return result;
    }, [0, 0]);
    return sumConfirmedProducingHa ? (sumC1Trays / sumConfirmedProducingHa) : 0;
  }

  private mergeMaBufferStoreData(data: any[], maBins: RawRequestResultItemBase[], level = 0, usedMaBinKeys: string[] = []) {
    if (!maBins?.length) {
      return;
    }

    data.forEach((item) => {
      if (level === Level.Orchard) {
        this.mergeMaBufferStoreData(item.children, maBins, Level.Ma, usedMaBinKeys);
        this.mergeReceiptedButUnpackedDataToOrchard(item, maBins, usedMaBinKeys);
      } else if (level === Level.Ma) {
        const maBin = this.findMaBin(maBins, item.orchard_id, item.maturity_area_id, item.grow_method_id, item.variety_id);
        if (maBin) {
          usedMaBinKeys.push([item.orchard_id, item.maturity_area_id, item.grow_method_id, item.variety_id].join(''));
          this.updateMaLevelBufferStoreData(item, maBin);
          this.mergeMaBufferStoreData(item.children, maBins, Level.PackDate, usedMaBinKeys);
        }
      } else {
        this.updatePackDateLevelBufferStoreData(item);
      }
    });

    if (level === Level.Orchard) {
      // Merge in orchards with nothing packed
      this.mergeReceiptedButUnpackedDataToNewOrchard(data, maBins, usedMaBinKeys);
    }
  }

  private mergeReceiptedButUnpackedDataToOrchard(orchard: any, maBins: RawRequestResultItemBase[], usedMaBinKeys: string[]) {
    maBins.forEach((bin) => {
      const binKey = [bin.orchard_id, bin.maturity_area_id, bin.grow_method_id, bin.variety_id].join('');
      if (!usedMaBinKeys.includes(binKey)) {
        if (bin.orchard_id === orchard.orchard_id &&
          bin.grow_method_id === orchard.grow_method_id &&
          bin.variety_id === orchard.variety_id) {
          this.addDataItemFromMaBin(orchard, bin);
          usedMaBinKeys.push(binKey);
        }
      }
    });
  }

  private mergeReceiptedButUnpackedDataToNewOrchard(data: any[], maBins: RawRequestResultItemBase[], usedMaBinKeys: string[]) {
    let orchard;
    maBins.forEach((bin) => {
      const binKey = [bin.orchard_id, bin.maturity_area_id, bin.grow_method_id, bin.variety_id].join('');
      if (!usedMaBinKeys.includes(binKey)) {
        // Note: this only works because maBins are sorted by orchard_id.
        if (orchard?.orchard_id !== bin.orchard_id) {
          orchard = this.createOrchardFromMaBin(bin);
          data.push(orchard);
        }
        this.addDataItemFromMaBin(orchard, bin);
      }
    });
  }

  private createOrchardFromMaBin(maBins: any): any {
    return {
      isNotSelectable: true,
      orchard_id: maBins.orchard_id,
      growernumber: maBins.growernumber,
      grow_method_id: maBins.grow_method_id,
      variety_id: maBins.variety_id,
      children: []
    };
  }

  private addDataItemFromMaBin(orchard: any, maBin: any) {
    const dataItem = Object.assign({}, maBin);
    dataItem.isNotSelectable = true;
    dataItem.maturity_area = dataItem.maturity_area_name;
    this.updateMaLevelBufferStoreData(dataItem, dataItem);
    orchard.children.push(dataItem);
  }

  private updateMaLevelBufferStoreData(item: any, maBin: RawRequestResultItemBase) {
    item.bins_tipped_over_receipted = this.getBinsTippedOverReceipted(maBin);
    item.buffer_store_bins_tipped_over_receipted = this.getBufferStoreBinsTippedOverReceipted(maBin);
    item.bins_receipted = maBin.bins_receipted;
    item.bins_tipped = maBin.bins_tipped;
    item.buffer_store_bins_receipted = maBin.buffer_store_bins_receipted;
    item.buffer_store_bins_tipped = maBin.buffer_store_bins_tipped;
  }

  private updatePackDateLevelBufferStoreData(item: any) {
    item.bins_tipped_over_receipted = item.bins_tipped;
  }

  private getBinsTippedOverReceipted(maBin: RawRequestResultItemBase): string {
    const binsTipped = this.formatNumber(maBin.bins_tipped, true);
    const binsReceipted = this.formatNumber(maBin.bins_receipted, false);
    return `${ binsTipped } / ${ binsReceipted }`;
  }

  private getBufferStoreBinsTippedOverReceipted(maBin: RawRequestResultItemBase): string {
    if (maBin.buffer_store_bins_receipted) {
      const bufferStoreBinsTipped = this.formatNumber(maBin.buffer_store_bins_tipped, false);
      const bufferStoreBinsReceipted = this.formatNumber(maBin.buffer_store_bins_receipted, false);
      return `${ bufferStoreBinsTipped } / ${ bufferStoreBinsReceipted }`;
    } else {
      return null;
    }
  }

  private formatNumber(value: number, allowZero: boolean): string {
    if (allowZero || value) {
      return this.formatValuePipe.transform(value, '', 0, this.emptyPlaceholder);
    } else {
      return this.emptyPlaceholder;
    }
  }

  private findMaBin(
    maBins: RawRequestResultItemBase[],
    orchardId: number,
    maturityAreaId: number,
    growMethodId: number,
    varietyId: number
  ): RawRequestResultItemBase {
    return maBins.find((item) => {
      return item.orchard_id === orchardId &&
        item.maturity_area_id === maturityAreaId &&
        item.grow_method_id === growMethodId &&
        item.variety_id === varietyId;
    });
  }

  private mergeOrchardBufferStoreData(orchardData) {
    (orchardData || []).forEach((orchard) => {
      let binsReceipted: string | number = 0;
      let binsTipped: string | number = 0;
      let bufferStoreBinsReceipted: string | number = 0;
      let bufferStoreBinsTipped: string | number = 0;
      (orchard.children || []).forEach((ma) => {
        binsReceipted += ma.bins_receipted;
        binsTipped += ma.bins_tipped;
        bufferStoreBinsReceipted += ma.buffer_store_bins_receipted;
        bufferStoreBinsTipped += ma.buffer_store_bins_tipped;
      });

      binsTipped = this.formatNumber(binsTipped, true);
      binsReceipted = this.formatNumber(binsReceipted, false);
      bufferStoreBinsTipped = this.formatNumber(bufferStoreBinsTipped, false);
      bufferStoreBinsReceipted = this.formatNumber(bufferStoreBinsReceipted, false);

      orchard.bins_receipted = binsReceipted;
      orchard.bins_tipped = binsTipped;
      orchard.buffer_store_bins_receipted = bufferStoreBinsReceipted;
      orchard.buffer_store_bins_tipped = bufferStoreBinsTipped;

      orchard.bins_tipped_over_receipted = `${ binsTipped } / ${ binsReceipted }`;
      if (bufferStoreBinsReceipted !== this.emptyPlaceholder) {
        orchard.buffer_store_bins_tipped_over_receipted = `${ bufferStoreBinsTipped } / ${ bufferStoreBinsReceipted }`;
      }
    });
  }
}
