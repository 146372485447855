import {
  Component,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  OnInit,
  Input, HostBinding
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DocumentLibraryService } from './document-library.service';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { State } from '@progress/kendo-data-query';
import { AuthService } from 'app/shared/services/auth.service';
import { DialogRef, DialogService, DialogSettings } from '@progress/kendo-angular-dialog';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SettingsService } from 'app/shared/services/settings.service';
import { environment } from 'environments/environment';
import { Utils } from 'app/shared/utils';
import { HttpService } from 'app/shared/services/http.service';
import { UserProductsService } from 'app/shared/services/user-products.service';
import { TutorialService } from 'app/shared/services/tutorial.service';
import { GaService } from 'app/shared/services/ga.service';

interface SeasonOptions {
  orchard_season: string;
}
interface GrowerNumberOptions {
  grower_number: string;
}
interface VarietyGrowMethodOptions {
  variety_grow_method: string;
}
interface DocumentTypeOptions {
  type_name: string;
}

interface SplitButtonOption {
  text: string;
  subText?: string;
  urlParams: { [key: string]: any };
}

@Component({
  selector: 'document-library',
  templateUrl: './document-library.component.html',
  styleUrls: ['./document-library.component.scss'],
  providers: [DocumentLibraryService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentLibraryComponent implements OnInit, OnDestroy {
  KENDO_SHORT_DATE_TIME_FORMAT = SettingsService.KENDO_SHORT_DATE_TIME_FORMAT;

  @Input() @HostBinding('class.embedded') embedded = false;
  view: Observable<GridDataResult>;
  state: State = {
    skip: 0,
    take: 25,
    sort: [{ field: 'uploaded_at', dir: 'desc' }, { field: 'grower_number', dir: 'asc' }]
  };
  selectedId = [];
  isGrowerPortal = false;
  canUpload = false;
  showUpload = false;
  uploadRestrictions: FileRestrictions = {
    allowedExtensions: ['.pdf']
  };

  mapButtonOptions: SplitButtonOption[]= [
    {
      text: 'Zespri Upload',
      subText: '(.jpeg, no hazards)',
      urlParams: { for_zespri: true }
    },
  ];

  uploadSaveUrl = environment.API_BASE_URL + 'orchards/documents/';

  seasonOptions: SeasonOptions[];
  growerNumberOptions: GrowerNumberOptions[];
  varietyGrowMethodOptions: VarietyGrowMethodOptions[];
  documentTypeOptions: DocumentTypeOptions[];
  private filters: [] = null;
  private documentLibraryServiceSubscription: Subscription;
  private deleteDialogSubscription = new Subscription();
  private selectedProductChangeSubscription: Subscription;
  private readonly routeEventSubscription: Subscription;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private documentLibraryService: DocumentLibraryService,
    private authService: AuthService,
    private dialogService: DialogService,
    private element: ElementRef,
    private userProductsService: UserProductsService,
    private tutorialService: TutorialService,
    private ga: GaService
  ) {
    this.selectedProductChangeSubscription = this.userProductsService.selectedProductChange.subscribe(() => {
      this.documentLibraryService.query(this.state);
    });

    this.routeEventSubscription = this.router.events.subscribe((event) => {
      this.documentLibraryServiceSubscription = this.documentLibraryService.subscribe(this.initializeDropdownOptions.bind(this));
      if (event instanceof NavigationEnd) {
        const data = this.route.snapshot.data;
        documentLibraryService.adminMode = !!(data && data['adminMode']);
        this.documentLibraryService.query(this.state);
      }
    });

    this.view = documentLibraryService;
    this.isGrowerPortal = Utils.isGrowerPortal();
    this.canUpload = this.authService.hasPermission('isDocumentUploader');
  }

  ngOnInit() {
    this.tutorialService.hideTutorialFooterButton();
    this.documentLibraryServiceSubscription = this.documentLibraryService.subscribe(this.initializeDropdownOptions.bind(this));
    const data = this.route.snapshot.data;
    this.documentLibraryService.adminMode = !!(data && data['adminMode']);
    this.documentLibraryService.query(this.state);
  }

  ngOnDestroy() {
    this.routeEventSubscription?.unsubscribe();
    this.documentLibraryServiceSubscription?.unsubscribe();
    this.deleteDialogSubscription?.unsubscribe();
    this.selectedProductChangeSubscription?.unsubscribe();
  }

  deleteSelected() {
    const dialog: DialogRef = this.dialogService.open(({
      title: 'Delete?',
      content: 'Are you sure you would like to delete the selected item(s)?',
      actions: [
        { text: 'Delete' },
        { text: 'Cancel', primary: true }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    } as DialogSettings));

    this.deleteDialogSubscription.add(dialog.result.subscribe((result) => {
      if (result['text'] === 'Delete') {
        this.deleteDialogSubscription.add(this.documentLibraryService.delete(this.selectedId).subscribe(() => {
          this.documentLibraryService.query(this.state);
        }));
      }
    }));
  }

  private initializeDropdownOptions() {
    if (this.documentLibraryService.filters && !this.filters) {
      this.filters = this.documentLibraryService.filters;

      this.seasonOptions = this.filters['seasons'].map((season) => {
        return { orchard_season: season };
      });
      this.seasonOptions.unshift({ orchard_season: null });

      this.growerNumberOptions = this.filters['grower_numbers'].map((growerNumber) => {
        return { grower_number: growerNumber };
      });

      this.varietyGrowMethodOptions = this.filters['variety_grow_methods'].map((varietyGrowMethods) => {
        return { variety_grow_method: varietyGrowMethods };
      });

      this.documentTypeOptions = this.filters['document_types'].map((documentType) => {
        return { type_name: documentType['name'] };
      });
      this.documentTypeOptions.unshift({ type_name: null });
    }
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.documentLibraryService.query(state);
  }

  openFile(url, subType: SplitButtonOption = null) {
    if (subType?.urlParams) {
      url = url + '?' + Object.keys(subType.urlParams).map((key) => {
        return `${key}=${subType.urlParams[key]}`;
      }).join('&');
    }

    this.ga.event('button', 'click', 'download document', url);
    Utils.openGrowerReport(url);
  }

  onDialogClose() {
    this.showUpload = false;
    this.documentLibraryService.query(this.state);
  }

  uploadErrorHandler(e) {
    if (e.files.length) {
      const uid = e.files[0].uid;
      const fileElement = this.element.nativeElement.querySelector(`*[data-uid="${uid}"] .k-file-info`);
      if (fileElement) {
        const elem = document.createElement('span');
        elem.classList.add('upload-error');

        if ('error' in e.response) {
          elem.append(e.response.error);
        } else {
          elem.append('Unknown error uploading file.');
        }

        const existing = fileElement.querySelector('.upload-error');
        if (existing) {
          existing.remove();
        }
        fileElement.append(elem);
      }
    }
  }
}
