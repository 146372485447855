import { Component, Input, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Utils } from 'app/shared/utils';
import { Axis } from 'app/interfaces/chart.interface';
import { SeasonDropdownItem } from 'app/grower-portal-dashboard/grower-portal-dashboard.component';

@Component({
  selector: 'app-grouped-bar-chart-with-history',
  templateUrl: './grouped-bar-chart-with-history.component.html',
  styleUrls: ['./grouped-bar-chart-with-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupedBarChartWithHistoryComponent {
  _data: any[] = [];
  baseSeasonData: any[];
  @Input() baseSeason: SeasonDropdownItem;
  @Input() groups: any[] = [];
  @Input() minValue;
  @Input() maxValue;
  @Input() minValueIfAllValuesArePositive;
  @Input() numGridLines = 8;
  @Input() precision = 4;
  @Input() type = 'stacked';
  @Input() legendLocation = 'bottom';
  @Input() showAverage = false;
  @Input() showHistoryAverage = false;
  @Input() yAxis: Axis = null;
  title: string;
  levelName: string;
  historyData: any[] = [];
  historyGroups: any[] = [];
  @HostBinding('class.history-page-visible') isHistoryPageVisible = false;
  @ViewChild('historyPage', { static: true }) historyPage: ElementRef;

  @Input()
  set data(data: any[]) {
    this._data = data;
    this.baseSeasonData = this.getSeasonData(this.baseSeason.value);
    this.updateHistoryPage();
    this.changeDetectorRef.markForCheck();
  }
  get data(): any[] {
    return this._data;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  showHistoryPage(chartGroup) {
    this.title = this.getHistoryLabel(chartGroup);
    this.levelName = this.getLevelName(chartGroup);
    this.isHistoryPageVisible = true;
    this.setHistoryData(chartGroup.groupIdentifiers);

    setTimeout(() => {
      this.adjustHistoryChartStyle();
    });
  }

  back() {
    this.isHistoryPageVisible = false;
  }

  private adjustHistoryChartStyle() {
    const SPACING = 20;
    const historyPageEl = this.historyPage.nativeElement;
    const chartOuter = historyPageEl.getElementsByClassName('chart-outer');

    if (chartOuter.length) {
      const chartOuterEl = chartOuter[0];
      const header = historyPageEl.getElementsByTagName('header');
      const headerHeight = header.length ? header[0].offsetHeight : 0;
      const legend = historyPageEl.getElementsByClassName('legend');
      const legendHeight = legend.length ? legend[0].offsetHeight : 0;
      const legendBottom = legend.length ? parseInt(getComputedStyle(legend[0]).bottom) || 0 : 0;
      chartOuterEl.style.paddingTop = `${ headerHeight + SPACING }px`;
      chartOuterEl.style.paddingBottom = `${ legendHeight + legendBottom + (SPACING * 2) }px`;
    }
  }

  private updateHistoryPage() {
    if (this.isHistoryPageVisible && this._data && this._data.length) {
      this.showHistoryPage(this._data[0]);
    }
  }

  private getHistoryLabel(data) {
    if (data.label) {
      return data.label;
    } else {
      return data.groupIdentifiers ? data.groupIdentifiers.maturity_area || data.groupIdentifiers.grower_number : '';
    }
  }

  private getSeasonData(season: number) {
    const data = this.data.filter(item => item.season === season);
    return data;
  }

  private getLevelName(group) {
    return group.groupIdentifiers.maturity_area ? 'Maturity Area' : 'KPIN';
  }

  private setHistoryData(groupIdentifiers) {
    this.historyGroups = [
      { label: 'Season', primary: 'season', secondary: 'type', isSelected: true, sortOrder: 'desc' }
    ];

    this.historyData = this.data.reduce((result, item) => {
      if (Utils.compare(groupIdentifiers, item.groupIdentifiers)) {
        result.push({ season: item.season, type: item.type, value: item.value });
      }
      return result;
    }, []);
  }
}
