import { NgModule } from '@angular/core';
import { FormatPhoneNumberPipe } from 'app/shared/pipes/format-phone-number.pipe';
import { AfterValueChangedDirective } from 'app/shared/directives/after-value-changed.directive';

@NgModule({
  declarations: [
    FormatPhoneNumberPipe,
    AfterValueChangedDirective,
  ],
  exports: [
    FormatPhoneNumberPipe,
    AfterValueChangedDirective,
  ]
})
export class SharedModule {}
