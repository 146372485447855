import { Component, OnInit, Input, ViewChild, HostBinding, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import * as d3 from 'd3';
import { Utils } from 'app/shared/utils';

interface PieChartDataItem {
  cssClass?: string;
  label?: string;
  value: number;
}

interface PieChartData {
  label?: string;
  data: PieChartDataItem[];
}

export interface PieChart {
  current: PieChartData;
  previous?: PieChartData;
}

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PieChartComponent implements OnInit {
  @Input() hasCenterValue = false;
  @Input() valuePostfix = '';
  @Input() valuePrecision = 0;
  @Input() subLabel = '';
  @Input() maxValue = 100;
  @Input() maxRings = 2;
  @Input() ringSpacing = 2;
  @Input() isAboveMaxOK = false;
  @Input() thickness = 10;
  @Input() width = 300;
  @Input() height = 300;
  @Input() transitionDuration = 1000;
  @Input() autoColor = false;
  @Input() showLegend = false;
  @Input() showValues = false;
  @Input() centerValue = 0;
  @Input() includeZeroValues = false;
  @Input() showValuesInLegend = false;
  @Input() showValueThreshold = 2.5; // A value will not be shown in the chart if it's less than this threshold
  @Input() useValueColors = true;
  @Input() valueColors = [
    { value: 0, color: '#b52125' },
    { value: 20, color: '#f27930' },
    { value: 50, color: '#ffd32f' },
    { value: 100, color: '#54b64e' }
  ];
  @Input() @HostBinding('class') legendPosition: 'top' | 'right' = 'right';
  @ViewChild('currentChart', { static: true }) currentChart: ElementRef;
  @ViewChild('previousChart', { static: true }) previousChart: ElementRef;
  legendItems: any[] = [];
  legendTop = 0;
  currentLabel = '';
  currentLabelPosition = { top: 0, left: 0 };
  previousLabel = '';
  previousLabelPosition = { top: 0, left: 0 };
  private currentChartElement: any = null;
  private previousChartElement: any = null;
  private pie;
  private currentArc;
  private currentLabelArc;
  private previousArc;
  private previousLabelArc;
  private chartData: PieChart | any[];
  private oldChartDataLength = 0;
  private chartValue: any;
  private currentRadius: number;
  private currentLabelRadius: number;
  private previousRadius: number;
  private previousLabelRadius: number;
  private arcSpacing: number;

  @Input()
  set value(value) {
    if (value != null && this.chartValue !== value) {
      if (this.chartValue != null) {
        this.chartValue = value;
        this.setupDataFromValue(this.chartValue);
        setTimeout(this.updateChart.bind(this));
      } else {
        this.chartValue = value;
        this.setupDataFromValue(this.chartValue);
        setTimeout(this.createChart.bind(this));
      }
    }
  }
  get value() {
    return this.chartValue;
  }

  @Input()
  set data(data: PieChart | any[]) {
    if (data) {
      this.useValueColors = false;
      data = this.prepareData(data);

      if (this.autoColor) {
        this.assignCssClassToData(data);
      }

      if (this.chartData) {
        if (this.dataChanged(data, this.chartData)) {
          this.chartData = data;
          setTimeout(this.updateChart.bind(this));
        }
      } else {
        this.chartData = data;
        setTimeout(this.createChart.bind(this));
      }
    }
  }
  get data(): PieChart | any[] {
    return this.chartData;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentChartElement = d3.select(this.currentChart.nativeElement);
    this.previousChartElement = d3.select(this.previousChart.nativeElement);
    this.arcSpacing = 2 * this.thickness;
    const margin = 7;
    const legendButtonPadding = 7;
    const labelSpacing = 20;
    const maxRadius =  Math.floor(Math.min(this.width, this.height) / 2) - margin;

    if (this.showValues) {
      this.currentLabelRadius = maxRadius;
      this.currentRadius = this.currentLabelRadius - labelSpacing;
      this.legendTop = labelSpacing - legendButtonPadding;
      this.currentLabelPosition = { top: labelSpacing, left: this.currentRadius + this.currentLabelRadius };
    } else {
      this.currentRadius = maxRadius;
      this.currentLabelRadius = this.currentRadius;
      this.legendTop = 0;
      this.currentLabelPosition = { top: 0, left: this.currentRadius };
    }

    this.previousRadius = this.currentRadius - this.arcSpacing;
    this.previousLabelRadius = this.currentRadius - this.arcSpacing - labelSpacing;
    this.previousLabelPosition = { top: this.previousLabelRadius + labelSpacing + 15, left: 2 * this.previousLabelRadius };

    this.pie = this.getPie();
    this.currentArc = this.getArc(this.currentRadius);
    this.currentLabelArc = this.getArc(this.currentLabelRadius);
    this.previousArc = this.getArc(this.previousRadius);
    this.previousLabelArc = this.getArc(this.previousLabelRadius);
  }

  onLegendItemMouseOver(item) {
    this.highlightArcGroup(item.cssClass, this.currentChartElement);
    this.highlightArcGroup(item.cssClass, this.previousChartElement);
  }

  onLegendItemMouseOut() {
    this.highlightArcGroup(null, this.currentChartElement);
    this.highlightArcGroup(null, this.previousChartElement);
  }

  private assignCssClassToData(data) {
    const cssClassLookup = {};
    let highestIndex = 0;

    // Create new css classes for current data
    if (data.current?.data) {
      data.current.data.forEach((item, index) => {
        item.cssClass = `item-${ index }`;
        cssClassLookup[item.label] = item.cssClass;
        highestIndex = index;
      });
    }

    // Try to assign matching current data css classes to previous data
    if (data.previous?.data) {
      data.previous.data.forEach((item) => {
        const cssClass = cssClassLookup[item.label];
        if (cssClass) {
          item.cssClass = cssClass;
        } else {
          // Create a new unused css class for items not found in current data
          highestIndex += 1;
          item.cssClass = `item-${ highestIndex }`;
        }
      });
    }
  }

  private prepareData(data) {
    if (!this.includeZeroValues) {
      if (data.current?.data) {
        data.current.data = this.removeZeroValues(data.current.data);
      }
      if (data.previous?.data) {
        data.previous.data = this.removeZeroValues(data.previous.data);
      }
    }
    return data;
  }

  private removeZeroValues(data: any[]): any[] {
    return (data || []).reduce((result, item) => {
      if (item.value) {
        result.push(item);
      }
      return result;
    }, []);
  }

  private dataChanged(oldData, newData) {
    return !Utils.compare(oldData, newData);
  }

  private highlightArcGroup(cssClass: string, target: any) {
    target
      .selectAll('.arc')
      .classed('selected', d => d.data.cssClass === cssClass);
  }

  private setLegendItems(data) {
    this.legendItems = data.map((item, index) => {
      return {
        index: index,
        cssClass: item.cssClass,
        label: item.label,
        value: this.formatValue(item.value)
      };
    });
  }

  private setupDataFromValue(value: number) {
    this.chartData = [];
    let numWholeRings = Math.floor(value / this.maxValue);
    let remainder = value % this.maxValue;
    if (numWholeRings >= this.maxRings) {
      numWholeRings = this.maxRings;
      remainder = 0;
    }

    for (let i = 0; i < numWholeRings; i++) {
      const item = [
        { value: this.maxValue, cssClass: 'value', fullValue: value },
        { value: 0, cssClass: 'empty' }
      ];
      this.chartData.push(item as any);
    }

    if (remainder || !numWholeRings) {
      const item = [
        { value: remainder, cssClass: 'value', fullValue: value },
        { value: this.maxValue - remainder, cssClass: 'empty' }
      ];
      this.chartData.push(item as any);
    }
  }

  private createChart() {
    if (Array.isArray(this.chartData)) {
      this.createValueBasedChart();
    } else if (this.chartData.current?.data) {
      this.createPreviousCurrentBasedChart();
    }
    this.changeDetectorRef.markForCheck();
  }

  private createValueBasedChart() {
    let currentRadius = this.currentRadius;
    let currentLabelRadius = this.currentLabelRadius;
    const chartData = this.chartData as any[];
    chartData.forEach((chartDataRing, index) => {
      if (index === 0) {
        this.setLegendItems(chartDataRing);
      }
      const currentArc = this.getArc(currentRadius, this.thickness);
      const currentLabelArc = this.getArc(currentLabelRadius, this.thickness);
      this.createCurrent(chartDataRing, currentArc, currentLabelArc, index);
      currentRadius -= (this.thickness + this.ringSpacing);
      currentLabelRadius -= (this.thickness + this.ringSpacing);
    });
    this.oldChartDataLength = chartData.length;
  }

  private createPreviousCurrentBasedChart() {
    const chartData = this.chartData as PieChart;
    this.currentLabel = chartData.current.label;
    this.setLegendItems(chartData.current.data);
    this.createCurrent(chartData.current.data);
    if (chartData.previous?.data) {
      this.previousLabel = chartData.previous.label;
      this.createPrevious(chartData.previous.data);
    }
  }

  private createCurrent(data: any[], currentArc = this.currentArc, currentLabelArc = this.currentLabelArc, index = 0) {
    this.createArcs(data, this.currentChartElement, currentArc, currentLabelArc, index);
  }

  private createPrevious(data: any[]) {
    this.createArcs(data, this.previousChartElement, this.previousArc, this.previousLabelArc, 0);
  }

  private createArcs(data: any[], target: any, arc: any, labelArc: any, index: number) {
    const ringGroup = target.append('g').attr('class', 'ring-' + index);
    const arcGroups = ringGroup
      .datum(data)
      .selectAll('.arc')
      .data(this.pie)
      .enter()
      .append('g')
      .attr('class', d => `arc ${ d['data']['cssClass'] || '' }`);

    const arcPaths = arcGroups
      .append('path')
      .attr('d', arc)
      .each(function(d) { return this._current = d; });

    if (this.useValueColors) {
      arcPaths.style('fill', (d) => {
        return this.getValueColor(d.data?.fullValue || d.value, d.data?.cssClass);
      });
    }

    if (this.showValues) {
      arcGroups
        .append('text')
        .attr('class', 'label')
        .attr('transform', d => `translate(${ labelArc.centroid(d) })`)
        .text(d => d.value < this.showValueThreshold ? '' : this.formatValue(d.value) + this.valuePostfix)
        .each(function(d) { this._current = d; });
    }
  }

  private updateChart() {
    if (Array.isArray(this.chartData)) {
      this.updateValueBasedChart();
    } else if (this.chartData.current?.data) {
      this.updatePreviousCurrentBasedChart();
    }
    this.changeDetectorRef.markForCheck();
  }

  private updateValueBasedChart() {
    const chartData = this.chartData as any[];
    if (this.oldChartDataLength > chartData.length) {
      this.removeRings(chartData.length, this.oldChartDataLength - 1);
    } else if (chartData.length > 1 && this.oldChartDataLength < chartData.length) {
      this.addRings(this.oldChartDataLength, chartData.length - 1);
    }
    this.updateRings(0, chartData.length - 1);
    this.oldChartDataLength = chartData.length;
  }

  private updatePreviousCurrentBasedChart() {
    const chartData = this.chartData as PieChart;
    this.currentLabel = chartData.current.label;
    this.setLegendItems(chartData.current.data);
    this.updateCurrentChart(chartData.current.data);

    if (chartData.previous?.data) {
      if (!this.previousLabel) {
        this.createPrevious(chartData.previous.data);
      } else {
        this.updatePreviousChart(chartData.previous.data);
      }
      this.previousLabel = chartData.previous.label;
    } else {
      this.previousLabel = '';
      this.removePreviousChart();
    }
  }

  private updateRings(fromIndex: number, toIndex: number) {
    for (let i = fromIndex; i <= toIndex; i++) {
      this.updateRing(i, i < toIndex ? 0 : undefined);
    }
  }

  private updateRing(index: number, transitionDuration) {
    const currentRadius = this.currentRadius - (this.thickness + this.ringSpacing) * index;
    const currentLabelRadius = this.currentLabelRadius - (this.thickness + this.ringSpacing) * index;
    const currentArc = this.getArc(currentRadius, this.thickness);
    const currentLabelArc = this.getArc(currentLabelRadius, this.thickness);
    this.updateArcs(this.chartData[index], this.currentChartElement, currentArc, currentLabelArc, index, transitionDuration);
  }

  private removeRings(fromIndex: number, toIndex: number) {
    for (let i = fromIndex; i <= toIndex; i++) {
      this.removeRing(i);
    }
  }

  private removeRing(index: number) {
    this.currentChartElement.select('.ring-' + index)
      .attr('fill-opacity', 1)
      .attr('stroke-opacity', 1)
      .transition()
        .duration(500)
        .attr('fill-opacity', 0)
        .attr('stroke-opacity', 0)
        .remove();
  }

  private addRings(fromIndex: number, toIndex: number) {
    for (let i = fromIndex; i <= toIndex; i++) {
      this.addRing(i, i === toIndex);
    }
  }

  private addRing(index: number, isLast = false) {
    const currentRadius = this.currentRadius - (this.thickness + this.ringSpacing) * index;
    const currentLabelRadius = this.currentLabelRadius - (this.thickness + this.ringSpacing) * index;
    const currentArc = this.getArc(currentRadius, this.thickness);
    const currentLabelArc = this.getArc(currentLabelRadius, this.thickness);
    const data = Utils.clone(this.chartData[index]);
    if (isLast) {
      data[0].value = 0;
      data[1].value = 100;
    }
    this.createCurrent(data, currentArc, currentLabelArc, index);
  }

  private updateCurrentChart(data: any[], currentArc = this.currentArc, currentLabelArc = this.currentLabelArc, index = 0) {
    this.updateArcs(data, this.currentChartElement, currentArc, currentLabelArc, index);
  }

  private updatePreviousChart(data: any[]) {
    this.updateArcs(data, this.previousChartElement, this.previousArc, this.previousLabelArc, 0);
  }

  private updateArcs(data: any[],
                     target: any,
                     arc: any,
                     labelArc: any,
                     index = 0,
                     transitionDuration = this.transitionDuration) {
    const ring = target.select(`.ring-${ index }`);
    const arcGroups = ring
      .selectAll('.arc')
      .data(this.pie(data));

    arcGroups
      .enter()
      .append('g')
      .attr('class', d => `arc ${ d['data']['cssClass'] || '' }`)
      .append('path')
      .attr('d', arc)
      .each(function(d) { return this._current = d; });

    arcGroups.exit().remove();
    arcGroups.attr('class', (d) => {
      return `arc ring-${index} ${d['data']['cssClass'] || ''}`;
    });

    const arcPaths = arcGroups.select('path');
    if (this.useValueColors) {
      arcPaths.style('fill', d => this.getValueColor(d.data?.fullValue || d.value, d.data?.cssClass));
    }

    arcPaths
      .transition()
      .duration(transitionDuration)
      .attrTween('d', (d, i, nodes) => {
        return this.tween(nodes[i], d, arc);
      });

    if (this.showValues) {
      const arcText = arcGroups
        .select('text')
        .attr('transform', d => `translate(${ labelArc.centroid(d) })`)
        .text(d => d.value < this.showValueThreshold ? '' : this.formatValue(d.value) + this.valuePostfix);

      arcText.transition()
        .duration(this.transitionDuration)
        .attrTween('transform', (d, i, nodes) => {
          return this.labelTween(nodes[i], d, labelArc);
        });
    }
  }

  private removePreviousChart() {
    this.previousChartElement.selectAll('.arc').remove();
  }

  private formatValue(value) {
    return (value || 0).toFixed(this.valuePrecision);
  }

  private getValueColor(value: number, cssClass): string {
    if (cssClass === 'value') {
      let result: string;
      for (const valueColor of this.valueColors) {
        if (valueColor.value > value) {
          break;
        }
        result = valueColor.color;
      }
      return result;
    }
  }

  private getPie() {
    return d3.pie()
      .value(d => d['value'])
      .sort(null)
      .startAngle(0);
  }

  private getArc(radius: number, thickness = this.thickness) {
    const innerRadius = thickness ? radius - thickness : 0;
    return d3.arc()
      .innerRadius(innerRadius)
      .outerRadius(radius);
  }

  private tween(element, d, arc) {
    const interpolate = d3.interpolate(element._current, d);
    element._current = interpolate(0);
    return (t) => {
      return arc(interpolate(t));
    };
  }

  private labelTween(element, d, arc) {
    const interpolate = d3.interpolate(element._current, d);
    element._current = interpolate(0);
    return (t) => {
      const interpolated =  interpolate(t);
      return `translate(${ arc.centroid(interpolated) })`;
    };
  }
}
