import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { HttpService } from 'app/shared/services/http.service';
import { DropdownItem } from 'app/shared/dropdown/dropdown.component';
import { WidgetHeaderButton } from 'app/widget/widget.component';
import { LatLng } from 'app/shared/orchard-map/orchard-map.component';
import { Utils } from 'app/shared/utils';
import { Subscription, map } from 'rxjs';

const ORCHARD_LIST_ENDPOINT = 'orchards/geometry/orchards/';

interface OrchardDropdownItem extends DropdownItem {
  value: number;  // Orchard ID
  label: string;  // Grower Number
  orchardMapId?: number;
}

interface OrchardMapItem {
  id: number;
  grower_number: string;
  product_name: string;
  orchard_map_id: number;
}

@Component({
  selector: 'grow-orchard-map-widget',
  templateUrl: './orchard-map-widget.component.html',
  styleUrls: ['./orchard-map-widget.component.scss'],
})
export class OrchardMapWidgetComponent implements OnInit, OnDestroy {
  @ViewChild('orchardMap', { static: false }) orchardMap;
  @ViewChild('orchardWeather', { static: false }) orchardWeather;
  private loadingOrchardsMessage = 'Loading orchards...';
  private loadingGeometryMessage = 'Loading map...';
  private loadingWeatherMessage = 'Loading weather...';
  private fetchOrchardsListSubscription: Subscription;
  isLoading = true;
  hideWeather = true;
  loadingMessage = this.loadingOrchardsMessage;
  orchards: OrchardDropdownItem[];
  selectedOrchard: OrchardDropdownItem;
  displayWeatherError = false;
  headerButtons: WidgetHeaderButton[] = [
    {
      text: 'Print Map',
      disabled: false,
      getUrl: (): string => {
        return Utils.getDocumentPath(this.selectedOrchard.orchardMapId);
      }
    }
  ];

  constructor(
    private http: HttpService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.fetchOrchardsList();
  }

  ngOnDestroy() {
    this.fetchOrchardsListSubscription?.unsubscribe();
  }

  mapError(event: any) {
    this.isLoading = false;
    this.disablePrintMapButton();
    this.hideWeather = true;
  }

  mapComplete(mapCenter: LatLng) {
    this.hideWeather = false;
    this.loadingMessage = this.loadingWeatherMessage;
    this.displayWeatherError = false;
    this.orchardWeather.loadData.next(mapCenter);
  }

  weatherError(event: any) {
    this.isLoading = false;
  }

  weatherComplete(event: any) {
    this.isLoading = false;
  }

  selectOrchard(orchard: OrchardDropdownItem) {
    if (!orchard) {
      return;
    }
    this.selectedOrchard = orchard;
    this.updatePrintMapButtonState();
    this.changeDetectorRef.detectChanges();
    this.updateWidgetContents();
  }

  private updatePrintMapButtonState() {
    if (this.selectedOrchard && this.selectedOrchard.orchardMapId) {
      this.enablePrintMapButton();
    } else {
      this.disablePrintMapButton();
    }
  }

  private enablePrintMapButton() {
    this.headerButtons[0].disabled = false;
  }

  private disablePrintMapButton() {
    this.headerButtons[0].disabled = true;
  }

  private updateWidgetContents() {
    this.loadingMessage = this.loadingGeometryMessage;
    this.isLoading = true;
    this.orchardMap.loadData.next({
      id: this.selectedOrchard.value,
      mapDocumentId: this.selectedOrchard.orchardMapId
    });
  }

  private fetchOrchardsList() {
    this.isLoading = true;
    this.loadingMessage = this.loadingOrchardsMessage;

    this.fetchOrchardsListSubscription = this.http.get(ORCHARD_LIST_ENDPOINT).pipe(
      map((data: OrchardMapItem[]) => {
        return data.map(orchard => {
          return {
            value: orchard.id,
            label: `${orchard.grower_number} - ${orchard.product_name}`,
            orchardMapId: orchard.orchard_map_id
          };
        });
      })
    ).subscribe((data: OrchardDropdownItem[]) => {
      this.orchards = data;
      this.isLoading = false;
      this.selectOrchard(this.orchards[0]);
    });
  }
}
