import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

export const USER_OVERRIDE_KEY = 'user-override-id';

export interface UserOverride {
  user_id: number;
  name: string;
}

@Component({
  selector: 'app-user-override',
  template: ''
})
export class UserOverrideComponent implements OnDestroy {
  private readonly queryParamsSubscription: Subscription;

  static getOverride(): UserOverride {
    const override = localStorage.getItem(USER_OVERRIDE_KEY);

    if (override) {
      return JSON.parse(override) as UserOverride;
    } else {
      return null;
    }
  }

  static clearOverride() {
    localStorage.setItem(USER_OVERRIDE_KEY, '');
  }

  static cancelOverride() {
    this.clearOverride();
    this.reload();
  }

  static reload() {
    location.reload();
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(params => {
      const override = {
        user_id: params['user_id'],
        name: params['name']
      } as UserOverride;

      if (override.user_id && override.name) {
        localStorage.setItem(USER_OVERRIDE_KEY, JSON.stringify(override));
      }
      this.router.navigateByUrl('/');
    });
  }

  ngOnDestroy() {
    this.queryParamsSubscription?.unsubscribe();
  }
}
