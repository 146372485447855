<form [formGroup]="form">
  <div class="card-header">Signer Details</div>
  <div class="row card-row">
    <div class="card">

      <div class="row">
        <div class="label">Authorised Signer</div>
        <div class="control"></div>
        <div class="value">{{ data.authorized_signer_name }}</div>
      </div>

      <changeable-field formGroupName="change_requests"
                        label="Postal Address"
                        [data]="data"
                        [dataKeys]="[
                          'postal_address_line_1',
                          'postal_address_line_2',
                          'postal_address_city',
                          'postal_address_country',
                          'postal_address_postal_code'
                        ]"
                        [controls]="[
                          changeRequestsForm.controls['postal_address_line_1'],
                          changeRequestsForm.controls['postal_address_line_2'],
                          changeRequestsForm.controls['postal_address_city'],
                          changeRequestsForm.controls['postal_address_country'],
                          changeRequestsForm.controls['postal_address_postal_code']
                        ]">
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="postalAddressLine1"
                       text="Address Line 1">
          </kendo-label>
          <kendo-textbox #postalAddressLine1 formControlName="postal_address_line_1">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="postalAddressLine2"
                       text="Address Line 2">
          </kendo-label>
          <kendo-textbox #postalAddressLine2 formControlName="postal_address_line_2">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="postalAddressCity"
                       text="City">
          </kendo-label>
          <kendo-textbox #postalAddressCity formControlName="postal_address_city">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="postalAddressCountry"
                       text="Country">
          </kendo-label>
          <kendo-textbox #postalAddressCountry formControlName="postal_address_country">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="postalAddressPostalCode"
                       text="Postcode">
          </kendo-label>
          <kendo-numerictextbox #postalAddressPostalCode
                                [spinners]="false"
                                [decimals]="0"
                                [format]="postcodeFormatOptions"
                                formControlName="postal_address_postal_code">
          </kendo-numerictextbox>
          <kendo-formerror>Error: Invalid Postcode</kendo-formerror>
        </kendo-formfield>
      </changeable-field>

      <changeable-field formGroupName="change_requests"
                        label="Home Address"
                        [data]="data"
                        [dataKeys]="[
                          'physical_address_line_1',
                          'physical_address_line_2',
                          'physical_address_city',
                          'physical_address_country',
                          'physical_address_postal_code'
                        ]"
                        [controls]="[
                          changeRequestsForm.controls['physical_address_line_1'],
                          changeRequestsForm.controls['physical_address_line_2'],
                          changeRequestsForm.controls['physical_address_city'],
                          changeRequestsForm.controls['physical_address_country'],
                          changeRequestsForm.controls['physical_address_postal_code']
                        ]">
        <ng-container dataValue *ngIf="data.physical_address_same_as_postal">
          Same as Postal Address.
        </ng-container>

        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="physicalAddressLine1"
                       text="Address Line 1">
          </kendo-label>
          <kendo-textbox #physicalAddressLine1 formControlName="physical_address_line_1">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="physicalAddressLine2"
                       text="Address Line 2">
          </kendo-label>
          <kendo-textbox #physicalAddressLine2 formControlName="physical_address_line_2">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="physicalAddressCity"
                       text="City">
          </kendo-label>
          <kendo-textbox #physicalAddressCity formControlName="physical_address_city">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="physicalAddressCountry"
                       text="Country">
          </kendo-label>
          <kendo-textbox #physicalAddressCountry formControlName="physical_address_country">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="physicalAddressPostalCode"
                       text="Postcode">
          </kendo-label>
          <kendo-numerictextbox #physicalAddressPostalCode
                                [spinners]="false"
                                [decimals]="0"
                                [format]="postcodeFormatOptions"
                                formControlName="physical_address_postal_code">
          </kendo-numerictextbox>
          <kendo-formerror>Error: Invalid Postcode</kendo-formerror>
        </kendo-formfield>
      </changeable-field>

      <changeable-field formGroupName="change_requests"
                        label="Mobile Number"
                        [data]="data"
                        dataKeys="phone_number_mobile"
                        [controls]="changeRequestsForm.controls['phone_number_mobile']">
        <kendo-formfield showHints="initial" showErrors="initial">
          <kendo-label [for]="phoneNumberMobile"
                       text="Mobile Number">
          </kendo-label>
          <kendo-maskedtextbox #phoneNumberMobile
                               prompt=""
                               [maskValidation]="false"
                               mask="00000000000"
                               formControlName="phone_number_mobile">
          </kendo-maskedtextbox>
          <kendo-formerror>Error: Invalid Mobile Number</kendo-formerror>
        </kendo-formfield>
      </changeable-field>

      <div class="row">
        <div class="label">Email Address</div>
        <div class="control"></div>
        <div class="value">{{ data.email_address }}</div>
      </div>
    </div>
  </div>

  <div class="card-header">Entity Details</div>
  <div class="row card-row">
    <div class="card">
      <div class="row">
        <div class="label">Full Name of Legal Entity</div>
        <div class="control"></div>
        <div class="value">{{ data.entity_name }}</div>
      </div>
    </div>
  </div>

  <div class="card-header">PPINs</div>
  <div class="row card-row">
    <div class="value" formArrayName="avogreen_orchard_list">
      <div *ngFor="let orchardForm of orchardFormGroups; let i = index"
           [formGroupName]="i"
           class="orchard card">
        @let orchard = orchardForm.metadata;

        <div formGroupName="change_requests">
          <strong>{{ orchard.grower_number }}, {{ orchard.orchard_name }}</strong>
          <div>{{ orchard.orchard_address }}</div>
          <div *ngFor="let area of orchard.variety_grow_methods">
            {{ area }}
          </div>

          <div class="orchard-details">
            <changeable-field label="Pest Monitor Center"
                              (onCanceled)="onPestMonitorCenterChange(orchard, orchardForm.get('change_requests.orchard_pest_monitor_center').oldValue, orchardForm)"
                              [data]="orchard"
                              [alwaysOpen]="true"
                              class="changeable-field-no-title"
                              dataKeys="orchard_pest_monitor_center"
                              [controls]="orchardForm.get('change_requests.orchard_pest_monitor_center')">
              <kendo-formfield showHints="initial" showErrors="initial">
                <kendo-dropdownlist #avogreenMonitor
                                    [data]="avogreenMonitorOptions"
                                    (valueChange)="onPestMonitorCenterChange(orchard, $event, orchardForm)"
                                    textField="text"
                                    valueField="value"
                                    formControlName="orchard_pest_monitor_center">
                </kendo-dropdownlist>
              </kendo-formfield>
            </changeable-field>
          </div>

          <!-- Displayed when no monitor is selected -->
          <div *ngIf="orchard.showSelectMonitorMessage" class="no-monitor-selected-message">
            <i class="icon fas fa-exclamation-triangle"></i>
            Please select a Pest Monitor Center.
          </div>

          <!-- Displayed when the selected avogreen monitor contains "Self" -->
          <div *ngIf="orchard.showSelfMonitorMessage" class="not-monitoring-with-trevelyans">
            <div>
              Please email a copy of your AvoGreen Certification to <a href="mailto:avogreenadmin@trevelyan.co.nz">avogreenadmin&#64;trevelyan.co.nz</a>.
            </div>
            <div>
              Trevelyan's requires this for NZ Avocado compliance.
            </div>
          </div>

          <!-- Displayed when the selected avogreen monitor is not trevelyans or self -->
          <div *ngIf="orchard.showNotMonitoringMonitorMessage" class="not-monitoring-with-trevelyans">
            <div>
              Please email a copy of your other provider monitoring agreement to <a
              href="mailto:avogreenadmin@trevelyan.co.nz">avogreenadmin&#64;trevelyan.co.nz</a>.
            </div>
            <div>
              Trevelyan's requires this for NZ Avocado compliance.
            </div>
          </div>

          <div formGroupName="monitoring_areas"
               class="row card-row object-list">
            <div class="card-header">Monitoring Areas</div>

            <div [class.visible]="orchard.remainingBlocks?.length" class="card remaining-blocks">
              <h3 class="remaining-blocks-title">Remaining Blocks</h3>
              <div class="tag-list">
                <span *ngFor="let block of orchard.remainingBlocks" class="tag">
                  {{ block.text }}
                </span>
              </div>
            </div>

            @let monitoringAreas = orchardForm.get('change_requests.monitoring_areas');
            <div *ngFor="let monitoringArea of orchard.monitoring_areas; let i = index"
                 [formGroupName]="i"
                 [class.deleted-monitoring-area]="!monitoringArea.active"
                 class="card">
              @let monitoringAreaControls = monitoringAreas.controls[i];

              @let nameField = monitoringAreaControls.get('monitoring_area_name');
              <changeable-field label="Monitoring Area Name"
                                dataKeys="monitoring_area_name"
                                [class.error]="nameField.invalid"
                                [data]="monitoringArea"
                                [controls]="nameField">
                <kendo-formfield>
                  <kendo-label [for]="areaName" text="Monitoring Area Name"></kendo-label>
                  <kendo-textbox #areaName formControlName="monitoring_area_name"></kendo-textbox>
                  <kendo-formerror *ngIf="nameField.invalid && nameField.errors.required">Error: Name is required</kendo-formerror>
                  <kendo-formerror *ngIf="nameField.invalid && nameField.errors['maxlength']">Error: Cannot be longer than {{ nameField.errors['maxlength'] }} characters.</kendo-formerror>
                  <kendo-formerror *ngIf="nameField.invalid && nameField.errors['notUnique']">Error: Must be unique.</kendo-formerror>
                </kendo-formfield>
              </changeable-field>

              @let blocksField = monitoringAreaControls.get('monitoring_area_blocks');
              <changeable-field label="Associated Blocks"
                                dataKeys="monitoring_area_blocks"
                                (onCanceled)="onBlocksChange(monitoringAreaControls, orchard, monitoringArea.monitoring_area_blocks.value, monitoringArea)"
                                [class.error]="blocksField.invalid"
                                [showPlaceholder]="false"
                                [showInitialValue]="true"
                                [formatFunction]="formatBlockNames"
                                [data]="monitoringArea"
                                [controls]="blocksField">
                <kendo-formfield>
                  <kendo-label [for]="blocks" text="Associated Blocks"></kendo-label>
                  <kendo-multiselect #blocks
                                     [data]="orchard.blocks"
                                     (valueChange)="onBlocksChange(monitoringAreaControls, orchard, $event, monitoringArea)"
                                     textField="text"
                                     valueField="value"
                                     formControlName="monitoring_area_blocks">
                  </kendo-multiselect>
                  <kendo-formerror *ngIf="blocksField.invalid && blocksField.errors.required">Error: At least one block is required</kendo-formerror>
                  <kendo-formerror *ngIf="blocksField.invalid && blocksField.errors['duplicate']">Error: Each block can only be on one Monitor Area. Duplicated Blocks: {{ blocksField.errors['duplicate'] }}</kendo-formerror>
                </kendo-formfield>
              </changeable-field>

              <div class="row">
                <div class="label">Producing Area</div>
                <div class="control"></div>
                <div class="value">{{ monitoringArea.producing_area | number:'1.2-2' | appendIfExists: ' Ha' }}</div>
              </div>

              @let treesField = monitoringAreaControls.get('monitoring_area_number_of_trees');
              <changeable-field label="Number of Trees"
                                dataKeys="monitoring_area_number_of_trees"
                                [class.error]="treesField.invalid"
                                [showPlaceholder]="false"
                                [data]="monitoringArea"
                                [controls]="treesField">
                <kendo-formfield>
                  <kendo-label [for]="trees" text="Number of Trees"></kendo-label>
                  <kendo-numerictextbox #trees
                                        [spinners]="false"
                                        [decimals]="0"
                                        [min]="0"
                                        [max]="999"
                                        format="n0"
                                        formControlName="monitoring_area_number_of_trees">
                  </kendo-numerictextbox>
                  <kendo-formhint>Number of trees to be monitored</kendo-formhint>
                  <kendo-formerror *ngIf="treesField.invalid && treesField.errors.required">
                    Error: Number of trees is required
                  </kendo-formerror>
                  <kendo-formerror *ngIf="treesField.invalid && !treesField.errors.required && treesField.errors.min">
                    Error: Number of trees must be between {{ treesField.errors.min }} and {{ treesField.errors.max }}
                  </kendo-formerror>
                </kendo-formfield>
              </changeable-field>

              <changeable-field label="Monitoring Frequency"
                                dataKeys="monitoring_area_monitoring_frequency"
                                [data]="monitoringArea"
                                [controls]="monitoringAreaControls.get('monitoring_area_monitoring_frequency')">
                <kendo-formfield>
                  <kendo-label [for]="monitoringFrequency" text="Monitoring Frequency"></kendo-label>
                  <kendo-dropdownlist #monitoringFrequency
                                      [data]="frequencyOptions"
                                      textField="text"
                                      valueField="value"
                                      formControlName="monitoring_area_monitoring_frequency">
                  </kendo-dropdownlist>
                </kendo-formfield>
              </changeable-field>

              <changeable-field label="China Registered?"
                                dataKeys="monitoring_area_china_registered"
                                [formatFunction]="formatChinaRegistered"
                                [data]="monitoringArea"
                                [controls]="monitoringAreaControls.get('monitoring_area_china_registered')">
                <kendo-formfield showHints="initial" showErrors="initial">
                  <kendo-label text="China Registered?"></kendo-label>
                  <kendo-switch class="modern-switch"
                                onLabel="Yes"
                                offLabel="No"
                                formControlName="monitoring_area_china_registered">
                  </kendo-switch>
                </kendo-formfield>
              </changeable-field>

              <changeable-field dataKeys="monitoring_area_active"
                                [hidden]="true"
                                [data]="monitoringArea"
                                [controls]="monitoringAreaControls.get('monitoring_area_active')">
                <kendo-formfield>
                  <kendo-checkbox formControlName="monitoring_area_active">
                  </kendo-checkbox>
                </kendo-formfield>
              </changeable-field>

              <div class="actions">
                <div class="flex-spacer"></div>
                <button type="button" *ngIf="monitoringArea.active && orchard?.activeMonitoringAreasCount > 1"
                        (click)="deleteMonitoringArea(orchard, monitoringArea, monitoringAreas, i)"
                        class="button small rounded fixed-vertical-alignment">
                  <i class="fa fa-trash"></i>Delete
                </button>
                <button type="button" *ngIf="!monitoringArea.active"
                        (click)="restoreMonitoringArea(orchard, monitoringArea, monitoringAreas, i)"
                        class="button small rounded fixed-vertical-alignment">
                  <i class="fa fa-trash"></i>Restore
                </button>
              </div>
            </div>

            <button type="button" (click)="addMonitoringArea(orchard, monitoringAreas)"
                    class="button rounded">
              <i class="fa fa-plus"></i>New
            </button>
          </div>

          <div *ngIf="orchard.isMonitoringWithTrevelyans"
               formGroupName="contacts"
               class="row card-row object-list">
            <div class="card-header">Contacts To Send Results</div>

            <div *ngFor="let contact of orchard.contacts; let i = index"
                 [formGroupName]="i"
                 class="card">
              @let contactControls = orchardForm.get('change_requests.contacts').controls[i];

              @let contactFirstNameField = contactControls.get('orchard_contact_first_name');
              @let contactLastNameField = contactControls.get('orchard_contact_last_name');
              <changeable-field label="Name"
                                class="contact-name"
                                [class.not-editable]="contact.id > 0"
                                [class.error]="contactFirstNameField.invalid || contactLastNameField.invalid"
                                [dataKeys]="[
                                  'orchard_contact_first_name',
                                  'orchard_contact_last_name'
                                ]"
                                [data]="contact"
                                [controls]="[contactFirstNameField, contactLastNameField]">
                <kendo-formfield showHints="initial" showErrors="initial">
                  <kendo-label [for]="contactFirstName" text="First Name"></kendo-label>
                  <kendo-textbox #contactFirstName
                                 formControlName="orchard_contact_first_name">
                  </kendo-textbox>
                  <kendo-formerror *ngIf="contactFirstNameField.invalid && contactFirstNameField.errors.required">Error: First Name is required</kendo-formerror>
                  <kendo-formerror *ngIf="contactFirstNameField.invalid && contactFirstNameField.errors['maxlength']">Error: Cannot be longer than {{ contactFirstNameField.errors['maxlength'] }} characters</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield showHints="initial" showErrors="initial">
                  <kendo-label [for]="contactLastName" text="Last Name"></kendo-label>
                  <kendo-textbox #contactLastName
                                 formControlName="orchard_contact_last_name">
                  </kendo-textbox>
                  <kendo-formerror *ngIf="contactLastNameField.invalid && contactLastNameField.errors.required">Error: Last Name is required</kendo-formerror>
                  <kendo-formerror *ngIf="contactLastNameField.invalid && contactLastNameField.errors['maxlength']">Error: Cannot be longer than {{ contactFirstNameField.errors['maxlength'] }} characters</kendo-formerror>
                </kendo-formfield>
              </changeable-field>

              @let emailField = contactControls.get('orchard_contact_email');
              <changeable-field label="Email"
                                dataKeys="orchard_contact_email"
                                [class.not-editable]="contact.id > 0"
                                [class.error]="emailField.invalid"
                                [data]="contact"
                                [controls]="emailField">
                <kendo-formfield showHints="initial" showErrors="initial">
                  <kendo-label [for]="contactEmail" text="Email"></kendo-label>
                  <kendo-textbox #contactEmail
                                 formControlName="orchard_contact_email">
                  </kendo-textbox>
                  <kendo-formerror *ngIf="emailField.invalid && emailField.errors['email']">Error: Please enter a valid email</kendo-formerror>
                </kendo-formfield>
              </changeable-field>

              <changeable-field label="Send Results?"
                                dataKeys="orchard_contact_send_results"
                                [formatFunction]="formatSendResults"
                                [data]="contact"
                                [controls]="contactControls.get('orchard_contact_send_results')">
                <kendo-formfield showHints="initial" showErrors="initial">
                  <kendo-label text="Send Results?"></kendo-label>
                  <kendo-switch class="modern-switch"
                                onLabel="Yes"
                                offLabel="No"
                                formControlName="orchard_contact_send_results">
                  </kendo-switch>
                </kendo-formfield>
              </changeable-field>

              <div *ngIf="contact.id < 0"
                   class="actions">
                <div class="flex-spacer"></div>
                <button type="button" (click)="deleteContact(orchard, orchardForm.get('change_requests.contacts'), i)"
                        class="button small rounded fixed-vertical-alignment">
                  <i class="fa fa-trash"></i>Delete
                </button>
              </div>
            </div>

            <button type="button" (click)="addContact(orchard, orchardForm.get('change_requests.contacts'))"
                    class="button rounded">
              <i class="fa fa-plus"></i>New
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="signing-formfield">
    <kendo-formfield>
      <span #signatureRow></span>
      <kendo-textbox class="signing-field"
                     placeholder="Type your name here"
                     formControlName="signature_value">
      </kendo-textbox>
      <kendo-formerror>
        <i class="fas fa-exclamation-triangle"></i>
        Your signature is required.
      </kendo-formerror>
    </kendo-formfield>
  </div>

  <div *ngIf="isDisabled; else signHereMessage" class="sign-here disabled">
    Signing disabled when impersonating a grower.
  </div>

  <ng-template #signHereMessage>
    <div class="sign-here" [class.below]="signSuffix === 'Below'">
      Sign {{ signSuffix }}
    </div>
  </ng-template>

  <div *ngIf="data.countersigner_signature_image" class="countersigner-row">
    <div class="countersigner-row-content">
      <div class="countersigner-signature"
           [style.backgroundImage]="'url(/api/media/' + data.countersigner_signature_image + ')'"></div>
      <div class="countersigner-signature-description">
        {{ data.countersigner_signature_description | lowercase }}
      </div>
    </div>
  </div>

  <div class="controls">
    <div *ngIf="isFormInvalid" class="invalid-form-message">
      <i class="fas fa-exclamation-triangle"></i>
      Please complete all required fields.
    </div>
    <button type="button" class="button rounded" (click)="cancel()">
      <i class="fas fa-chevron-left"></i>
      {{ isViewOnly ? 'Back' : 'Cancel' }}
    </button>
    <button
      class="button ok rounded save-button"
      [class.disabled]="isFormInvalid"
      type="button"
      (click)="saveForm()">
      <i class="fas fa-file-circle-check"></i>
      Save
    </button>
  </div>
</form>
