import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Utils } from 'app/shared/utils';

export const TOOLTIP_BOTTOM_OFFSET = 10;

export interface ColgroupCol {
  span: number;
  cssClass: string;
}

export interface RowError {
  key: string;
  value: any;
  message: string;
  startColumn: number;
  numColumns: number;
}

export interface GridRowTooltip {
  showEmpty?: boolean;
  emptyPlaceholder?: string;
  triggerColumns: string[];
  excludeRows?: { key: string, value: number | string }[];
  dataColumns: { key: string; label: string; }[];
}

export interface GridTooltip {
  width: number;
  content: string;
  data: { label: string, value: string | number, isEmpty: boolean }[];
  coordinates: { row: number, column: number };
  dir: 'north' | 'south';
  position: {
    top?: number;
    left?: number;
    bottom?: number;
  };
}

export const DEFAULT_TOOLTIP_WIDTH = 230;

@Component({
  selector: 'app-simple-grid',
  templateUrl: './simple-grid.component.html',
  styleUrls: ['./simple-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleGridComponent implements OnInit {
  private _columns = [];
  @Input()
  get columns(): any[] {
    return this._columns;
  }
  set columns(value: any[]) {
    this._columns = value;
    if (this.data?.length) {
      this.init();
    }
  }
  @Input() selectedColumnKey: string = null;
  @Input() rowSelectType: 'none'|'single'|'multi' = 'none';
  @Input() showRowSelect = true;
  @Input() hasColumnSelect = false;
  @Input() rowSelectCallback: (row: any, index: number, selectedRows: number[]) => any;
  @Input() columnSelectCallback: (key: any, column?: any, index?: number) => any;
  @Input() toggleRowCallback: (any) => any;
  @Input() defaultAlignment = 'right';
  @Input() emptyPlaceholder = 'NA';
  @Input() selectedRowIndexes: number[] = [];
  @Input() selectAll = false;
  @Input() alternatingColumnBg = true;
  @Input() colgroup: ColgroupCol[] = [];
  @Input() isDisabledRow = false;
  @Input() rowErrors: RowError[] = null;
  @Input() rowTooltip: GridRowTooltip = null;

  private _data: any = [];
  @Input()
  get data() {
    return this._data;
  }
  set data(value: any) {
    this._data = value || [];
    setTimeout(() => {
      this.initSelectedRows();
      this.revealSelectedRows();
      this.changeDetectorRef.markForCheck();
    });
  }

  private _hiddenColumns: (number|string)[] = [];
  @Input()
  get hiddenColumns() {
    return this._hiddenColumns;
  }
  set hiddenColumns(value: (number|string)[]) {
    this._hiddenColumns = value;
    this.changeDetectorRef.markForCheck();
  }
  selectedRows: any[] = [];
  headerRows: any[];
  flatColumns: any[] = null;
  maxDropdownHeight = 300;
  gridTooltip: GridTooltip = {
    width: DEFAULT_TOOLTIP_WIDTH,
    content: '',
    data: [],
    coordinates: {
      row: null,
      column: null
    },
    dir: 'north',
    position: {}
  };

  private delayedSelectRowTimeoutHandle: any = null;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.init();
  }

  // The single click is delayed to allow the double click event to cancel it
  delayedSelectRow(row, index, event, force) {
    if (row.isNotSelectable) {
      return;
    }

    clearTimeout(this.delayedSelectRowTimeoutHandle);
    this.delayedSelectRowTimeoutHandle = setTimeout(() => {
      this.selectRow(row, index, event, force);
    }, 200);
  }

  selectRow(row, index, event = null, force = false) {
    if (row.isNotSelectable) {
      return;
    }
    event?.stopPropagation();
    if (force || this.showRowSelect) {
      if (this.rowSelectType === 'single') {
        this.selectedRowIndexes = [index];
      } else if (this.rowSelectType === 'multi') {
        const selectedRowsIndex = this.selectedRowIndexes.indexOf(index);
        if (selectedRowsIndex >= 0) {
          this.selectedRowIndexes.splice(selectedRowsIndex, 1);
          this.selectedRows.splice(selectedRowsIndex, 1);
        } else {
          this.selectedRowIndexes.push(index);
          this.selectedRows.push(row);
        }
      }

      if (Utils.isFunction(this.rowSelectCallback)) {
        setTimeout(() => {
          this.rowSelectCallback(row, index, this.selectedRows);
        });
      }
    }
    this.changeDetectorRef.markForCheck();
  }

  onCellClick(row, column, event) {
    if (column.action) {
      event.stopPropagation();
      column.action(row);
    } else {
      this.selectColumn(column, event);
    }
  }

  selectColumn(column, event?, index?) {
    if ((this.selectedColumnKey !== column.key) || column.isSameSelectable) {
      if (this.hasColumnSelect && column.isSelectable) {
        if (event) {
          event.stopPropagation();
        }
        this.selectedColumnKey = column.key;
        if (Utils.isFunction(this.columnSelectCallback)) {
          this.columnSelectCallback(column.key, column, index);
        }
      }
    }
    this.changeDetectorRef.markForCheck();
  }

  toggleSelectAllRows() {
    if (this.selectedRows.length === this.data.length) {
      this.unselectAllRows();
    } else {
      this.selectAllRows();
    }
  }

  toggleRow(row, event) {
    clearTimeout(this.delayedSelectRowTimeoutHandle);
    event.stopPropagation();
    this.toggleChildren(row);
    if (Utils.isFunction(this.toggleRowCallback)) {
      this.toggleRowCallback(row);
    }
    this.changeDetectorRef.markForCheck();
  }

  showTooltip(rowIndex: number, columnIndex: number, columnKey: string = null, row: any = null, event: MouseEvent = null) {
    if (!this.showCellTooltip(row, rowIndex, columnIndex, event)
      && this.shouldShowRowTooltip(row, columnKey)) {
      this.showRowTooltip(row, rowIndex, columnIndex, event);
    }
    this.changeDetectorRef.markForCheck();
  }

  private init() {
    if (!this.hasColumnSelect) {
      this.selectedColumnKey = null;
    }
    this.markDataErrors();
    this.setEvenOdd(this.columns);
    this.headerRows = this.makeHeaderRows(this.columns);
    this.addRowSelectHeaderColumn();
    this.flatColumns = this.flattenColumns(this.columns);
  }

  private showCellTooltip(row: any, rowIndex: number, columnIndex: number, event: MouseEvent): boolean {
    const col = this.flatColumns[columnIndex];
    const msg = col?.messages?.[row[col.key]];
    if (msg) {
      this.gridTooltip.coordinates.row = rowIndex;
      this.gridTooltip.coordinates.column = columnIndex;
      this.showBasicTooltip(msg, event);
      return true;
    }
    return false;
  }

  private shouldShowRowTooltip(row, columnKey): boolean {
    if (this.rowTooltip?.triggerColumns.includes(columnKey)) {
      return !(this.rowTooltip.excludeRows || []).some((item) => row[item.key] === item.value);
    }
    return false;
  }

  private showRowTooltip(row: any, rowIndex: number, columnIndex: number, event: MouseEvent) {
    const [left, , bottom] = this.getTooltipPosition(event.target);
    const tooltipData = this.rowTooltip.dataColumns.map((column) => {
      const emptyPlaceholder = this.rowTooltip.emptyPlaceholder || this.emptyPlaceholder;
      let value = row[column.key];
      value = this.rowTooltip.showEmpty ? value : value || emptyPlaceholder;
      return { label: column.label, value: value, isEmpty: value === emptyPlaceholder };
    });
    this.gridTooltip.coordinates.row = rowIndex;
    this.gridTooltip.coordinates.column = columnIndex;
    this.gridTooltip.dir = 'south';
    this.gridTooltip.content = null;
    this.gridTooltip.data = tooltipData;
    this.gridTooltip.position = {left, bottom};
  }

  hideTooltip() {
    this.gridTooltip.data = null;
    this.gridTooltip.content = null;
    this.gridTooltip.coordinates.row = null;
    this.gridTooltip.coordinates.column = null;
    this.changeDetectorRef.markForCheck();
  }

  showBasicTooltip(content: string, event) {
    if (content) {
      const [left, top] = this.getTooltipPosition(event.target);
      this.gridTooltip.dir = 'north';
      this.gridTooltip.data = null;
      this.gridTooltip.content = content;
      this.gridTooltip.position = {left, top};
      this.changeDetectorRef.markForCheck();
    }
  }

  private getTooltipPosition(targetElement: any) {
    const bounds = targetElement.getBoundingClientRect();
    return [
      bounds.left + bounds.width / 2,
      bounds.top + bounds.height - TOOLTIP_BOTTOM_OFFSET,
      document.documentElement.clientHeight - bounds.top
    ];
  }

  updateColumnProperty(tabGroup: string, property: string, value: any) {
    this.flatColumns.forEach((column) => {
      if (column.tabGroup === tabGroup) {
        column[property] = value;
      }
    });
  }

  private markDataErrors() {
    if (this.data && this.rowErrors?.length) {
      this.data.forEach((row) => {
        this.markRowErrors(row);
      });
    }
  }

  private markRowErrors(row) {
    for (const rowError of this.rowErrors) {
      if (row[rowError.key] === rowError.value) {
        row.error = rowError;
        return;
      }
    }
  }

  private toggleChildren(row, state = null) {
    if (row.children) {
      row.isOpen = state == null ? !row.isOpen : !state;
      row.children.forEach((child) => {
        child.isHidden = state == null ? !child.isHidden : state;
        // Closing the child should also close all of it's children
        if (child.isHidden) {
          this.toggleChildren(child, true);
          this.changeDetectorRef.markForCheck();
        }
      });
    }
  }

  private selectAllRows() {
    this.selectedRows = JSON.parse(JSON.stringify(this.data));
    this.selectedRowIndexes = Array.from(Array(this.data.length).keys());
    if (Utils.isFunction(this.rowSelectCallback)) {
      setTimeout(() => {
        this.rowSelectCallback(null, null, this.selectedRows);
      });
    }
    this.changeDetectorRef.markForCheck();
  }

  private unselectAllRows() {
    this.selectedRows = [];
    this.selectedRowIndexes = [];
    if (Utils.isFunction(this.rowSelectCallback)) {
      setTimeout(() => {
        this.rowSelectCallback(null, null, this.selectedRows);
      });
    }
    this.changeDetectorRef.markForCheck();
  }

  private initSelectedRows() {
    if (this.data?.length) {
      if (this.selectedRowIndexes?.length ) {
        this.selectedRowIndexes.forEach((selectedRowIndex) => {
          this.selectRow(this.data[selectedRowIndex], selectedRowIndex, null, true);
        });
      } else if (this.selectAll) {
        this.selectAllRows();
      }
    }
    this.changeDetectorRef.markForCheck();
  }

  private revealSelectedRows() {
    if (this.selectedRowIndexes?.length && this.data?.length) {
      let areAnyRowsRevealed = false;
      this.selectedRowIndexes.forEach((selectedRowIndex) => {
        const selectedRow = this.data[selectedRowIndex];
        if (selectedRow) {
          this.revealRow(selectedRow);
          areAnyRowsRevealed = true;
        }
      });

      if (this.rowSelectType === 'single' && !areAnyRowsRevealed) {
        this.selectFirstRow();
      }
    }
  }

  private selectFirstRow() {
    this.selectRow(this.data[0], 0);
  }

  private revealSiblings(row) {
    if (row.parent) {
      this.reveal(row.parent.children);
    }
  }

  private revealChildren(row) {
    this.reveal(row.children);
  }

  private reveal(data) {
    if (data) {
      data.forEach(item => item.isHidden = false);
    }
  }

  private revealRow(row) {
    if (row.parent) {
      this.revealRow(row.parent);
      if (row.level > 0) {
        this.revealSiblings(row);
      }
    }
    row.isHidden = false;
    row.isOpen = true;
    this.revealChildren(row);
  }

  private makeHeaderRows(columns: any[], rows = []): any[] {
    const subColumns = [];
    const row = columns.map((column) => {
      if (column.childColumns) {
        subColumns.push(...column.childColumns);
      }
      return this.makeHeaderColumn(column);
    });

    rows.push(row);
    if (subColumns.length > 0) {
      this.makeHeaderRows(subColumns, rows);
    }
    return rows;
  }

  private setEvenOdd(columns) {
    const subColumns = [];
    let currentTabGroup: string;
    let index = 0;
    let firstTabGroupLastOddValue: boolean;
    let previousIsOdd: boolean;

    columns.forEach((column) => {
      let isOdd;

      if (column.noEvenOddShading) {
        isOdd = true;
      } else {
        // Store the final "odd" value of the first page
        // We need it to initialize the "odd" values of subsequent pages
        if (firstTabGroupLastOddValue == null && currentTabGroup !== column.tabGroup) {
          firstTabGroupLastOddValue = previousIsOdd;
        }

        // Reset the counter on page change
        if (currentTabGroup === column.tabGroup) {
          index += 1;
        } else {
          index = firstTabGroupLastOddValue === false ? 1 : 0;
          currentTabGroup = column.tabGroup;
        }

        isOdd = this.getNextOddValue(index, column);
      }

      this.updateColumnIsOdd(column, isOdd);

      if (column.childColumns) {
        column.childColumns.forEach((childColumn) => {
          subColumns.push(childColumn);
          this.updateColumnIsOdd(childColumn, (column.evenOddChildren ? null : isOdd));
        });
      }

      previousIsOdd = isOdd;
    });

    if (subColumns.length > 0) {
      this.setEvenOdd(subColumns);
    }
  }

  private updateColumnIsOdd(column, isOdd) {
    if (column.isOdd == null) {
      column.isOdd = isOdd;
    }
  }

  private getNextOddValue(index: number, column: any) {
    return column.isOdd == null ? index % 2 === 1 : column.isOdd;
  }

  private addRowSelectHeaderColumn() {
    if (this.showRowSelect && this.rowSelectType !== 'none' && this.headerRows && this.headerRows[0]) {
      this.headerRows[0].unshift({
        hasSelectAll: this.rowSelectType === 'multi',
        label: '',
        colspan: 0,
        rowspan: this.headerRows.length,
        align: 'center',
        cssClass: 'row-select'
      });
    }
  }

  private makeHeaderColumn(column): any {
    const colspan = column.childColumns ? column.childColumns.length : 1;
    let cssClass = column.cssClass || '';
    cssClass += column.isOdd ? ' odd' : ' even';
    column.cssClass = cssClass;

    return {
      tabGroup: column.tabGroup,
      key: column.key,
      label: column.label,
      align: column.align || this.defaultAlignment,
      rowspan: column.rowspan || 1,
      colspan: colspan,
      cssClass: cssClass,
      isSelectable: column.isSelectable,
      isSameSelectable: column.isSameSelectable,
      isOdd: column.isOdd,
      tooltipContent: column.tooltipContent,
      dropdown: column.dropdown,
      pager: column.pager,
      pagerItemCount: (column.pager || {}).itemCount || (column.childColumns || []).length
    };
  }

  private flattenColumns(columns, flattenedColumns = []) {
    columns.forEach((column) => {
      if (column.childColumns) {
        this.flattenColumns(column.childColumns, flattenedColumns);
      } else {
        if (column.key) {
          flattenedColumns.push({
            tabGroup: column.tabGroup,
            label: column.label,
            key: column.key,
            align: column.align || this.defaultAlignment,
            isSelectable: column.isSelectable,
            isSameSelectable: column.isSameSelectable,
            zeroIsValid: column.zeroIsValid,
            isDelta: column.isDelta,
            cssClass: column.cssClass,
            precision: column.precision,
            subValuePrecision: column.subValuePrecision,
            isPercent: column.isPercent,
            hasHeatMap: column.hasHeatMap,
            subValue: column.subValue,
            prefix: column.prefix,
            messages: column.messages,
            isOdd: column.isOdd,
            action: column.action,
            allowEmpty: column.allowEmpty,
            alwaysShowSubValue: column.alwaysShowSubValue,
            emptyPlaceholder: column.emptyPlaceholder,
            badValue: column.badValue,
            goodValue: column.goodValue,
            unit: column.unit
          });
        }
      }
    });
    return flattenedColumns;
  }
}
