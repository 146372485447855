import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormArray,
  FormControl,
  FormGroup,
  FormGroupDirective
} from '@angular/forms';
import { Utils, ChangeRequestOption } from 'app/shared/utils';

export interface RequestedChange {
  value: ChangeRequestOption;
  requested_at: Date;
  requested_by_name: string;
}

export interface ChangeableField<TValue> {
  value: TValue;
  requested_change: RequestedChange;
}

export interface ChangeRequestDescriptor {
  name: string;
  old_value: object[];
  new_value: object[];
}

type DisplayedFields<TValue> = Record<string, ChangeableField<TValue>>;

export class ChangeRequestFormControl extends FormControl {
  show = false;
  // Property used from the form group for fetching the object id from the form data
  objectIdKey: string;
  objectId: number;

  private key: string;
  private oldValue: ChangeRequestOption;
  private valueMap: Record<string | number, any>;

  constructor(objectIdKey: string, validators?: any, defaultValue?: any, valueMap?: Record<string | number, any>) {
    super(defaultValue || '', validators);
    this.objectIdKey = objectIdKey;
    this.valueMap = valueMap;
  }

  toggle() {
    this.show = !this.show;
  }

  private getInputValue(): ChangeRequestOption {
    return this.applyValueMap(super.getRawValue())
  }

  private applyValueMap(value: any): any {
    if (value instanceof Object || value instanceof Array) {
      return value;
    } else if (typeof value === 'boolean' || typeof value === 'number') {
      return this.valueMap?.['' + value] || value;
    }

    value = (value == null ? '' : value).toString().trim();

    // Masked input fields return the mask value when empty, which causes them to get submitted as a change request
    if (value.match(/^[ -]+$/)) {
      return '';
    }

    return this.valueMap?.[value] || value;
  }

  hasChanged() {
    const newValue = this.getInputValue();

    if (this.objectId < 0) {
      // New objects are always considered changed.
      return true;
    }

    if (this.touched && this.dirty && (newValue != null)) {
      if (newValue === 'null' && this.oldValue === null) {
        // Very hacky way to handling null values while preserving the original requirement of the value not being null
        return false;
      }
      return !Utils.compare(newValue, this.oldValue);
    }
    return false;
  }

  getLatestValue(): any {
    return this.hasChanged() ? this.getInputValue() : this.oldValue;
  }

  getRawValue(): ChangeRequestDescriptor {
    const change = {
      name: this.key,
      old_value: [{ id: this.objectId }],
      new_value: [{ id: this.objectId }]
    };
    change.old_value[0][this.key] = this.applyValueMap(this.oldValue);
    change.new_value[0][this.key] = this.getInputValue();

    return change;
  }

  forceBaseValueUpdate(value: any) {
    // Hacky bullshi* to force the base value to update
    this.oldValue = value;
    this.updateValue(value);
  }

  updateValue(value: any) {
    this.setValue(value);
    this.markAsTouched();
    this.markAsDirty();
    this.updateValueAndValidity();
  }

  setupControl(name: string, objectId: number, field: ChangeableField<any>) {
    this.key = name;
    this.objectId = objectId;
    this.oldValue = field.requested_change?.value || field.value;
  }
}

export class ChangeRequestFormGroup extends FormGroup {
  declare controls: Record<string, ChangeRequestFormControl | ChangeRequestFormArray | FormControl>;

  patchValue(formData: Record<string, any>, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    if (formData == null) { return; }

    const dataKeys = Object.keys(formData);
    for (const name of dataKeys) {
      const data = formData[name];
      const control = this.controls[name];
      if (control) {
        if (control instanceof ChangeRequestFormControl) {
          const objectId = formData[control.objectIdKey];
          if (!objectId) {
            throw new Error(`Missing object id for control ${ name }`);
          }
          control.setupControl(name, objectId, formData[name]);
        } else if (control instanceof ChangeRequestFormArray) {
          control.controls.forEach((formGroup, index) => {
            formGroup.patchValue(data[index]);
          });
        } else {
          control.patchValue(data.value, options);
        }
      }
    }
    this.updateValueAndValidity(options);
  }

  getRawValue(): ChangeRequestDescriptor[] {
    return Object.values(this.controls).reduce((result, control) => {
      if (control instanceof ChangeRequestFormControl && control.hasChanged()) {
        result.push(control.getRawValue());
      } else if (control instanceof ChangeRequestFormArray) {
        result.push(...control.getRawValue());
      } else if (control instanceof FormArray) {
        for (const childControl of Object.values(control.controls)) {
          result.push(...childControl.getRawValue());
        }
      }
      return result;
    }, []);
  }
}

export class ChangeRequestFormArray extends FormArray {
  declare controls: ChangeRequestFormGroup[];
  changeTypeKey: string;
  bundleChanges: boolean;

  constructor(changeTypeKey: string, bundleChanges: boolean, controls: ChangeRequestFormControl[]) {
    super(controls);
    this.changeTypeKey = changeTypeKey;
    this.bundleChanges = bundleChanges;
  }

  getRawValue(): ChangeRequestDescriptor[] {
    const unbundledChanges = [];
    const oldValues = [];
    const newValues = [];

    for (const control of this.controls) {
      const controlValue = control.getRawValue();

      if (controlValue.length) {
        const returnValue = controlValue.reduce((result, change: ChangeRequestDescriptor) => {
          result.old_value = { ...result.old_value, ...change.old_value[0] };
          result.new_value = { ...result.new_value, ...change.new_value[0] };
          return result;
        }, {
          name: this.changeTypeKey,
          old_value: {},
          new_value: {}
        });

        if (!this.bundleChanges) {
          if (Object.keys(returnValue.old_value).length) {
            unbundledChanges.push({
              name: returnValue['name'],
              old_value: [returnValue['old_value']],
              new_value: [returnValue['new_value']]
            });
          }
        } else {
          oldValues.push(returnValue.old_value);
          newValues.push(returnValue.new_value);
        }
      }
    }

    if (!this.bundleChanges) {
      return unbundledChanges;
    }

    if (newValues.length) {
      return [{
        name: this.changeTypeKey,
        old_value: oldValues,
        new_value: newValues
      }];
    }
    return [];
  }
}

@Component({
  selector: 'changeable-field',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  styleUrls: ['../agreement-form.component.scss'],
  templateUrl: './changeable-field.component.html',
  host: { class: 'changeable-field' }
})
export class ChangeableFieldComponent<TValue> implements OnInit{
  @ViewChild('inputs', { static: false }) inputs: ElementRef;

  @Output() onCanceled = new EventEmitter<void>();
  @Output() onOpened = new EventEmitter<void>();

  protected isEnabled = true;
  @Input()
  set enableControl(isEnabled: boolean) {
    this.isEnabled = isEnabled;
    if (!isEnabled) {
      this.closeControl();
    }
  }
  @Input() label: string;
  @Input() alwaysOpen = false;
  @Input() showPlaceholder = true;
  @Input() showInitialValue = false;
  @Input() formatFunction: (n: any) => string;

  _initialData: object;
  @Input()
  set data(value: object) {
    this._initialData = value;
    if (!value || !Object.keys(value).length) {
      return;
    }
    this._displayedFields = this.getCleanDisplayedFields<TValue>(value);
    this.latestRequestedChange = this.getLatestRequestedChange();
  }
  _displayedFields: Record<string, ChangeableField<TValue>> = {};

  @Input()
  set dataKeys(value: string | string[]) {
    this._dataKeys = Array.isArray(value) ? value : [value];
  }
  private _dataKeys: string[];

  @Input()
  set controls(value: AbstractControl | AbstractControl[]) {
    this._controls = Array.isArray(value) ? value : [value];
  }
  private _controls: AbstractControl[];

  latestRequestedChange: RequestedChange = null;
  _showForm = false;
  get showForm(): boolean {
    return this._showForm;
  }
  set showForm(value: boolean) {
    this._showForm = value;
    if (value) {
      this.onOpened.emit();
    }
    for (const i in this._controls) {
      const control = this._controls[i] as FormControl;
      if (value) {
        const existingValue = control.value;
        control['formOpen'] = true;
        control.markAsTouched();
        control.markAsDirty();
        control.setValue(this._initialData?.[this._dataKeys[i]]?.value);
        if (!this.showInitialValue) {
          setTimeout(() => {
            control.setValue(existingValue);
          });
        }
      } else {
        control['formOpen'] = false;
        control.reset();
      }
    }
  }

  ngOnInit() {
    if (this.alwaysOpen) {
      this.showForm = true;
    }
  }

  // Used by the keyvalue pipe in the template to preserve the order of the fields
  originalOrder = (a, b): number => 0;

  onControlClick() {
    if (!this.isEnabled) {
      return;
    }

    this.showForm = !this.showForm;
    if (!this.showForm) {
      this.closeControl();
    } else {
      setTimeout(this.initForm.bind(this));
    }
  }

  closeControl() {
    this.showForm = false;
    this._controls.forEach(item => {
      item.reset();
    });
    this.onCanceled.emit();
  }

  private initForm() {
    // Set focus to the first input
    const firstInput = this.inputs?.nativeElement.querySelector('.k-input-inner');
    if (!firstInput || firstInput.nodeName !== 'INPUT') {
      return;
    }

    firstInput.setSelectionRange(0, 0); // Moves the cursor to beginning of a text input.
    firstInput.focus();

    // Set a placeholder/value on each input
    if (this.showPlaceholder) {
      const inputs = this.inputs?.nativeElement.querySelectorAll('.k-input');
      (inputs || []).forEach(input => {
        const formControlName = input.getAttribute('formcontrolname');
        const inputElement = input.querySelector('.k-input-inner');
        inputElement.placeholder = this.getPlaceholder(formControlName);
      });
    }
  }

  private getPlaceholder(formControlName: string): string {
    const dataField = this._displayedFields[formControlName];
    return (dataField?.requested_change.value || dataField?.value || '') as string;
  }

  private getLatestRequestedChange(): RequestedChange {
    return Object.values(this._displayedFields)
      .filter(field => !!field.requested_change?.requested_at)
      .sort((a, b) => {
        return (new Date(a.requested_change.requested_at) as any) - (new Date(b.requested_change.requested_at) as any);
    })[0]?.requested_change;
  }

  private getCleanDisplayedFields<T>(value: object): DisplayedFields<T> {
    return this._dataKeys.reduce((obj, key) => {
      let displayValue = value[key];
      if (this.formatFunction) {
        displayValue = Object.assign({}, value[key], {
          value: this.formatFunction(value[key]['value'])
        });
      }
      return Object.assign(obj, {
        [key]: displayValue as ChangeableField<T>
      });
    }, {});
  }
}
