<form [formGroup]="form">
  <div class="card-header">Signer Details</div>
  <div class="row card-row">
    <div class="card">

      <div class="row">
        <div class="label">Authorised Signer</div>
        <div class="control"></div>
        <div class="value">{{ data.authorized_signer_name }}</div>
      </div>

      <changeable-field formGroupName="change_requests"
                        label="Postal Address"
                        [data]="data"
                        [dataKeys]="[
                          'postal_address_line_1',
                          'postal_address_line_2',
                          'postal_address_city',
                          'postal_address_country',
                          'postal_address_postal_code'
                        ]"
                        [controls]="[
                          changeRequestsForm.controls['postal_address_line_1'],
                          changeRequestsForm.controls['postal_address_line_2'],
                          changeRequestsForm.controls['postal_address_city'],
                          changeRequestsForm.controls['postal_address_country'],
                          changeRequestsForm.controls['postal_address_postal_code']
                        ]">
        <kendo-formfield>
          <kendo-label [for]="postalAddressLine1"
                       text="Address Line 1">
          </kendo-label>
          <kendo-textbox #postalAddressLine1 formControlName="postal_address_line_1">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="postalAddressLine2"
                       text="Address Line 2">
          </kendo-label>
          <kendo-textbox #postalAddressLine2 formControlName="postal_address_line_2">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="postalAddressCity"
                       text="City">
          </kendo-label>
          <kendo-textbox #postalAddressCity formControlName="postal_address_city">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="postalAddressCountry"
                       text="Country">
          </kendo-label>
          <kendo-textbox #postalAddressCountry formControlName="postal_address_country">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="postalAddressPostalCode"
                       text="Postcode">
          </kendo-label>
          <kendo-numerictextbox #postalAddressPostalCode
                                [spinners]="false"
                                [decimals]="0"
                                [format]="postcodeFormatOptions"
                                formControlName="postal_address_postal_code">
          </kendo-numerictextbox>
          <kendo-formerror>Error: Invalid Postcode</kendo-formerror>
        </kendo-formfield>
      </changeable-field>

      <changeable-field formGroupName="change_requests"
                        label="Home Address"
                        [data]="data"
                        [dataKeys]="[
                          'physical_address_line_1',
                          'physical_address_line_2',
                          'physical_address_city',
                          'physical_address_country',
                          'physical_address_postal_code'
                        ]"
                        [controls]="[
                          changeRequestsForm.controls['physical_address_line_1'],
                          changeRequestsForm.controls['physical_address_line_2'],
                          changeRequestsForm.controls['physical_address_city'],
                          changeRequestsForm.controls['physical_address_country'],
                          changeRequestsForm.controls['physical_address_postal_code']
                        ]">
        <ng-container dataValue *ngIf="data.physical_address_same_as_postal">
          Same as Postal Address.
        </ng-container>

        <kendo-formfield>
          <kendo-label [for]="physicalAddressLine1"
                       text="Address Line 1">
          </kendo-label>
          <kendo-textbox #physicalAddressLine1 formControlName="physical_address_line_1">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="physicalAddressLine2"
                       text="Address Line 2">
          </kendo-label>
          <kendo-textbox #physicalAddressLine2 formControlName="physical_address_line_2">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="physicalAddressCity"
                       text="City">
          </kendo-label>
          <kendo-textbox #physicalAddressCity formControlName="physical_address_city">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="physicalAddressCountry"
                       text="Country">
          </kendo-label>
          <kendo-textbox #physicalAddressCountry formControlName="physical_address_country">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="physicalAddressPostalCode"
                       text="Postcode">
          </kendo-label>
          <kendo-numerictextbox #physicalAddressPostalCode
                                [spinners]="false"
                                [decimals]="0"
                                [format]="postcodeFormatOptions"
                                formControlName="physical_address_postal_code">
          </kendo-numerictextbox>
          <kendo-formerror>Error: Invalid Postcode</kendo-formerror>
        </kendo-formfield>
      </changeable-field>

      <changeable-field formGroupName="change_requests"
                        label="Mobile Number"
                        [data]="data"
                        dataKeys="phone_number_mobile"
                        [controls]="changeRequestsForm.controls['phone_number_mobile']">
        <kendo-formfield>
          <kendo-maskedtextbox prompt=""
                               [maskValidation]="false"
                               mask="00000000000"
                               formControlName="phone_number_mobile">
          </kendo-maskedtextbox>
          <kendo-formerror>Error: Invalid Mobile Number</kendo-formerror>
        </kendo-formfield>
      </changeable-field>

      <div class="row">
        <div class="label">Email Address</div>
        <div class="control"></div>
        <div class="value">{{ data.email_address }}</div>
      </div>
    </div>
  </div>

  <div class="card-header">Entity Details</div>
  <div class="row card-row">
    <div class="card">
      <div class="row">
        <div class="label">Full Name of Legal Entity</div>
        <div class="control"></div>
        <div class="value">{{ data.entity_name }}</div>
      </div>
    </div>
  </div>

  <div class="card-header">KPINs</div>
  <div class="row card-row">
    <div class="value" formArrayName="kiwigreen_orchard_list">
      <div *ngFor="let orchardForm of orchardFormGroups; let i = index"
           [formGroupName]="i"
           class="orchard card">
        @let orchard = orchardForm.metadata;

        <div formGroupName="change_requests">
          <strong>{{ orchard.grower_number }}, {{ orchard.orchard_name }}</strong>
          <div>{{ orchard.orchard_address }}</div>
          <div *ngFor="let area of orchard.variety_grow_methods">
            {{ area }}
          </div>

          <div class="orchard-details">
            <changeable-field label="Pest Monitor Center"
                              (onCanceled)="onPestMonitorCenterChange(orchard, orchardForm.get('change_requests.orchard_pest_monitor_center').oldValue, orchardForm)"
                              [data]="orchard"
                              [alwaysOpen]="true"
                              dataKeys="orchard_pest_monitor_center"
                              class="changeable-field-no-title"
                              [controls]="orchardForm.get('change_requests.orchard_pest_monitor_center')">
              <kendo-formfield>
                <kendo-dropdownlist [data]="pestMonitorCenterOptions"
                                    (valueChange)="onPestMonitorCenterChange(orchard, $event, orchardForm)"
                                    textField="text"
                                    valueField="value"
                                    formControlName="orchard_pest_monitor_center">
                </kendo-dropdownlist>
              </kendo-formfield>
            </changeable-field>

            <changeable-field *ngIf="orchard.isMonitoringWithTrevelyans"
                              label="Loadout Area Description (if not within orchard)"
                              (onCanceled)="onPestMonitorCenterChange(orchard, orchardForm.get('change_requests.loadout_area_description').oldValue, orchardForm)"
                              [data]="orchard"
                              [alwaysOpen]="true"
                              class="changeable-field-no-title"
                              dataKeys="loadout_area_description"
                              [controls]="orchardForm.get('change_requests.loadout_area_description')">
              <kendo-formfield>
                <kendo-textarea class="text-area-no-resize"
                                [rows]="3"
                                formControlName="loadout_area_description">
                </kendo-textarea>
                <kendo-formhint>
                  If the loadout area is not located within this orchard, please describe where it is, and whom to contact to
                  have permission to access it.
                </kendo-formhint>
              </kendo-formfield>
            </changeable-field>
          </div>

          <!-- Displayed when no monitor is selected -->
          <div *ngIf="orchard.showSelectMonitorMessage" class="form-error-message">
            <i class="icon fas fa-exclamation-triangle"></i>
            Please select a Pest Monitor Center.
          </div>

          <!-- Displayed when no one is set to be contacted first when monitoring -->
          <div *ngIf="orchard.isMonitoringWithTrevelyans && orchard.showSelectFirstContactMessage"
               class="form-error-message">
              <i class="icon fas fa-exclamation-triangle"></i>
              Please select at least one person below to be contacted first when monitoring.
          </div>

          <div *ngIf="orchard.isMonitoringWithTrevelyans"
               formGroupName="contacts"
               class="row card-row object-list">
            <div class="card-header">Contacts To Send Results</div>

            <div *ngFor="let contact of orchard.contacts; let i = index"
                 [formGroupName]="i"
                 class="card">
              @let contactControls = orchardForm.get('change_requests.contacts').controls[i];

              @let contactFirstNameField = contactControls.get('orchard_contact_first_name');
              @let contactLastNameField = contactControls.get('orchard_contact_last_name');
              <changeable-field label="Name"
                                class="contact-name"
                                [class.not-editable]="contact.id > 0"
                                [class.error]="contactFirstNameField.invalid || contactLastNameField.invalid"
                                [dataKeys]="[
                                  'orchard_contact_first_name',
                                  'orchard_contact_last_name'
                                ]"
                                [data]="contact"
                                [controls]="[contactFirstNameField, contactLastNameField]">
                <kendo-formfield>
                  <kendo-label [for]="contactFirstName" text="First Name"></kendo-label>
                  <kendo-textbox #contactFirstName
                                 formControlName="orchard_contact_first_name">
                  </kendo-textbox>
                  <kendo-formerror *ngIf="contactFirstNameField.invalid && contactFirstNameField.errors.required">Error: First Name is required</kendo-formerror>
                  <kendo-formerror *ngIf="contactFirstNameField.invalid && contactFirstNameField.errors['maxlength']">Error: Cannot be longer than {{ contactFirstNameField.errors['maxlength'] }} characters</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                  <kendo-label [for]="contactLastName" text="Last Name"></kendo-label>
                  <kendo-textbox #contactLastName
                                 formControlName="orchard_contact_last_name">
                  </kendo-textbox>
                  <kendo-formerror *ngIf="contactLastNameField.invalid && contactLastNameField.errors.required">Error: Last Name is required</kendo-formerror>
                  <kendo-formerror *ngIf="contactLastNameField.invalid && contactLastNameField.errors['maxlength']">Error: Cannot be longer than {{ contactFirstNameField.errors['maxlength'] }} characters</kendo-formerror>
                </kendo-formfield>
              </changeable-field>

              @let emailField = contactControls.get('orchard_contact_email');
              <changeable-field label="Email"
                                dataKeys="orchard_contact_email"
                                class="changeable-field-no-title"
                                [class.not-editable]="contact.id > 0"
                                [class.error]="emailField.invalid"
                                [data]="contact"
                                [controls]="emailField">
                <kendo-formfield>
                  <kendo-textbox formControlName="orchard_contact_email">
                  </kendo-textbox>
                  <kendo-formerror *ngIf="emailField.invalid && emailField.errors['email']">Error: Please enter a valid email</kendo-formerror>
                </kendo-formfield>
              </changeable-field>

              <changeable-field label="Send Results?"
                                dataKeys="orchard_contact_send_results"
                                class="changeable-field-no-title"
                                [formatFunction]="formatBoolean"
                                [data]="contact"
                                [controls]="contactControls.get('orchard_contact_send_results')">
                <kendo-formfield>
                  <kendo-switch class="modern-switch"
                                onLabel="Yes"
                                offLabel="No"
                                formControlName="orchard_contact_send_results">
                  </kendo-switch>
                </kendo-formfield>
              </changeable-field>

              @let contactFirstField = contactControls.get('contact_first_when_monitoring');
              <changeable-field label="Contact First When Monitoring?"
                                dataKeys="contact_first_when_monitoring"
                                class="changeable-field-no-title"
                                (onCanceled)="onContactWhenMonitoringChange(contact, 'contact_first_when_monitoring')"
                                (onOpened)="onContactWhenMonitoringChange(contact, 'contact_first_when_monitoring')"
                                [formatFunction]="formatBoolean"
                                [showInitialValue]="true"
                                [data]="contact"
                                [controls]="contactFirstField">
                <kendo-formfield>
                  <kendo-switch class="modern-switch"
                                onLabel="Yes"
                                offLabel="No"
                                formControlName="contact_first_when_monitoring"
                                (valueChange)="onContactWhenMonitoringChange(contact, 'contact_first_when_monitoring')">
                  </kendo-switch>
                  <kendo-formerror *ngIf="contactFirstField.invalid && contactFirstField.errors['duplicate']">Error: Only one person can be set to be contacted first when monitoring.</kendo-formerror>
                  <kendo-formerror *ngIf="contactFirstField.invalid && contactFirstField.errors['required']">Error: {{ contactFirstField.errors['required'] }}</kendo-formerror>
                  <kendo-formhint>
                    Orchard contact person for KiwiGreen team to message before entry.
                    It is important you advise us if spraying will occur on the day of planned monitoring.
                  </kendo-formhint>
                </kendo-formfield>
              </changeable-field>

              @let contactSecondField = contactControls.get('contact_second_when_monitoring');
              <changeable-field label="Contact Second When Monitoring?"
                                dataKeys="contact_second_when_monitoring"
                                class="changeable-field-no-title"
                                (onCanceled)="onContactWhenMonitoringChange(contact, 'contact_second_when_monitoring')"
                                (onOpened)="onContactWhenMonitoringChange(contact, 'contact_second_when_monitoring')"
                                [formatFunction]="formatBoolean"
                                [showInitialValue]="true"
                                [data]="contact"
                                [controls]="contactSecondField">
                <kendo-formfield>
                  <kendo-switch class="modern-switch"
                                onLabel="Yes"
                                offLabel="No"
                                formControlName="contact_second_when_monitoring"
                                (valueChange)="onContactWhenMonitoringChange(contact, 'contact_second_when_monitoring')">
                  </kendo-switch>
                  <kendo-formerror *ngIf="contactSecondField.invalid && contactSecondField.errors['duplicate']">Error: Only one person can be set to be contacted second when monitoring.</kendo-formerror>
                  <kendo-formhint>
                    Second/fallback orchard contact person for KiwiGreen team to message before entry.
                    It is important you advise us if spraying will occur on the day of planned monitoring.
                  </kendo-formhint>
                </kendo-formfield>
              </changeable-field>

              <div *ngIf="contact.id < 0"
                   class="actions">
                <div class="flex-spacer"></div>
                <button type="button" (click)="deleteContact(orchard, orchardForm.get('change_requests.contacts'), i)"
                        class="button small rounded fixed-vertical-alignment">
                  <i class="fa fa-trash"></i>Delete
                </button>
              </div>
            </div>

            <button type="button" (click)="addContact(orchard, orchardForm.get('change_requests.contacts'))"
                    class="button rounded">
              <i class="fa fa-plus"></i>New
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="signing-formfield">
    <div *ngIf="showGateFeeMessage">
      By signing this document I agree that the travel gate fee will apply if our team arrives at the orchard and cannot
      proceed with the scheduled monitoring due to activities, such as spraying, being conducted on orchard.
    </div>

    <kendo-formfield>
      <span #signatureRow></span>
      <kendo-textbox class="signing-field"
                     placeholder="Type your name here"
                     formControlName="signature_value">
      </kendo-textbox>
      <kendo-formerror>
        <i class="fas fa-exclamation-triangle"></i>
        Your signature is required.
      </kendo-formerror>
    </kendo-formfield>
  </div>

  <div *ngIf="isDisabled; else signHereMessage" class="sign-here disabled">
    Signing disabled when impersonating a grower.
  </div>

  <ng-template #signHereMessage>
    <div class="sign-here" [class.below]="signSuffix === 'Below'">
      Sign {{ signSuffix }}
    </div>
  </ng-template>

  <div *ngIf="data.countersigner_signature_image" class="countersigner-row">
    <div class="countersigner-row-content">
      <div class="countersigner-signature"
           [style.backgroundImage]="'url(/api/media/' + data.countersigner_signature_image + ')'"></div>
      <div class="countersigner-signature-description">
        {{ data.countersigner_signature_description | lowercase }}
      </div>
    </div>
  </div>

  <div class="controls">
    <div *ngIf="isFormInvalid" class="invalid-form-message">
      <i class="fas fa-exclamation-triangle"></i>
      Please complete all required fields.
    </div>
    <button type="button" class="button rounded" (click)="cancel()">
      <i class="fas fa-chevron-left"></i>
      {{ isViewOnly ? 'Back' : 'Cancel' }}
    </button>
    <button
      class="button ok rounded save-button"
      [class.disabled]="isFormInvalid"
      type="button"
      (click)="saveForm()">
      <i class="fas fa-file-circle-check"></i>
      Save
    </button>
  </div>
</form>
